import { AppActions } from '../rootActions';
import * as AuthConstants from './constants';
import * as AuthModels from './models';

/**
 * Functions to be called in React components
 */
export const login = (payload: AuthModels.LoginPayload): AppActions => ({
  type: AuthConstants.LOGIN,
  payload,
});

export const forgetPassword = (payload: { email: string }): AppActions => ({
  type: AuthConstants.FORGET_PASSWORD,
  payload,
});

export const refreshToken = (): AppActions => ({
  type: AuthConstants.REFRESH_TOKEN,
});

export const register = (payload: AuthModels.RegisterPayload): AppActions => ({
  type: AuthConstants.REGISTER,
  payload,
});

export const clearStatus = (): AppActions => ({
  type: AuthConstants.CLEAR_STATUS,
});

export const loginWithGoogle = (payload: AuthModels.LoginWithGooglePayload): AppActions => ({
  type: AuthConstants.LOGIN_GOOGLE,
  payload,
});

export const loginWithFacebook = (payload: AuthModels.LoginWithFacebookPayload): AppActions => ({
  type: AuthConstants.LOGIN_FACEBOOK,
  payload,
});

export const loginWithApple = (payload: AuthModels.LoginWithApplePayload): AppActions => ({
  type: AuthConstants.LOGIN_APPLE,
  payload,
});

export const logout = (): AppActions => ({
  type: AuthConstants.LOGOUT,
});

export const confirmVerificationCode = (
  payload: AuthModels.VerificationCodePayload,
): AppActions => ({
  type: AuthConstants.CONFIRM_VERIFICATION_CODE,
  payload: payload,
});

export const resendVerificationCode = (
  payload: AuthModels.ResendVerificationCodePayload,
): AppActions => ({
  type: AuthConstants.RESEND_VERIFICATION_CODE,
  payload: payload,
});

export const getUserDetails = (): AppActions => ({
  type: AuthConstants.GET_USER_DETAILS,
});

export const resetUserDetails = (): AppActions => ({
  type: AuthConstants.RESET_USER_DETAILS,
});

export const updateOnboardingDetails = (
  payload: AuthModels.OnboardingDetailsPayload,
): AppActions => ({
  type: AuthConstants.UPDATE_ONBOARDING_DETAILS,
  payload: payload,
});

export const getStudyLevel = (payload: AuthModels.StudyLevelQuery): AppActions => ({
  type: AuthConstants.GET_STUDY_LEVEL,
  payload: payload,
});

export const getMoreStudyLevel = (payload: AuthModels.StudyLevelQuery): AppActions => ({
  type: AuthConstants.GET_MORE_STUDY_LEVEL,
  payload: payload,
});

export const clearVerificationStatus = (): AppActions => ({
  type: AuthConstants.CLEAR_VERIFICATION_STATUS,
});

export const skipVerification = (): AppActions => ({
  type: AuthConstants.SKIP_VERIFICATION,
});
