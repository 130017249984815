import { ParentsNotificationActionType, ParentsNotificationState } from './models';
import * as ParentsNotificationConstants from './contants';
import i18n from 'i18next';

export const parentsNotificationInitialState: ParentsNotificationState = {
  // Notification Details
  auth: null,
  notificationDetails: null,
  notificationDetailsLoading: false,
  notificationDetailsStatusCode: null,
  notificationDetailsNetworkError: false,

  // Verify password
  verifyPasswordMessage: null,
  verifyPasswordLoading: false,
  verifyPasswordStatusCode: null,
  verifyPasswordNetworkError: false,

  // Save details
  saveDetailsLoading: false,
  saveDetailsStatusCode: null,
  saveDetailsNetworkError: false,

  // Update details
  updateDetailsLoading: false,
  updateDetailsStatusCode: null,
  updateDetailsNetworkError: false,

  // Cancel notifications
  cancelNotificationLoading: false,
  cancelNotificationStatusCode: null,
  cancelNotificationNetworkError: false,

  // Confirm OTP
  confirmOTPMessage: null,
  confirmOTPLoading: false,
  confirmOTPStatusCode: null,
  confirmOTPNetworkError: false,

  // Resend OTP
  resendOTPMessage: null,
  resendOTPLoading: false,
  resendOTPStatusCode: null,
  resendOTPNetworkError: false,

  // Update password
  updatePasswordLoading: false,
  updatePasswordStatusCode: null,
  updatePasswordNetworkError: false,

  // Forgot password
  forgotPasswordMessage: null,
  forgotPasswordLoading: false,
  forgotPasswordStatusCode: null,
  forgotPasswordNetworkError: false,
};

export const parentsNotificationReducer = (
  state = parentsNotificationInitialState,
  action: ParentsNotificationActionType,
): ParentsNotificationState => {
  switch (action.type) {
    case ParentsNotificationConstants.GET_NOTIFICATION_DETAILS:
      return {
        ...state,
        auth: null,
        notificationDetails: null,
        notificationDetailsLoading: true,
        notificationDetailsStatusCode: null,
        notificationDetailsNetworkError: false,
      };
    case ParentsNotificationConstants.GET_NOTIFICATION_DETAILS_SUCCESS:
      return {
        ...state,
        auth: action.payload.data.objects.auth,
        notificationDetails: action.payload.data.objects.details,
        notificationDetailsLoading: false,
        notificationDetailsStatusCode: action.payload.code,
      };
    case ParentsNotificationConstants.GET_NOTIFICATION_DETAILS_ERROR:
      return {
        ...state,
        notificationDetailsLoading: false,
        notificationDetailsStatusCode: action.payload.code,
      };
    case ParentsNotificationConstants.GET_NOTIFICATION_DETAILS_NETWORK_ERROR:
      return {
        ...state,
        notificationDetailsLoading: false,
        notificationDetailsNetworkError: true,
      };
    case ParentsNotificationConstants.VERIFY_PASSWORD:
      return {
        ...state,
        verifyPasswordMessage: null,
        verifyPasswordLoading: true,
        verifyPasswordStatusCode: null,
        verifyPasswordNetworkError: false,
      };
    case ParentsNotificationConstants.VERIFY_PASSWORD_SUCCESS:
      return {
        ...state,
        verifyPasswordLoading: false,
        verifyPasswordStatusCode: action.payload.code,
      };
    case ParentsNotificationConstants.VERIFY_PASSWORD_ERROR:
      return {
        ...state,
        verifyPasswordMessage:
          i18n.language === 'en'
            ? action.payload.translatedErrors.en
            : action.payload.translatedErrors.ms,
        verifyPasswordLoading: false,
        verifyPasswordStatusCode: action.payload.code,
      };
    case ParentsNotificationConstants.VERIFY_PASSWORD_NETWORK_ERROR:
      return {
        ...state,
        verifyPasswordLoading: false,
        verifyPasswordNetworkError: true,
      };
    case ParentsNotificationConstants.SAVE_NOTIFICATION_DETAILS:
      return {
        ...state,
        saveDetailsLoading: true,
        saveDetailsStatusCode: null,
        saveDetailsNetworkError: false,
      };
    case ParentsNotificationConstants.SAVE_NOTIFICATION_DETAILS_SUCCESS:
      return {
        ...state,
        saveDetailsLoading: false,
        saveDetailsStatusCode: action.payload.code,
      };
    case ParentsNotificationConstants.SAVE_NOTIFICATION_DETAILS_ERROR:
      return {
        ...state,
        saveDetailsLoading: false,
        saveDetailsStatusCode: action.payload.code,
      };
    case ParentsNotificationConstants.SAVE_NOTIFICATION_DETAILS_NETWORK_ERROR:
      return {
        ...state,
        verifyPasswordLoading: false,
        verifyPasswordNetworkError: true,
      };
    case ParentsNotificationConstants.UPDATE_NOTIFICATION_DETAILS:
      return {
        ...state,
        updateDetailsLoading: true,
        updateDetailsStatusCode: null,
        updateDetailsNetworkError: false,
      };
    case ParentsNotificationConstants.UPDATE_NOTIFICATION_DETAILS_SUCCESS:
      return {
        ...state,
        updateDetailsLoading: false,
        updateDetailsStatusCode: action.payload.code,
      };
    case ParentsNotificationConstants.UPDATE_NOTIFICATION_DETAILS_ERROR:
      return {
        ...state,
        updateDetailsLoading: false,
        updateDetailsStatusCode: action.payload.code,
      };
    case ParentsNotificationConstants.UPDATE_NOTIFICATION_DETAILS_NETWORK_ERROR:
      return {
        ...state,
        updateDetailsLoading: false,
        updateDetailsNetworkError: true,
      };
    case ParentsNotificationConstants.CANCEL_NOTIFICATION:
      return {
        ...state,
        cancelNotificationLoading: true,
        cancelNotificationStatusCode: null,
        cancelNotificationNetworkError: false,
      };
    case ParentsNotificationConstants.CANCEL_NOTIFICATION_SUCCESS:
      return {
        ...state,
        cancelNotificationLoading: false,
        cancelNotificationStatusCode: action.payload.code,
      };
    case ParentsNotificationConstants.CANCEL_NOTIFICATION_ERROR:
      return {
        ...state,
        cancelNotificationLoading: false,
        cancelNotificationStatusCode: action.payload.code,
      };
    case ParentsNotificationConstants.CANCEL_NOTIFICATION_NETWORK_ERROR:
      return {
        ...state,
        cancelNotificationLoading: false,
        cancelNotificationNetworkError: true,
      };
    case ParentsNotificationConstants.CONFIRM_OTP:
      return {
        ...state,
        confirmOTPMessage: null,
        confirmOTPLoading: true,
        confirmOTPStatusCode: null,
        confirmOTPNetworkError: false,
      };
    case ParentsNotificationConstants.CONFIRM_OTP_SUCCESS:
      return {
        ...state,
        confirmOTPLoading: false,
        confirmOTPStatusCode: action.payload.code,
      };
    case ParentsNotificationConstants.CONFIRM_OTP_ERROR:
      return {
        ...state,
        confirmOTPMessage:
          i18n.language === 'en'
            ? action.payload.translatedErrors.en
            : action.payload.translatedErrors.ms,
        confirmOTPLoading: false,
        confirmOTPStatusCode: action.payload.code,
      };
    case ParentsNotificationConstants.CONFIRM_OTP_NETWORK_ERROR:
      return {
        ...state,
        confirmOTPLoading: false,
        confirmOTPNetworkError: true,
      };
    case ParentsNotificationConstants.RESEND_OTP:
      return {
        ...state,
        resendOTPMessage: null,
        resendOTPLoading: true,
        resendOTPStatusCode: null,
        resendOTPNetworkError: false,
      };
    case ParentsNotificationConstants.RESEND_OTP_SUCCESS:
      return {
        ...state,
        resendOTPMessage: i18n.t('resend_code_successful'),
        resendOTPLoading: false,
        resendOTPStatusCode: action.payload.code,
      };
    case ParentsNotificationConstants.RESEND_OTP_ERROR:
      return {
        ...state,
        resendOTPLoading: false,
        resendOTPStatusCode: action.payload.code,
      };
    case ParentsNotificationConstants.RESEND_OTP_NETWORK_ERROR:
      return {
        ...state,
        resendOTPLoading: false,
        resendOTPNetworkError: true,
      };
    case ParentsNotificationConstants.UPDATE_PASSWORD:
      return {
        ...state,
        updatePasswordLoading: true,
        updatePasswordStatusCode: null,
        updatePasswordNetworkError: false,
      };
    case ParentsNotificationConstants.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        updatePasswordLoading: false,
        updatePasswordStatusCode: action.payload.code,
      };
    case ParentsNotificationConstants.UPDATE_PASSWORD_ERROR:
      return {
        ...state,
        updatePasswordLoading: false,
        updatePasswordStatusCode: action.payload.code,
      };
    case ParentsNotificationConstants.UPDATE_PASSWORD_NETWORK_ERROR:
      return {
        ...state,
        updatePasswordLoading: false,
        updatePasswordNetworkError: true,
      };
    case ParentsNotificationConstants.FORGOT_PASSWORD:
      return {
        ...state,
        forgotPasswordMessage: null,
        forgotPasswordLoading: true,
        forgotPasswordStatusCode: null,
        forgotPasswordNetworkError: false,
      };
    case ParentsNotificationConstants.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPasswordMessage: action.payload.data,
        forgotPasswordLoading: false,
        forgotPasswordStatusCode: action.payload.code,
      };
    case ParentsNotificationConstants.FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        forgotPasswordLoading: false,
        forgotPasswordStatusCode: action.payload.code,
      };
    case ParentsNotificationConstants.FORGOT_PASSWORD_NETWORK_ERROR:
      return {
        ...state,
        forgotPasswordLoading: false,
        forgotPasswordNetworkError: true,
      };
    case ParentsNotificationConstants.RESET_PARENTS_NOTIFICATION:
      return parentsNotificationInitialState;
    default:
      return state;
  }
};
