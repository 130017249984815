import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function CartIcon(props: IconProps): JSX.Element {
  return (
    <Icon viewBox="0 0 40 35" {...props}>
      <path
        id="Path_487"
        data-name="Path 487"
        d="M15,31.5A1.5,1.5,0,1,1,13.5,30,1.5,1.5,0,0,1,15,31.5Z"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <path
        id="Path_488"
        data-name="Path 488"
        d="M31.5,31.5A1.5,1.5,0,1,1,30,30,1.5,1.5,0,0,1,31.5,31.5Z"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <path
        id="Path_489"
        data-name="Path 489"
        d="M1.5,1.5h6l4.02,20.085a3,3,0,0,0,3,2.415H29.1a3,3,0,0,0,3-2.415L34.5,9H9"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </Icon>
  );
}
