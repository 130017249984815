import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function CancelIcon(props: IconProps): JSX.Element {
  return (
    <Icon viewBox="0 0 16.243 16.243" {...props}>
      <g id="X" transform="translate(-925.879 -7.879)">
        <line
          id="Line_111"
          data-name="Line 111"
          x2="12"
          y2="12"
          transform="translate(928 10)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeWidth="3"
        />
        <line
          id="Line_112"
          data-name="Line 112"
          x1="12"
          y2="12"
          transform="translate(928 10)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeWidth="3"
        />
      </g>
    </Icon>
  );
}
