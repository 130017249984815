import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

import { Resource } from 'src/models/cart/Resource';
import { formatPrice } from 'src/utils/formatPrice';

interface ResourceItemProps {
  resource: Resource;
}

function ResourceItem({ resource }: ResourceItemProps): JSX.Element {
  return (
    <Flex key={resource.cartId} mt="4" borderBottomWidth="1px" borderBottomColor="white" pb="4">
      <Text w="70%" mr="5%">
        {resource.resourceName} - {resource.form.name}
      </Text>
      <Text w="25%">
        {formatPrice(String(resource.discountPrice ? resource.discountPrice : resource.price))}
      </Text>
    </Flex>
  );
}

export default ResourceItem;
