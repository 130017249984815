import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import {
  Button,
  Box,
  Divider,
  Flex,
  Text,
  Select,
  HStack,
  VStack,
  useToast,
  Link,
} from '@chakra-ui/react';
import format from 'date-fns/format';

import { month, Months } from 'src/constants/months';
import {
  selectCartCourse,
  selectCurrentDate,
  selectCutOffDate,
  selectCutOffDateStatusCode,
  selectPeopleAlsoBuyItem,
  selectPromoCode,
  selectPromoCodeErrorMessage,
} from 'src/redux/cart/selectors';
import { formatPrice } from 'src/utils/formatPrice';
import { getCoursePriceStructure } from 'src/utils/getCoursePriceStructure';
import {
  getCart,
  getCutOffDate,
  getPeopleAlsoBuy,
  resetPeopleAlsoBuy,
  resetPromoCode,
} from 'src/redux/cart/actions';
import NavigateTopbar from 'src/components/Topbar/NavigateTopbar';
import ItemCard from './ItemCard';
import PeopleAlsoBuyCard from 'src/components/Cart/PeopleAlsoBuyCard';
import useSessionExpired from 'src/components/SessionExpired';
import PromoCode from 'src/components/Cart/PromoCode';
import { compareAsc, eachMonthOfInterval } from 'date-fns';
import { Course } from 'src/models/cart/Course';
import Loader from 'src/components/Loader';

function Subscription(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const toast = useToast();

  const [startYear, setStartYear] = useState(0);
  const [startMonth, setStartMonth] = useState(0);
  const [latestCourseDate, setLatestCourseDate] = useState<Date>();

  const [courseTotal, setCourseTotal] = useState(0);
  const [courseNoSaving, setCourseNoSaving] = useState(0);
  const [promoCode, setPromoCode] = useState('');
  const [grandTotalWithPromo, setGrandTotalWithPromo] = useState(0);
  const [validCourses, setValidCourses] = useState<Course[] | undefined>();

  const courses = useSelector(selectCartCourse);
  const peopleAlsoBuy = useSelector(selectPeopleAlsoBuyItem);

  const cutOffDate = useSelector(selectCutOffDate);
  const cutOffDateStatusCode = useSelector(selectCutOffDateStatusCode);

  const currentDate = useSelector(selectCurrentDate);

  const promoCodeData = useSelector(selectPromoCode);
  const promoCodeErrorMessage = useSelector(selectPromoCodeErrorMessage);

  const hasPromoCode = promoCodeData && Boolean(promoCodeData);
  const validPromoCode = hasPromoCode && Boolean(!promoCodeErrorMessage);
  const hasCourseItem =  courses && Boolean(courses.length);
  const hasPeopleAlsoBuy = peopleAlsoBuy && Boolean(peopleAlsoBuy.length);

  const anyCourseHasDiscount = Boolean(courses?.some((course) => course.discountPrice));

  const [date, setDate] = useState(new Date());
  let currentYear = date.getFullYear();
  let currentMonth = date.getMonth();
  let currentDay = date.getDate();

  const resetPromoCodeMaster = () => {
    setPromoCode('');
    dispatch(resetPromoCode());
  };

  useEffect(() => {
    dispatch(getCutOffDate());
    resetPromoCodeMaster();
  }, [dispatch]);

  // Calculate latest course date
  useEffect(() => {
    if (hasCourseItem) {
      let latestCourseDate = date;
      courses?.forEach((course) => {
        if (compareAsc(latestCourseDate, new Date(course.endDate)) === -1) {
          latestCourseDate = new Date(course.endDate);
        }
      });
      setLatestCourseDate(latestCourseDate);
      return;
    }
    setLatestCourseDate(undefined);
    setStartYear(0);
    setStartMonth(0);
  }, [courses, date]);

  // Fetch people also buy list
  useEffect(() => {
    if (hasCourseItem) {
      const forms: string[] = [];
      const courseIds: number[] = [];

      courses?.forEach((item) => {
        forms.push(item.form.name);
        courseIds.push(item.id);
      });

      dispatch(
        getPeopleAlsoBuy({
          forms,
          courseIds,
        }),
      );
    }

    // No course item
    else {
      dispatch(resetPeopleAlsoBuy());
    }
  }, [dispatch, courses]);

  // Get latest bundle discount
  useEffect(() => {
    if (startMonth && startYear) {
      const endDate = new Date(startYear, startMonth - 1, 1);
      dispatch(getCart(format(endDate, 'Y-M-d')));
    }
  }, [startMonth, startYear, dispatch]);

  // Calculate course subtotal
  useEffect(() => {
    const validCourses = courses?.filter((course) => checkCourseCanPurchase(course));
    setValidCourses(validCourses);
    if (
      hasCourseItem &&
      startYear !== 0 &&
      startMonth !== 0 &&
      validCourses &&
      validCourses.length > 0
    ) {
      const coursesPriceWithDiscout = validCourses
        .map((course) => {
          const { hasDiscount, discountPrice, originalPrice } = getCoursePriceStructure(course);
          return hasDiscount ? discountPrice : originalPrice;
        })
        .reduce((accumulator, price) => accumulator + price);

      const coursesPriceNoDiscout = validCourses
        .map((course) => {
          const { originalPrice } = getCoursePriceStructure(course);
          return originalPrice;
        })
        .reduce((accumulator, price) => accumulator + price);

      if (hasPromoCode) {
        const { type, value } = promoCodeData;
        const discountNumber = Number(value);

        let grandTotalWithPromoCode = 0;

        if (type.id === 1) {
          grandTotalWithPromoCode = coursesPriceWithDiscout - discountNumber;
        }

        if (type.id === 2) {
          grandTotalWithPromoCode = coursesPriceWithDiscout * ((100 - discountNumber) / 100);
        }

        setGrandTotalWithPromo(grandTotalWithPromoCode);
      }

      setCourseTotal(coursesPriceWithDiscout);
      setCourseNoSaving(coursesPriceNoDiscout);
    } else {
      setCourseTotal(0);
      setCourseNoSaving(0);
    }
  }, [courses, promoCodeData, startYear, startMonth]);

  // Use the date from backend
  useEffect(() => {
    if (currentDate) {
      const newDate = new Date(currentDate);
      setDate(newDate);
      currentYear = newDate.getFullYear();
      currentMonth = newDate.getMonth();
      currentDay = newDate.getDate();
    }
  }, [currentDate]);

  const { search } = useLocation();
  const { payment } = queryString.parse(search);

  // Show payment decline error if applicable
  useEffect(() => {
    if (payment === '0') {
      toast({
        title: 'Payment Declined!',
        position: 'top',
        status: 'error',
        isClosable: true,
      });
    }
  }, []);

  const checkCourseCanPurchase = (course: Course) => {
    if (startYear === 0 || startMonth === 0) {
      return false;
    }
    const endDate = new Date(course.endDate);
    const startDate = new Date(course.startDate);

    if (startYear < startDate.getFullYear()) {
      return false;
    } else if (endDate.getFullYear() < startYear) {
      return false;
    } else if (endDate.getFullYear() === startYear && endDate.getMonth() + 1 < startMonth) {
      return false;
    } else if (startDate.getFullYear() === startYear && startDate.getMonth() + 1 > startMonth) {
      return false;
    } else {
      return true;
    }
  };

  const onCheckout = () => {
    history.push({
      pathname: '/checkout/billing-info',
      state: {
        from: 'subscription',
        startYear,
        startMonth,
        promoCode: hasPromoCode ? promoCode : null,
        grandTotalWithPromo,
        totalAmount: courseTotal,
        courses: validCourses,
      },
    });
  };

  const filterYear = () => {
    if (latestCourseDate) {
      const filteredYear = [currentYear];
      for (let year = currentYear + 1; year <= latestCourseDate.getFullYear(); year++) {
        filteredYear.push(year);
      }
      return filteredYear;
    }
    return [];
  };

  const filterMonth = () => {
    if (startYear !== 0 && cutOffDate && courses) {
      const beforeCutOffDate = currentDay <= cutOffDate;
      const filteredMonth = month.filter((m) => {
        const month = Months[m.toLowerCase()] - 1;
        const isCurrentMonth = month === currentMonth && startYear === currentYear;

        if (month < currentMonth && startYear === currentYear) {
          return false;
        }

        for (let i = 0; i < courses.length; i++) {
          const startDate = new Date(courses[i].startDate);
          const endDate = new Date(courses[i].endDate);
          const monthInterval: number[] = [];

          eachMonthOfInterval({ start: startDate, end: endDate }).forEach((item) => {
            const d = new Date(item);
            if (d.getFullYear() === startYear) {
              monthInterval.push(d.getMonth());
            }
          });

          if (monthInterval.includes(month)) {
            if (isCurrentMonth) {
              if (beforeCutOffDate) {
                return true;
              } else {
                return false;
              }
            } else {
              return true;
            }
          }
        }
        return false;
      });

      if (!filteredMonth.includes(month[startMonth - 1]) && startMonth !== 0) {
        setStartMonth(0);
      }
      return filteredMonth;
    }
    return [];
  };

  useSessionExpired(cutOffDateStatusCode);

  if (!courses) {
    return (
      <Box>
        <NavigateTopbar
          currentTab={t('subscription')}
          firstTitle={t('subscription')}
          firstTo="/subscription"
          secondTitle={t('pay_as_you_go')}
          secondTo="/prepaid"
          isWithSearchbar={false}
        />
        <Flex height="100vh" width="100%" justifyContent="center" alignContent="center">
          <Loader />
        </Flex>
      </Box>
    );
  }

  return (
    <Box>
      <NavigateTopbar
        currentTab={t('subscription')}
        firstTitle={t('subscription')}
        firstTo="/subscription"
        secondTitle={t('pay_as_you_go')}
        secondTo="/prepaid"
        isWithSearchbar={false}
      />
      <Flex
        px="8"
        py="4"
        direction="column"
        overflowY="auto"
        as={OverlayScrollbarsComponent}
        options={{ scrollbars: { autoHide: 'scroll' } }}
        height="100vh">
        <Text fontSize="lg" fontWeight="medium">
          {t('please_select_starting_month')}
        </Text>
        <HStack mt="4" spacing="4">
          <Select
            w={{
              base: '100%',
              md: 'fit-content',
            }}
            placeholder="Year"
            borderColor="black"
            _hover={{ borderColor: 'black' }}
            value={startYear}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              setStartYear(Number(e.target.value))
            }
            disabled={!hasCourseItem}>
            {filterYear().map((y) => (
              <option key={y} value={y}>
                {y}
              </option>
            ))}
          </Select>
          <Select
            w={{
              base: '100%',
              md: 'fit-content',
            }}
            placeholder="Month"
            borderColor="black"
            _hover={{ borderColor: 'black' }}
            value={startMonth}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              setStartMonth(Number(e.target.value))
            }
            disabled={!hasCourseItem}>
            {filterMonth().map((m) => (
              <option key={m} value={Months[m.toLowerCase()]}>
                {t(m.toLowerCase())}
              </option>
            ))}
          </Select>
        </HStack>
        <Divider borderColor="#707070" mt="6" />
        <Box overflow="hidden" w="100%">
          {hasCourseItem &&
            courses.map((course) => {
              const canPurchase = checkCourseCanPurchase(course);
              return (
                <ItemCard
                  key={course.cartId}
                  course={course}
                  resetPromoCode={resetPromoCodeMaster}
                  canPurchase={canPurchase}
                />
              );
            })}
        </Box>
        <VStack
          spacing="4"
          alignItems={{
            base: 'flex-start',
            md: 'flex-end',
          }}
          mt="6"
          direction="column">
          {anyCourseHasDiscount && (
            <HStack
              spacing="2"
              fontWeight="semibold"
              fontSize="lg"
              flexWrap={{
                base: 'wrap',
                md: 'unset',
              }}>
              <Box as="span">{t('monthly_savings')}:</Box>
              <Box as="span" color="red">
                {formatPrice(String(courseNoSaving - courseTotal))}
              </Box>
            </HStack>
          )}
          {/* Promo */}
          <PromoCode
            canCheckout={Boolean(validCourses?.length)}
            promoCode={promoCode}
            setPromoCode={setPromoCode}
            resetPromoCode={resetPromoCodeMaster}
          />
          {/* End of Promo */}
          <HStack
            spacing="2"
            fontWeight="semibold"
            fontSize="lg"
            flexWrap={{
              base: 'wrap',
              md: 'unset',
            }}>
            <Box as="span">{t('monthly_recurring_payment')}:</Box>
            {validPromoCode ? (
              <>
                <Box as="span" textDecoration="line-through">
                  {formatPrice(String(courseTotal))}
                </Box>
                <Box as="span" color="red">
                  {formatPrice(String(grandTotalWithPromo))}
                </Box>
              </>
            ) : (
              <Box as="span">{formatPrice(String(courseTotal))}</Box>
            )}
          </HStack>
          <Flex flexDirection="column">
            <Button
              bg="#02F950"
              color="white"
              fontSize={20}
              fontWeight="semibold"
              onClick={onCheckout}
              _hover={{ backgroundColor: '#00C414' }}
              _active={{ backgroundColor: '#00C414' }}
              disabled={!Boolean(validCourses?.length)}>
              {t('checkout_now')}
            </Button>
            <Flex justifyContent="center">
              <Link
                mt={1}
                fontSize={14}
                color="#1900FF"
                href="/privacy-policy"
                cursor="pointer"
                _hover={{ textDecoration: 'underline' }}>
                {t('payment_policy')}
              </Link>
            </Flex>
          </Flex>
        </VStack>
        {hasPeopleAlsoBuy && (
          <Flex
            mt="8"
            flexDirection={{
              base: 'column',
              md: 'row',
            }}>
            <Box minW="50%">
              <Text fontSize={20} fontWeight="bold" mb={5}>
                {t('people_also_buy')}
              </Text>
              {peopleAlsoBuy?.map((item) => (
                <PeopleAlsoBuyCard key={item.id} {...item} />
              ))}
            </Box>
          </Flex>
        )}
      </Flex>
    </Box>
  );
}

export default Subscription;
