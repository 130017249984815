import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  useDisclosure,
  useToast,
  Box,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { selectPurchaseDetails, selectPurchaseDetailsLoading, selectTerminateSubscriptionLoading, selectTerminateSubscriptionSuccess } from 'src/redux/purchase/selectors';
import { getPurhcaseDetails, resetPurchaseHistory, terminateSubscription } from 'src/redux/purchase/actions';
import { formatPrice } from 'src/utils/formatPrice';
import Placeholder from 'src/images/rectangle.png';
import BackTopbar from 'src/components/Topbar/BackTopbar';
import Loader from 'src/components/Loader';
import { useTranslation } from 'react-i18next';
import { month } from 'src/constants/months';

function MyPurchaseDetails(): JSX.Element {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const toast = useToast();
  const history = useHistory();

  const { id } = useParams<{ id: string }>();
  const purchase = useSelector(selectPurchaseDetails);
  const loading = useSelector(selectPurchaseDetailsLoading);
  const terminateLoading = useSelector(selectTerminateSubscriptionLoading);
  const terminateSuccess = useSelector(selectTerminateSubscriptionSuccess);

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    dispatch(getPurhcaseDetails(Number(id)));
  }, [dispatch, id]);

  useEffect(() => {
    if (terminateSuccess) {
      toast({
        title: t('terminate_subscription_successful'),
        position: 'top',
        status: 'success',
        isClosable: true,
        duration: 500,
      });
      setTimeout(() => {
        history.push('/my-purchases');
      }, 1000);

      dispatch(resetPurchaseHistory());
    }
  }, [terminateSuccess]);

  if (!purchase || loading)
    return (
      <Box w="100%" h="100%">
        <BackTopbar title="Purchase Details" to="/my-purchases" isWithSearchbar={false} />
        <Loader />
      </Box>
    );

  const isSubscription = purchase.gateway === 'iPayRecurring';
  const isFreeTrial = purchase.gateway === 'n/a';
  const gateway = purchase.gateway === 'iPayRecurring' ? 'iPayRecurring' : 'iPayOnlineBanking';

  const pageCopy = {
    iPayRecurring: {
      priceIndicator: '/month',
      firstTitle: 'Total fee/month',
      secondTitle: 'Monthly savings',
      thirdTitle: 'Total Monthly Recurring',
      paidBy: 'Billed on 1st of each month',
    },
    iPayOnlineBanking: {
      priceIndicator: '',
      firstTitle: 'Subtotal',
      secondTitle: 'Bundle discount',
      thirdTitle: 'Grand Total',
      paidBy: `Paid by ${String(purchase.paymentMethod?.name)}`,
    },
  };

  const onTerminateSubscription = () => {
    dispatch(terminateSubscription(purchase.refNo));
  };

  return (
    <Box w="100%" h="100%">
      <BackTopbar title={`#${purchase.refNo}`} to="/my-purchases" isWithSearchbar={false} />
      <Box pt="8" pb="16">
        <Flex
          flexDirection={{
            base: 'column',
            md: 'row',
          }}
          justifyContent="space-between"
          px="16"
          fontSize="xl">
          <Text fontWeight="semibold">Order Summary</Text>
          <Text>{purchase.date}</Text>
        </Flex>
        <Box px="16" my="6">
          {purchase.items?.map((item) => (
            <Flex
              key={item.id}
              mb="8"
              pb="4"
              w="full"
              flexDirection={{
                base: 'column',
                md: 'row',
              }}
              alignItems={{
                base: 'flex-start',
                md: 'center',
              }}
              fontSize="lg"
              borderBottomWidth="1px"
              borderBottomColor="gray.500">
              <Flex
                justifyContent="center"
                w={{
                  base: '100%',
                  md: 'auto',
                }}
                mb={{
                  base: '4',
                  md: '0',
                }}>
                <Image
                  src={item.imageURL || Placeholder}
                  minW="55px"
                  minH="55px"
                  w={{
                    base: '150px',
                    md: '55px',
                  }}
                  h={{
                    base: '150px',
                    md: '55px',
                  }}
                  fit="cover"
                />
              </Flex>
              <Box>
                <Text
                  ml={{
                    base: '0',
                    md: '4',
                  }}
                  textTransform="capitalize">
                  {item.title
                    .split('- ')
                    .map((title, id) => {
                      if (id === 3) {
                        return 'Cikgu ' + title;
                      }
                      return title;
                    })
                    .join('- ')}
                </Text>
                {(isSubscription || isFreeTrial) && Boolean(purchase.startMonth) && Boolean(purchase.startYear) && (
                  <Text
                    ml={{
                      base: '0',
                      md: '4',
                    }}
                  >
                    <Text mt={5} as="span">
                      {t('starting_date')}: {t(month[purchase.startMonth - 1].toLowerCase())} {purchase.startYear}
                    </Text>
                  </Text>
                )}
              </Box>
              <Spacer />
              <Text>
                {formatPrice(String(item.priceInfo.finalPrice))}
                {pageCopy[gateway].priceIndicator}
              </Text>
            </Flex>
          ))}
        </Box>
        <Flex
          w="full"
          px="16"
          direction={{
            base: 'column',
            md: 'row',
          }}>
          <Flex
            direction="column"
            w={{
              base: '100%',
              md: '50%',
            }}
            fontSize="xl">
            {purchase.totalSummary.grandTotal !== 'RM0.00' &&
              purchase.billingInfo.address !== null && (
                <>
                  <Text fontWeight="semibold">{t('billing_info')}</Text>
                  <Text mt="4">{purchase.billingInfo.name}</Text>
                  <Text mt="4" whiteSpace="pre-wrap">
                    {`${purchase.billingInfo.address},\n${purchase.billingInfo.postcode} ${purchase.billingInfo.city},\n${purchase.billingInfo.state}.`}
                  </Text>
                  <Text mt="4">{purchase.billingInfo.phone}</Text>
                  {isSubscription && (
                    <>
                      <Flex mt="16">
                        <Button onClick={onOpen}>{t('terminate')}</Button>
                      </Flex>
                      <Modal isOpen={isOpen} onClose={onClose}>
                        <ModalOverlay />
                        <ModalContent>
                          <ModalHeader>{t('terminate_subscription')}</ModalHeader>
                          <ModalCloseButton />
                          <ModalBody>
                            <Text>{t('terminate_confirmation')}</Text>
                          </ModalBody>

                          <ModalFooter>
                            <Button mr="2" onClick={onClose}>
                              {t('cancel')}
                            </Button>
                            <Button
                              colorScheme="red"
                              onClick={onTerminateSubscription}
                              isLoading={terminateLoading}
                            >
                              {t('confirm')}
                            </Button>
                          </ModalFooter>
                        </ModalContent>
                      </Modal>
                    </>
                  )}
                </>
              )}
          </Flex>
          <Flex
            direction="column"
            w={{
              base: '100%',
              md: '50%',
            }}
            fontSize="xl"
            mt={{
              base: '50px',
              md: '0',
            }}>
            <Text fontWeight="semibold">Total Summary</Text>
            <Flex mt="6" fontWeight="medium" justifyContent="space-between" w="full">
              <Text>{pageCopy[gateway].firstTitle}:</Text>
              <Text>{purchase.totalSummary.subTotal}</Text>
            </Flex>
            <Flex mt="4" fontWeight="medium" justifyContent="space-between" w="full">
              <Text>{pageCopy[gateway].secondTitle}:</Text>
              <Text>{purchase.totalSummary.bundleDiscount}</Text>
            </Flex>
            <Flex mt="4" fontWeight="medium" justifyContent="space-between" w="full">
              <Text>Promo code:</Text>
              <Text>{purchase.totalSummary.promoDiscount}</Text>
            </Flex>
            <Flex direction="column" mt="6" pt="6" borderTopColor="gray.500" borderTopWidth="1px">
              <Flex mt="4" justifyContent="space-between" w="full">
                <Text fontWeight="semibold">{pageCopy[gateway].thirdTitle}:</Text>
                <Text fontWeight="medium">{purchase.totalSummary.grandTotal}</Text>
              </Flex>
              {purchase.totalSummary.grandTotal !== 'RM0.00' &&
                purchase.billingInfo.address !== null && (
                  <Text fontSize="lg">{pageCopy[gateway].paidBy}</Text>
                )}
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
}

export default MyPurchaseDetails;
