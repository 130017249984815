import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { HStack, Flex, Text, Input, Button } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

import { IS_DEV } from 'src/constants/environment';
import { PAYMENT_URL } from 'src/constants/api';
import { formatPrice } from 'src/utils/formatPrice';
import { CreateOrderPayload, PaymentDetails } from 'src/redux/order/models/payload';
import { createOrder } from 'src/redux/order/actions';
import { selectNewOrder } from 'src/redux/order/selectors';
import BackTopbar from 'src/components/Topbar/BackTopbar';
import CourseItem from './CourseItem';
import QuestionBankItem from './QuestionBankItem';
import ResourceItem from './ResourceItem';
import { Course } from 'src/models/cart/Course';
import { QuestionBank } from 'src/models/cart/QuestionBank';
import { Resource } from 'src/models/cart/Resource';
import { selectCartItemCount } from 'src/redux/cart/selectors';
import { getCart } from 'src/redux/cart/actions';

interface LocationState {
  from: string;
  duration: number[];
  startYear: number;
  startMonth: number;
  promoCode: string | null;
  grandTotalWithPromo: number;
  totalAmount: number;
  courses: Course[] | undefined;
  questionBanks: QuestionBank[] | undefined;
  resources: Resource[] | undefined;
}

function BillingInfo(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    from,
    duration,
    startYear,
    startMonth,
    promoCode,
    grandTotalWithPromo,
    totalAmount,
    courses,
    questionBanks,
    resources,
  } = useLocation<LocationState>().state;

  let popupPaymentWindow: any;
  const cartItemCount = useSelector(selectCartItemCount);
  const [isPaymentSuccess, setPaymentSuccess] = useState(false);

  const hasPromoCode = Boolean(promoCode);
  const orderCreated = useSelector(selectNewOrder);

  const hasCourses = courses && Boolean(courses.length);
  const hasQuestionBank = questionBanks && Boolean(questionBanks.length);
  const hasResources = resources && Boolean(resources.length);

  const isPrepaid = from === 'prepaid';

  type FormData = {
    email: string;
    name: string;
    phone: string;
    idNumber: string;
    address: string;
    city: string;
    postcode: string;
    state: string;
  };

  const { register, handleSubmit } = useForm<FormData>();

  const setupCartItems = () => {
    const hasPrepaidCart = hasCourses || hasQuestionBank || hasResources;

    // Prepaid payment
    if (hasPrepaidCart && from === 'prepaid') {
      const masterCart = [];

      if (hasCourses) {
        let largestDuration = 0;
        for (let i = 0; i <= duration.length; i++){
          if (duration[i] > largestDuration) {
              largestDuration = duration[i];
          }
        }
        const forCartCourses = courses.map((course, index) => {
          const selectedMonth: number[] = [];
          if (duration && startMonth) {
            const newStartMonth =
              duration[index] === largestDuration ? startMonth : (largestDuration - duration[index]) + startMonth;
            for (let i = 0; i < duration[index]; i++) {
              selectedMonth.push(newStartMonth + i);
            }
          }
          return {
            id: course.id,
            categoryType: 'course',
            months: selectedMonth,
            bundleDiscount: course.bundleDiscount ?? 0,
          };
        });
        masterCart.push(...forCartCourses);
      }

      if (hasQuestionBank) {
        const forCartQuestionBank = questionBanks.map((question) => ({
          id: question.id,
          categoryType: 'questionbank',
          months: null,
          bundleDiscount: 0,
        }));
        masterCart.push(...forCartQuestionBank);
      }

      if (hasResources) {
        const forCartResources = resources.map((resource) => ({
          id: resource.id,
          categoryType: 'resource',
          months: null,
          bundleDiscount: 0,
        }));
        masterCart.push(...forCartResources);
      }

      return masterCart;
    }

    //Subscription payment
    if (hasCourses && from === 'subscription') {
      return courses.map((course) => ({
        id: course.id,
        bundleDiscount: course.bundleDiscount ?? 0,
      }));
    }

    return [];
  };

  const onSubmit = (data: FormData) => {
    const { idNumber, name, email, phone, address, city, postcode, state } = data;
    const cartItems = setupCartItems();

    let paymentDetails: PaymentDetails = {
      freeTrial: !isPrepaid && false,
      gateway: isPrepaid ? 'iPayOnlineBanking' : 'iPayRecurring',
      promocode: Boolean(promoCode) ? String(promoCode) : '',
      idNumber,
      name,
      email,
      phone,
      address,
      city,
      postcode,
      state,
      country: 'Malaysia',
    };

    if (from === 'subscription') {
      paymentDetails = {
        ...paymentDetails,
        startMonth: startMonth,
        startYear: startYear,
      };
    }

    const newOrder: CreateOrderPayload = {
      paymentDetails,
      cartItems,
      testMode: IS_DEV,
    };

    dispatch(createOrder(newOrder));
  };

  // Payment popup window
  const customPaymentPopup = (paymentUrl: any) => {
    // Popup window attributes
    const windowWidth = 640;
    const windowHeight = 1024;
    const left = window.screen.width / 2 - windowWidth / 2;
    const top = window.screen.height / 2 - windowHeight / 2;

    popupPaymentWindow = window.open(
      paymentUrl,
      'Tavis',
      `menubar=no,location=yes,scrollbars=no,status=` +
        `no,width=${windowWidth},height=${windowHeight},top=${top},left=${left}`,
    );
    try {
      popupPaymentWindow.focus();
    } catch (e) {
      alert("Pop-up window is disabled. Please enable in the settings.");
    }

    // Listener to popup window open or close
    let timer = setInterval(() => {
      if (popupPaymentWindow?.closed) {
        clearInterval(timer);
        setPaymentSuccess(false);
      } else {
        // Polling get cart api in every 3s
        dispatch(getCart());
      }
    }, 3000);
  };

  // Changes on orderCreated, call payment popup window
  useEffect(() => {
    if (orderCreated) {
      const paymentUrl = queryString.stringifyUrl({
        url: PAYMENT_URL,
        query: { ...orderCreated },
      });

      customPaymentPopup(paymentUrl);

      // Handle redirect if payment success by cart item count
      if (cartItemCount === 0) {
        setPaymentSuccess(true);
        if (isPaymentSuccess) {
          popupPaymentWindow?.close();
          history.push('/my-purchases');
        }
      }
    }
  }, [orderCreated, cartItemCount, isPaymentSuccess, history, popupPaymentWindow]);

  const totalPrice = {
    subscription: totalAmount,
    prepaid: totalAmount,
  };

  return (
    <Flex w="100%" h="100%" flexDirection="column" minH="100vh">
      <BackTopbar title={t('checkout_now')} />
      <Flex
        p="8"
        flexDirection={{
          base: 'column',
          lg: 'row',
        }}
        h="100%"
        w="100%"
      >
        <Flex
          mr={{
            base: 0,
            lg: 8,
          }}
          flex="1"
          direction="column"
          as="form"
          onSubmit={handleSubmit(onSubmit)}
          mb={{
            base: '20px',
            lg: 0,
          }}
        >
          <Text fontSize="2xl" fontWeight="semibold">
            {t('billing_info')}
          </Text>
          <Text mt="4">{t('email')}</Text>
          <Input
            placeholder="johndoe@test.com"
            mt="2"
            type="email"
            {...register('email', { required: true })}
          />
          <Text mt="4">{t('full_name')}</Text>
          <Input placeholder="John Doe" mt="2" {...register('name', { required: true })} />
          <Text mt="4">{t('phone_no')}</Text>
          <Input placeholder="60123456789" mt="2" {...register('phone', { required: true })} />
          <Text mt="4">{t('ic')}</Text>
          <Input
            placeholder="010110-10-1010"
            mt="2"
            {...register('idNumber', { required: true })}
          />
          <Text mt="4">{t('address')}</Text>
          <Flex direction="column">
            <Input
              mt="2"
              placeholder={t('street')}
              {...register('address', { required: true })}
            />
            <HStack mt="2" spacing="4">
              <Input placeholder={t('city')} {...register('city', { required: true })} />
              <Input placeholder={t('postcode')} {...register('postcode', { required: true })} />
            </HStack>
            <Input mt="2" placeholder={t('state')} {...register('state', { required: true })} />
          </Flex>
          <Flex mt="4" justifyContent="flex-end">
            <Button
              bg="#19E596"
              px="8"
              py="2"
              fontWeight="semibold"
              color="white"
              fontSize="lg"
              _hover={{ bg: '#17d48b' }}
              _active={{ bg: '#14c782' }}
              type="submit">
              {t('continue')}
            </Button>
          </Flex>
        </Flex>
        <Flex
          flex="1"
          direction="column"
          p="10"
          bg="#65CD7D"
          color="white"
          borderRadius="xl"
          boxShadow="dark-lg"
        >
          <Text fontSize="2xl" fontWeight="semibold">
            {t('cart')}
          </Text>
          <Flex mt="4" fontSize="lg" fontWeight="medium">
            <Text w="75%">{t('items')}</Text>
            <Text w="25%" whiteSpace="nowrap">{t('amount')}</Text>
          </Flex>
          <Flex mt="4" direction="column" overflow="auto">
            {courses?.map((course, index) => (
              <CourseItem
                key={course.cartId}
                course={course}
                isPrepaid={isPrepaid}
                duration={duration ? duration[index] : undefined}
                startYear={startYear ? startYear : undefined}
                startMonth={startMonth ? startMonth : undefined}
              />
            ))}
            {isPrepaid &&
              questionBanks?.map((question) => (
                <QuestionBankItem key={question.cartId} question={question} />
              ))}
            {isPrepaid &&
              resources?.map((resource) => (
                <ResourceItem key={resource.cartId} resource={resource} />
              ))}
          </Flex>
          <Flex
            justifyContent="space-between"
            fontSize="lg"
            borderTopWidth="1px"
            borderTopColor="white"
            pt="4">
            <Text>{t('total')}</Text>
            {hasPromoCode ? (
              <HStack spacing="2">
                {isPrepaid ? (
                  <Text textDecoration="line-through">
                    {formatPrice(String(totalPrice.prepaid))}
                  </Text>
                ) : (
                  <Text textDecoration="line-through">
                    {formatPrice(String(totalPrice.subscription)) + '/month'}
                  </Text>
                )}
                <Text>
                  {isPrepaid
                    ? formatPrice(String(grandTotalWithPromo))
                    : formatPrice(String(grandTotalWithPromo)) + '/month'}
                </Text>
              </HStack>
            ) : (
              <Text>
                {isPrepaid
                  ? formatPrice(String(totalPrice.prepaid))
                  : formatPrice(String(totalPrice.subscription)) + '/month'}
              </Text>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default BillingInfo;