import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Flex, Select, HStack, Grid, Box, Text } from '@chakra-ui/react';
import { Waypoint } from 'react-waypoint';

import { CoursesQuery } from 'src/models/Course';
import { getCourses, getMoreCourses, resetCourses } from 'src/redux/course/actions';
import {
  selectCourses,
  selectCoursesLoading,
  selectCoursesPagination,
  selectCoursesRefetching,
} from 'src/redux/course/selectors';
import TitleTopbar from 'src/components/Topbar/TitleTopbar';
import FilterTab from 'src/components/FilterTab';
import CourseCard from './CourseCard';
import RefetchingLoader from './RefetchingLoader';
import CourseLoader from './CourseLoader';
import { selectLoginStatus } from 'src/redux/auth/selectors';

function Courses(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedSubject, setSelectedSubject] = useState<string[]>([]);
  const [selectedLevel, setSelectedLevel] = useState<string[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState<string[]>([]);
  const [selectedWeekday, setSelectedWeekday] = useState<string[]>([]);
  const [minPrice, setMinPrice] = useState<string | null>(null);
  const [maxPrice, setMaxPrice] = useState<string | null>(null);
  const [sort, setSort] = useState<string | null>(null);
  const [keyword, setKeyword] = useState<string | null>(null);

  const courses = useSelector(selectCourses);
  const loading = useSelector(selectCoursesLoading);
  const refetching = useSelector(selectCoursesRefetching);
  const pagination = useSelector(selectCoursesPagination);
  const loginStatus = useSelector(selectLoginStatus);

  const getSort = (value: string | null) => {
    if (value === 'fullname') return [value, 'ASC'];
    if (value === 'price_asc') return ['price', 'ASC'];
    if (value === 'price_des') return ['price', 'DESC'];
    return [];
  };

  const getApiQuery = () => {
    const [sortBy, sortOrder] = getSort(sort);

    const apiQuery: CoursesQuery = {
      subject: selectedSubject.join(','),
      form: selectedLevel.join(','),
      language: selectedLanguage.join(','),
      weekdays: selectedWeekday.join(','),
      price_up: maxPrice,
      price_down: minPrice,
      sortby: sortBy,
      sortorder: sortOrder,
      keyword: keyword,
      limit: 10,
    };

    return apiQuery;
  };

  useEffect(() => {
    // Wait 0.5s before make API request
    const debounce = setTimeout(() => dispatch(getCourses(getApiQuery())), 500);
    return () => clearTimeout(debounce);
  }, [
    dispatch,
    selectedSubject,
    selectedLevel,
    selectedLanguage,
    selectedWeekday,
    minPrice,
    maxPrice,
    sort,
    keyword,
    loginStatus,
  ]);

  // Avoid stale course list to appear after leaving page
  useEffect(() => {
    return () => {
      dispatch(resetCourses());
    };
  }, [dispatch]);

  if (!courses) return <CourseLoader />;

  const isListEmpty = Boolean(!courses.length);
  const sortHasValue = Boolean(sort);

  const onEnter = (position: number) => {
    const hasMore = pagination?.page !== pagination?.totalPages;
    const isLastItem = position === Number(courses.length - 1);

    const apiQuery = {
      ...getApiQuery(),
      page: pagination ? pagination.page + 1 : 1,
    };

    if (isLastItem && hasMore) {
      dispatch(getMoreCourses(apiQuery));
    }
  };

  const renderList = () => {
    if (loading) return <CourseLoader />;

    if (isListEmpty)
      return (
        <Flex w="full" h="100vh" justifyContent="center" alignItems="center">
          <Text>{t('no_classes_available')}</Text>
        </Flex>
      );

    return (
      <Box>
        <Grid
          templateColumns={{
            sm: 'repeat(1, 1fr)',
            md: 'repeat(2, 1fr)',
            lg: 'repeat(3, 1fr)',
            xl: 'repeat(4, 1fr)',
          }}
          templateRows="repeat(3, 1fr)"
          gap="4"
          p="8"
          w="full"
          minW="75%">
          {courses.map((course, position) => (
            <Waypoint key={course.id} onEnter={() => onEnter(position)}>
              <CourseCard {...course} />
            </Waypoint>
          ))}
          {refetching && <RefetchingLoader />}
        </Grid>
      </Box>
    );
  };

  return (
    <Box>
      <TitleTopbar title={t('classes')} keywordSearch={{ keyword, setKeyword }} />
      <Flex m="3vh" mt="0" direction="column" h="100%">
        <HStack px="8" py="2vh" bg="white" spacing="8">
          <Flex
            py="2"
            px="4"
            borderBottomWidth="1px"
            borderBottomColor={sortHasValue ? 'black' : 'gray.400'}>
            <Select
              w="full"
              color={sortHasValue ? 'black' : 'gray.400'}
              fontSize="lg"
              placeholder="Sort By"
              variant="unstyled"
              icon={<React.Fragment />}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setSort(e.target.value)}>
              <option value="fullname">{t('alphabetical')}</option>
              <option value="price_asc">{t('price_asc')}</option>
              <option value="price_des">{t('price_des')}</option>
            </Select>
          </Flex>
        </HStack>

        <Flex
          flexDirection={{
            base: 'column',
            md: 'row',
          }}>
          <Flex
            flexDirection="column"
            w={{
              base: '100%',
              md: '35%',
              lg: '350px',
            }}
          >
            <Text fontSize={18} fontWeight="medium" as="h2" mt="4" ml="8">
              {t('filter')}:
            </Text>
            <FilterTab
              subjectFilter={{ selectedSubject, setSelectedSubject }}
              levelFilter={{ selectedLevel, setSelectedLevel }}
              priceFilter={{ minPrice, setMinPrice, maxPrice, setMaxPrice }}
              languageFilter={{ selectedLanguage, setSelectedLanguage }}
              weekdayFilter={{ selectedWeekday, setSelectedWeekday }}
            />
          </Flex>
          {renderList()}
        </Flex>
      </Flex>
    </Box>
  );
}

export default Courses;
