export const GET_LEVELS = 'resources/getLevels';
export const GET_LEVELS_SUCCESS = 'resources/getLevelsSuccess';
export const GET_LEVELS_ERROR = 'resouces/getLevelsError';
export const GET_LEVELS_NETWORK_ERROR = 'resources/getLevelsNetworkError';

export const GET_READING_MATERIALS = 'resources/getReadingMaterials';
export const GET_READING_MATERIALS_SUCCESS = 'resources/getReadingMaterialsSuccess';
export const GET_READING_MATERIALS_ERROR = 'resources/getReadingMaterialsError';
export const GET_READING_MATERIALS_NETWORK_ERROR = 'resources/getReadingMaterialsNetworkError';

export const GET_MORE_READING_MATERIALS = 'resources/getMoreReadingMaterials';
export const GET_MORE_READING_MATERIALS_SUCCESS = 'resources/getMoreReadingMaterialsSuccess';

export const GET_RECORDED_LECTURES = 'resources/getRecordedLectures';
export const GET_RECORDED_LECTURES_SUCCESS = 'resources/getRecordedLecturesSuccess';
export const GET_RECORDED_LECTURES_ERROR = 'resources/getRecordedLecturesError';
export const GET_RECORDED_LECTURES_NETWORK_ERROR = 'resources/getRecordedLecturesNetworkError';

export const GET_MORE_RECORDED_LECTURES = 'resources/getMoreRecordedLectures';
export const GET_MORE_RECORDED_LECTURES_SUCCESS = 'resources/getMoreRecordedLecturesSuccess';

export const RESET_RESOURCES = 'resources/resetResources';
