import { Course } from 'src/models/Course';
import { CourseListItem } from 'src/models/CourseListItem';

import { CourseState } from './models';
import AppState from '../rootState';
import { Pagination } from 'src/models/Pagination';

const selectCourseState = (state: AppState): CourseState => state.course;

export const selectCourses = (state: AppState): CourseListItem[] | null =>
  selectCourseState(state).courseList;

export const selectCoursesStatusCode = (state: AppState): number | null =>
  selectCourseState(state).courseListStatusCode;

export const selectCoursesNetworkError = (state: AppState): boolean =>
  selectCourseState(state).courseListNetworkError;

export const selectCourseDetails = (state: AppState): Course[] | null =>
  selectCourseState(state).courseDetails;

export const selectCourseDetailsStatusCode = (state: AppState): number | null =>
  selectCourseState(state).courseDetailsStatusCode;

export const selectCourseDetailsNetworkError = (state: AppState): boolean =>
  selectCourseState(state).courseDetailsNetworkError;

export const selectCoursesLoading = (state: AppState): boolean | undefined =>
  selectCourseState(state).courseListLoading;

export const selectCoursesRefetching = (state: AppState): boolean | undefined =>
  selectCourseState(state).courseListRefetching;

export const selectCoursesPagination = (state: AppState): Pagination | undefined =>
  selectCourseState(state).courseListPagination;
