import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function TimeIcon(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <path
        d="M12,0 C18.627417,0 24,5.372583 24,12 C23.9922206,18.624192 18.624192,23.9922206 12,24 C5.372583,24 0,18.627417 0,12 C0,5.372583 5.372583,0 12,0 Z M12,2.82352941 C6.93197524,2.82352941 2.82352941,6.93197524 2.82352941,12 C2.82352941,17.0680248 6.93197524,21.1764706 12,21.1764706 C17.0680248,21.1764706 21.1764706,17.0680248 21.1764706,12 C21.1710239,6.93423303 17.065767,2.82897606 12,2.82352941 Z M12,4.23529412 C12.7796961,4.23529412 13.4117647,4.86736271 13.4117647,5.64705882 L13.4117647,11.1275294 L16.8663529,12.8555294 C17.4512191,13.1486596 17.7580776,13.8052075 17.6078063,14.4419274 C17.457535,15.0786474 16.889506,15.5287145 16.2352941,15.5294118 C16.0166555,15.5289589 15.80111,15.4777306 15.6056471,15.3797647 L11.3703529,13.2621176 C10.8915982,13.0235518 10.588788,12.5349014 10.5882353,12 L10.5882353,5.64705882 C10.5882353,4.86736271 11.2203039,4.23529412 12,4.23529412 Z"
        id="Combined-Shape"
        fill="currentColor"
        fillRule="nonzero"
      ></path>
    </Icon>
  );
}
