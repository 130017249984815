import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Flex,
  Button,
  Text,
  Stack,
  VStack,
  HStack,
  PinInput,
  PinInputField,
  Img,
  Heading,
  Box,
  useToast,
} from '@chakra-ui/react';
import TavisLogo from 'src/images/tavis_logo.svg';
import Background from 'src/images/img_login_register.png';

import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectConfirmVerificationCodeMessage,
  selectConfirmVerificationCodeNetworkError,
  selectConfirmVerificationCodeStatusCode,
  selectResendVerificationCodeMessage,
  selectResendVerificationCodeNetworkError,
  selectResendVerificationCodeStatusCode,
  selectSkipVerificationMessage,
  selectSkipVerificationStatusCode,
  selectverificationSkipsLeft,
} from 'src/redux/auth/selectors';
import {
  clearVerificationStatus,
  confirmVerificationCode,
  resendVerificationCode,
  skipVerification,
} from 'src/redux/auth/actions';
import { getButtonStatus } from 'src/utils/getButtonStatus';

interface LocationState {
  email: string;
}

function EmailVerification(): JSX.Element {
  const { t } = useTranslation();
  const toast = useToast();
  const location = useLocation<LocationState>();
  const dispatch = useDispatch();
  const history = useHistory();

  const confirmVerificationCodeMessage = useSelector(selectConfirmVerificationCodeMessage);
  const confirmVerificationCodeStatusCode = useSelector(selectConfirmVerificationCodeStatusCode);
  const confirmVerificationCodeNetworkError = useSelector(
    selectConfirmVerificationCodeNetworkError,
  );

  const resendVerificationCodeMessage = useSelector(selectResendVerificationCodeMessage);
  const resendVerificationCodeStatusCode = useSelector(selectResendVerificationCodeStatusCode);
  const resendVerificationCodeNetworkError = useSelector(selectResendVerificationCodeNetworkError);

  const verificationSkipsLeft = useSelector(selectverificationSkipsLeft);
  const skipVerificationMessage = useSelector(selectSkipVerificationMessage);
  const skipVerificationStatusCode = useSelector(selectSkipVerificationStatusCode);

  const [verificationCode, setVerificationCode] = useState('');
  const [disableButton, setDisableButton] = useState(true);
  const [showResendCodeMessage, setShowResendCodeMessage] = useState(false);
  const [showResendCodeButton, setShowResendCodeButton] = useState(false);
  const [timerText, setTimerText] = useState('');

  const timer = useRef<NodeJS.Timer>();
  const timerCounter = useRef(60);
  const isComponentUnmount = useRef(false);

  useEffect(() => {
    const debounce = setTimeout(() => {
      setShowResendCodeButton(true);
      setShowResendCodeMessage(true);
    }, 2000);

    return () => {
      clearTimeout(debounce);
      isComponentUnmount.current = true;
      if (timer.current !== undefined) {
        clearInterval(timer.current);
      }
    };
  }, []);

  useEffect(() => {
    return () => {
      if (confirmVerificationCodeStatusCode !== 200 && isComponentUnmount.current) {
        dispatch(clearVerificationStatus());
      }
    };
  }, [confirmVerificationCodeStatusCode]);

  useEffect(() => {
    if (confirmVerificationCodeStatusCode === 200) {
      dispatch(clearVerificationStatus());
      // history.push('/');
      history.push('/onboarding');
    } else if (confirmVerificationCodeMessage) {
      toast({
        position: 'top',
        status: 'error',
        title: confirmVerificationCodeMessage,
      });
    } else if (confirmVerificationCodeNetworkError) {
      toast({
        position: 'top',
        status: 'error',
        title: 'Could not verify verification code. Please try again',
      });
    }
  }, [
    confirmVerificationCodeMessage,
    confirmVerificationCodeStatusCode,
    confirmVerificationCodeNetworkError,
    dispatch,
    history,
    toast,
  ]);

  useEffect(() => {
    if (resendVerificationCodeMessage && resendVerificationCodeStatusCode === 200) {
      toast({
        position: 'top',
        status: 'info',
        title: resendVerificationCodeMessage,
      });
    } else if (resendVerificationCodeMessage) {
      toast({
        position: 'top',
        status: 'error',
        title: resendVerificationCodeMessage,
      });
    } else if (resendVerificationCodeNetworkError) {
      toast({
        position: 'top',
        status: 'error',
        title: `Could not send verification code to ${location.state.email}. Please try again`,
      });
    }
  }, [
    resendVerificationCodeMessage,
    resendVerificationCodeStatusCode,
    resendVerificationCodeNetworkError,
    dispatch,
    location.state.email,
    toast,
  ]);

  useEffect(() => {
    if (skipVerificationStatusCode === 200 && skipVerificationMessage) {
      toast({
        title: skipVerificationMessage,
        position: 'top',
        status: 'success',
        isClosable: true,
        duration: 1500,
      });
      dispatch(clearVerificationStatus());
      // history.push('/');
      history.push('/onboarding');
    } else if (skipVerificationMessage) {
      toast({
        position: 'top',
        status: 'error',
        isClosable: true,
        duration: 1500,
        title: skipVerificationMessage,
      });
      dispatch(clearVerificationStatus());
    }
  }, [skipVerificationMessage, skipVerificationStatusCode]);

  const showResend = () => {
    if (showResendCodeButton && showResendCodeMessage) {
      return (
        <Text
          fontWeight="medium"
          textAlign="center"
          mb={{
            base: 0,
            sm: 28,
          }}>
          Didn&apos;t receive?{' '}
          <Box
            as="span"
            color="#0062FF"
            onClick={onClickResendCode}
            cursor="pointer"
            _hover={{ textDecoration: 'underline' }}
            _active={{ textDecoration: 'underline' }}>
            Click here to resend
          </Box>
        </Text>
      );
    } else if (showResendCodeMessage) {
      return (
        <Text fontWeight="medium" mb={28}>
          Didn&apos;t receive? Resend in {timerText} seconds
        </Text>
      );
    }
    return <></>;
  };

  const onCompleteVerification = (value: string) => {
    setVerificationCode(value);
  };

  const onSubmitVerificationCode = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(
      confirmVerificationCode({
        email: location.state.email,
        code: verificationCode,
      }),
    );
  };

  const onClickResendCode = () => {
    setShowResendCodeButton(false);
    dispatch(
      resendVerificationCode({
        email: location.state.email,
      }),
    );

    timer.current = setInterval(() => {
      timerCounter.current--;

      if (timerCounter.current <= 0) {
        setShowResendCodeButton(true);
        if (timer.current !== undefined) {
          clearInterval(timer.current);
        }
        timer.current = undefined;
        timerCounter.current = 60;
      }
      setTimerText(timerCounter.current.toString());
    }, 1000);
  };

  const onClickSkip = () => {
    dispatch(skipVerification());
  };

  return (
    <Flex h="100vh">
      <Stack
        display={{
          base: 'none',
          lg: 'flex',
        }}
        direction="column"
        align="center"
        justify="center"
        spacing={20}
        w="40%"
        h="100%"
        overflow="hidden">
        <Img src={TavisLogo} width="328px" />
        <Img src={Background} />
      </Stack>
      <Stack
        color="white"
        backgroundColor="#65CD7D"
        h="100%"
        w={{
          base: '100%',
          lg: '60%',
        }}
        direction="column"
        align="center"
        justify="center"
        spacing={8}
        m="auto"
        p="20">
        <VStack
          as="form"
          onSubmit={onSubmitVerificationCode}
          color="white"
          spacing={{
            base: 10,
            sm: 20,
          }}
          m="auto"
          maxW="700px"
          width="100%"
          shouldWrapChildren>
          <Heading
            as="h1"
            fontSize={{
              base: 20,
              sm: 34,
            }}
            fontWeight="bold"
            textAlign="center">
            {t('verification_code')}
          </Heading>
          <Text
            fontSize={{
              base: 16,
              sm: 26,
            }}
            textAlign="center">
            {t('email_verification_instruction', { email: location.state.email })}
          </Text>
          <HStack mb={-10}>
            <PinInput
              type="number"
              placeholder=""
              autoFocus={true}
              onChange={(value) => setDisableButton(getButtonStatus(value))}
              onComplete={onCompleteVerification}>
              <PinInputField
                backgroundColor="white"
                color="black"
                w={{
                  base: '30px',
                  sm: '60px',
                }}
                h={{
                  base: '30px',
                  sm: '60px',
                }}
                fontSize={{
                  base: '16px',
                  sm: '30px',
                }}
                fontWeight="bold"
              />
              <PinInputField
                backgroundColor="white"
                color="black"
                w={{
                  base: '30px',
                  sm: '60px',
                }}
                h={{
                  base: '30px',
                  sm: '60px',
                }}
                fontSize={{
                  base: '16px',
                  sm: '30px',
                }}
                fontWeight="bold"
              />
              <PinInputField
                backgroundColor="white"
                color="black"
                w={{
                  base: '30px',
                  sm: '60px',
                }}
                h={{
                  base: '30px',
                  sm: '60px',
                }}
                fontSize={{
                  base: '16px',
                  sm: '30px',
                }}
                fontWeight="bold"
              />
              <PinInputField
                backgroundColor="white"
                color="black"
                w={{
                  base: '30px',
                  sm: '60px',
                }}
                h={{
                  base: '30px',
                  sm: '60px',
                }}
                fontSize={{
                  base: '16px',
                  sm: '30px',
                }}
                fontWeight="bold"
              />
              <PinInputField
                backgroundColor="white"
                color="black"
                w={{
                  base: '30px',
                  sm: '60px',
                }}
                h={{
                  base: '30px',
                  sm: '60px',
                }}
                fontSize={{
                  base: '16px',
                  sm: '30px',
                }}
                fontWeight="bold"
              />
              <PinInputField
                backgroundColor="white"
                color="black"
                w={{
                  base: '30px',
                  sm: '60px',
                }}
                h={{
                  base: '30px',
                  sm: '60px',
                }}
                fontSize={{
                  base: '16px',
                  sm: '30px',
                }}
                fontWeight="bold"
              />
            </PinInput>
          </HStack>
          {showResend()}
          <HStack>
            <Button
              disabled={verificationSkipsLeft === null || verificationSkipsLeft === undefined}
              w="100%"
              maxW="150px"
              h="50px"
              bg="#595959"
              fontSize={{
                base: 16,
                sm: 26,
              }}
              fontWeight="medium"
              _hover={{ bg: '#303030' }}
              _active={{ bg: '#303030' }}
              onClick={onClickSkip}
            >
              {t('skip')} ({verificationSkipsLeft ? verificationSkipsLeft : 0})
            </Button>
            <Button
              isDisabled={disableButton}
              w="100%"
              maxW="150px"
              h="50px"
              bg="#19AED9"
              fontSize={{
                base: 16,
                sm: 26,
              }}
              fontWeight="medium"
              _hover={{ bg: '#007FA7' }}
              _active={{ bg: '#007FA7' }}
              type="submit">
              {t('confirm')}
            </Button>
          </HStack>
        </VStack>
      </Stack>
    </Flex>
  );
}

export default EmailVerification;
