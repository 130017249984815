import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function EditIcon(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <g id="Edit_icon" data-name="Edit icon" transform="translate(-367 1)">
        <path
          id="Path_1131"
          data-name="Path 1131"
          d="M11.946,6H4.988A1.988,1.988,0,0,0,3,7.988V21.9a1.988,1.988,0,0,0,1.988,1.988H18.9A1.988,1.988,0,0,0,20.891,21.9V14.946"
          transform="translate(365 -3.892)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_1132"
          data-name="Path 1132"
          d="M22.437,3.436a2.109,2.109,0,0,1,2.982,2.982L15.976,15.86,12,16.854l.994-3.976Z"
          transform="translate(361.964 -2.818)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </Icon>
  );
}
