import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, HStack, Spinner, Box, useMediaQuery } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import format from 'date-fns/format';
import { useLocation } from 'react-router-dom';

import { EventsQuery } from 'src/models/Event';
import { getEvents, resetEvents } from 'src/redux/event/actions';
import {
  selectEvents,
  selectEventsStatusCode,
  selectEventsNetworkError,
  selectEventsLoading,
} from 'src/redux/event/selectors';
import { selectProfile } from 'src/redux/profile/selectors';
import TitleTopbar from 'src/components/Topbar/TitleTopbar';
import Loader from 'src/components/Loader';
import NetworkErrorComponent from 'src/components/NetworkError';
import useSessionExpired from 'src/components/SessionExpired';
import Calendar from './Calendar';
import { selectAccountVerified } from 'src/redux/auth/selectors';

interface LocationState {
  needDelay?: boolean;
}

function Timetable(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLargerThanMd] = useMediaQuery('(min-width: 768px)');

  const events = useSelector(selectEvents);
  const statusCode = useSelector(selectEventsStatusCode);
  const profile = useSelector(selectProfile);
  const networkError = useSelector(selectEventsNetworkError);
  const eventsLoading = useSelector(selectEventsLoading);
  const accountVerified = useSelector(selectAccountVerified);

  const [date, setDate] = useState<Date>(new Date());
  const [daysInMonth, setDaysInMonth] = useState<Date[]>([]);

  const needDelay = useRef<boolean | undefined>((location.state as LocationState)?.needDelay);

  const onClickPreviousMonth = () => {
    const tempDate = date;
    const newDate = new Date(tempDate.getFullYear(), tempDate.getMonth() - 1, 1);
    setDate(newDate);
  };

  const onClickNextMonth = () => {
    const tempDate = date;
    const newDate = new Date(tempDate.getFullYear(), tempDate.getMonth() + 1, 1);
    setDate(newDate);
  };

  useEffect(() => {
    return () => {
      dispatch(resetEvents());
    };
  }, []);
  /*
   *  To load all days in the month
   */
  useEffect(() => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const daysInMonth: Date[] = [];
    const lastDay = parseInt(
      new Date(year, month + 1, 0).toLocaleDateString('en-us', { day: 'numeric' }),
    );

    for (let i = 1; i <= lastDay; i++) {
      const newDate = new Date(year, month, i);
      daysInMonth.push(newDate);
    }

    const lastDate = daysInMonth[daysInMonth.length - 1];
    const apiQuery: EventsQuery = {
      from: format(daysInMonth[0], "yyyy-MM-dd'T'HH:mm"),
      to: format(
        new Date(lastDate.getFullYear(), lastDate.getMonth() + 1, 1),
        "yyyy-MM-dd'T'HH:mm",
      ),
    };

    setDaysInMonth(daysInMonth);
    if (needDelay.current) {
      needDelay.current = false;
      setTimeout(() => {
        dispatch(getEvents(apiQuery));
      }, 2000);
    } else {
      dispatch(getEvents(apiQuery));
    }
  }, [dispatch, date]);

  useSessionExpired(statusCode);
  if (networkError) return <NetworkErrorComponent />;

  // Fetching profile
  if (!profile)
    return (
      <Box>
        <TitleTopbar title={t('Timetable')} />
        <Loader />
      </Box>
    );

  // Fetching purchased classes
  if (eventsLoading || !events) {
    return (
      <Flex maxH="100vh">
        <Flex
          overflow="hidden"
          h="96vh"
          w="100%"
          direction="column"
          borderRadius="10"
          bg="white"
          my="4">
          <TitleTopbar title={t('Timetable')} subtitle={t('greetings')} isWithSearchbar={false} />
          <HStack flex="1" spacing={5} px={10} py={5}>
            <Flex
              bg="white"
              h="100%"
              w="100%"
              borderRadius={10}
              p={10}
              pb={5}
              pr={5}
              flexDirection="column"
              boxShadow="0 0 5px gray">
              <Flex h="100%" w="100%" justifyContent="center" alignItems="center">
                <Spinner />
              </Flex>
            </Flex>
          </HStack>
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex minH="fit-content" py="4" direction="column">
      <TitleTopbar
        title={t('dashboard')}
        subtitle={t('greetings', { name: profile.fullname })}
        isWithSearchbar={false}
        showVerifyEmailWarning={!accountVerified || undefined}
      />
      <Flex h="100%" px={10} py={5}>
        <Flex
          bg="white"
          h="100%"
          w="100%"
          borderRadius={10}
          p={{ base: 5, md: 10 }}
          pb={5}
          pr={5}
          direction="column"
          boxShadow="0 0 5px gray">
          <Calendar
            events={events}
            daysInMonth={daysInMonth}
            onClickPreviousMonth={onClickPreviousMonth}
            onClickNextMonth={onClickNextMonth}
            date={date}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Timetable;
