import { Form } from 'src/models/Form';

import { GET_LEVEL_LIST, GET_LEVEL_LIST_SUCCESS } from './constants';
import { LevelActionType, LevelState } from './models';

export const levelInitialState: LevelState = {
  // Subject list
  levelList: null,
  levelListLoading: false,
  levelListErrors: false,
};

export const levelReducer = (state = levelInitialState, action: LevelActionType): LevelState => {
  switch (action.type) {
    case GET_LEVEL_LIST:
      return {
        ...state,
        levelListLoading: true,
      };
    case GET_LEVEL_LIST_SUCCESS: {
      const formattedLevels = action.payload.data.objects.map(
        ({ id, formName }): Form => ({ id, name: formName }),
      );
      return {
        ...state,
        levelListLoading: false,
        levelList: formattedLevels,
      };
    }
    default:
      return state;
  }
};
