import { ajax, AjaxError, AjaxResponse } from 'rxjs/ajax';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { combineEpics, ofType, ActionsObservable, Epic } from 'redux-observable';

import { API_URL } from 'src/constants/api';
import {
  CREATE_FREE_CLASS_ORDER,
  CREATE_FREE_CLASS_ORDER_ERROR,
  CREATE_FREE_CLASS_ORDER_NETWORK_ERROR,
  CREATE_FREE_CLASS_ORDER_SUCCESS,
  CREATE_ORDER,
  CREATE_ORDER_SUCCESS,
} from './constants';
import {
  CreateFreeClassOrderAction,
  CreateFreeClassOrderSuccessAction,
  CreateOrderAction,
  CreateOrderSuccessAction,
} from './models/actions';
import { CreateFreeClassOrderResponse, CreateOrderResponse } from './models/responses';
import { of } from 'rxjs';
import { ErrorResponse } from 'src/models/ErrorResponse';
import { LOGOUT } from '../auth/constants';

function createOrderEpic(action$: ActionsObservable<CreateOrderAction>) {
  return action$.pipe(
    ofType(CREATE_ORDER),
    switchMap((action) => {
      return ajax({
        url: API_URL + '/order',
        method: 'post',
        withCredentials: true,
        body: action.payload,
        headers: {
          'content-type': 'application/json;charset=UTF-8',
        },
      }).pipe(
        map((payload: AjaxResponse): CreateOrderSuccessAction => {
          return {
            type: CREATE_ORDER_SUCCESS,
            payload: payload.response as CreateOrderResponse,
          };
        }),
      );
    }),
  );
}

function createFreeClassOrderEpic(action$: ActionsObservable<CreateFreeClassOrderAction>) {
  return action$.pipe(
    ofType(CREATE_FREE_CLASS_ORDER),
    switchMap((action) => {
      return ajax({
        url: API_URL + '/order/enroll',
        method: 'post',
        withCredentials: true,
        body: action.payload,
        headers: {
          'content-type': 'application/json;charset=UTF-8',
        },
      }).pipe(
        map((payload: AjaxResponse): CreateFreeClassOrderSuccessAction => {
          return {
            type: CREATE_FREE_CLASS_ORDER_SUCCESS,
            payload: payload.response as CreateFreeClassOrderResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: CREATE_FREE_CLASS_ORDER_NETWORK_ERROR,
            });
          }
          return of({
            type: CREATE_FREE_CLASS_ORDER_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

export const orderEpics: Epic = combineEpics(...[createOrderEpic, createFreeClassOrderEpic]);
