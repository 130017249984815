import { of } from 'rxjs';
import { ajax, AjaxError, AjaxResponse } from 'rxjs/ajax';
import { catchError, map, switchMap } from 'rxjs/operators';
import { combineEpics, ofType, ActionsObservable, Epic } from 'redux-observable';

import { API_URL } from 'src/constants/api';
import * as CareerConstants from './constants';
import * as CareerModels from './models';

function submitTutorEpic(action$: ActionsObservable<CareerModels.SubmitTutorAction>) {
  return action$.pipe(
    ofType(CareerConstants.SUBMIT),
    switchMap((action) => {
      let formData = new FormData();
      const payload = action.payload;

      // Append payload object to form data
      for (const [key, value] of Object.entries(payload)) {
        formData.append(key, value);
      }

      return ajax({
        url: API_URL + '/site/career',
        method: 'post',
        body: formData,
      }).pipe(
        map((payload: AjaxResponse): CareerModels.SubmitTutorSuccessAction => {
          return {
            type: CareerConstants.SUBMIT_SUCCESS,
            payload: payload.response as CareerModels.SubmitTutorResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: CareerConstants.SUBMIT_NETWORK_ERROR,
            });
          }
          return of({
            type: CareerConstants.SUBMIT_ERROR,
            payload: error.response as CareerModels.SubmitTutorErrorResponse,
          });
        }),
      );
    }),
  );
}

export const careerEpics: Epic = combineEpics(...[submitTutorEpic]);
