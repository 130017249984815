import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Flex,
  Text,
  Stack,
  Spacer,
  Button,
  HStack,
  Select,
  useToast,
  Link,
} from '@chakra-ui/react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import queryString from 'query-string';

import { month } from 'src/constants/months';
import { formatPrice } from 'src/utils/formatPrice';
import {
  getCart,
  getCutOffDate,
  getPeopleAlsoBuy,
  resetPeopleAlsoBuy,
  resetPromoCode,
} from 'src/redux/cart/actions';
import {
  selectCartCourse,
  selectCartQuestionBank,
  selectCartResource,
  selectCurrentDate,
  selectCutOffDate,
  selectCutOffDateStatusCode,
  selectPeopleAlsoBuyItem,
  selectPromoCode,
  selectPromoCodeErrorMessage,
} from 'src/redux/cart/selectors';
import { getCoursePriceStructure } from 'src/utils/getCoursePriceStructure';
import { QuestionBank } from 'src/models/cart/QuestionBank';
import { Resource } from 'src/models/cart/Resource';
import NavigateTopbar from 'src/components/Topbar/NavigateTopbar';
import Loader from 'src/components/Loader';
import PeopleAlsoBuyCard from 'src/components/Cart/PeopleAlsoBuyCard';
import CourseItem from './CourseItem';
import QuestionBankItem from './QuestionBankItem';
import ResourceItem from './ResourceItem';
import useSessionExpired from 'src/components/SessionExpired';
import PromoCode from 'src/components/Cart/PromoCode';
import { Course } from 'src/models/cart/Course';
import { eachMonthOfInterval, format } from 'date-fns';

const monthsValue = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

function Prepaid(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const toast = useToast();

  const [duration, setDuration] = useState(0);
  const [startMonth, setStartMonth] = useState(0);

  const [validCourses, setValidCourses] = useState<Course[] | undefined>();

  const [startMonthOption, setStartMonthOption] = useState<number[]>([]);
  const [durationOption, setDurationOption] = useState<number[]>([]);

  const [courseTotal, setCourseTotal] = useState(0);
  const [othersTotal, setOthersTotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [grandTotalWithPromo, setGrandTotalWithPromo] = useState(0);

  const [courseNoSaving, setCourseNoSaving] = useState(0);
  const [othersNoSaving, setOthersNoSaving] = useState(0);
  const [savingsTotal, setSavingsTotal] = useState(0);

  const [canCheckout, setCanCheckout] = useState(false);
  const [promoCode, setPromoCode] = useState('');

  const courses = useSelector(selectCartCourse);
  const questionBank = useSelector(selectCartQuestionBank);
  const resources = useSelector(selectCartResource);
  const cutOffDate = useSelector(selectCutOffDate);

  const cutOffDateStatusCode = useSelector(selectCutOffDateStatusCode);
  useSessionExpired(cutOffDateStatusCode);

  const currentDate = useSelector(selectCurrentDate);

  const peopleAlsoBuy = useSelector(selectPeopleAlsoBuyItem);

  const promoCodeData = useSelector(selectPromoCode);
  const promoCodeErrorMessage = useSelector(selectPromoCodeErrorMessage);

  const hasPromoCode = promoCodeData && Boolean(promoCodeData);

  const validPromoCode = hasPromoCode && Boolean(!promoCodeErrorMessage);

  const hasCourseItem = courses && Boolean(courses.length);
  const hasQuestionBankItem = questionBank && Boolean(questionBank.length);
  const hasResourcesItem = resources && Boolean(resources.length);

  const hasOthersItem = Boolean(hasQuestionBankItem || hasResourcesItem);
  const hasPeopleAlsoBuy = peopleAlsoBuy && Boolean(peopleAlsoBuy.length);

  const [date, setDate] = useState(new Date());
  let currentYear = date.getFullYear();
  let currentMonth = date.getMonth();
  let currentDay = date.getDate();

  const { search } = useLocation();
  const { payment } = queryString.parse(search);

  const resetPromoCodeMaster = () => {
    setPromoCode('');
    dispatch(resetPromoCode());
  };

  // Show payment decline error if applicable
  useEffect(() => {
    if (payment === '0') {
      toast({
        title: 'Payment Declined!',
        position: 'top',
        status: 'error',
        isClosable: true,
      });
    }
  }, []);

  useEffect(() => {
    dispatch(getCutOffDate());
  }, [dispatch]);

  // Use the date from backend
  useEffect(() => {
    if (currentDate) {
      const newDate = new Date(currentDate);
      setDate(newDate);
      currentYear = newDate.getFullYear();
      currentMonth = newDate.getMonth();
      currentDay = newDate.getDate();
    }
  }, [currentDate]);

  // Initialize starting months option
  useEffect(() => {
    if (cutOffDate && courses) {
      const beforeCutOffDate = currentDay <= cutOffDate;
      const startMonthOption = monthsValue.filter((m) => {
        const month = m - 1;
        const isCurrentMonth = month === currentMonth;

        if (month < currentMonth) {
          return false;
        }

        for (let i = 0; i < courses.length; i++) {
          const startDate = new Date(courses[i].startDate);
          const endDate = new Date(courses[i].endDate);
          const monthInterval: number[] = [];

          eachMonthOfInterval({ start: startDate, end: endDate }).forEach((item) => {
            const d = new Date(item);
            if (d.getFullYear() === currentYear) {
              monthInterval.push(d.getMonth());
            }
          });

          if (monthInterval.includes(month)) {
            if (isCurrentMonth) {
              if (beforeCutOffDate) {
                return true;
              } else {
                return false;
              }
            } else {
              return true;
            }
          }
        }
        return false;
      });
      setStartMonthOption(startMonthOption);
    }
  }, [cutOffDate, courses]);

  // Set max duration
  useEffect(() => {
    if (startMonth && courses) {
      const durationOption: number[] = [];

      for (let duration = 1; duration <= 12 - (startMonth - 1); duration++) {
        for (let i = 0; i < courses.length; i++) {
          const canPurchase = checkCourseCanPurchase(courses[i], duration);
          if (canPurchase && !durationOption.includes(duration)) {
            durationOption.push(duration);
            break;
          }
        }
      }

      setDurationOption(durationOption);
    }
    // If startMonth not selected
    else {
      setDurationOption([]);
      resetPromoCodeMaster();
    }
  }, [startMonth, cutOffDate, courses]);

  // Check the current duration/month is in the duration/month options
  useEffect(() => {
    if (!startMonthOption.includes(startMonth)) {
      setStartMonth(0);
    }

    if (!durationOption.includes(duration)) {
      setDuration(0);
    }
  }, [duration, durationOption, startMonth, startMonthOption]);

  // Important note: This useEffect hook codes below required a bit changes from desktop repo
  // codes as in the desktop repo non null assertion been turned off in eslintrc.js but not in
  // this repo. Here, we are checking and waiting for the course, resources, question bank
  // to be ready as it initial state is null but once it is ready either empty array or array
  // with the data will be returned
  useEffect(() => {
    let isCoursesResourcesQBanksReady = false;

    // Non null assertion checker for courses, resources, question bank
    if (courses !== null || resources !== null || questionBank !== null) {
      isCoursesResourcesQBanksReady = true;
    } else {
      isCoursesResourcesQBanksReady = false;
    }

    if (isCoursesResourcesQBanksReady) {
      // Suppress non-null assertion in variable instead of turn it off in tsconfig.json
      if (resources!.length > 0 || questionBank!.length > 0) {
        setCanCheckout(true);
        return;
      } else if (courses!.length > 0 && duration !== 0 && startMonth !== 0) {
        // If at least 1 course is allowed to purchase, enable the checkout button
        for (let i = 0; i < courses!.length; i++) {
          const canPurchase = checkCourseCanPurchase(courses![i]);
          if (canPurchase) {
            setCanCheckout(true);
            break;
          } else {
            setCanCheckout(false);
          }
        }
        return;
      }
    }

    resetPromoCodeMaster();
    setCanCheckout(false);
  }, [courses, questionBank, resources, duration, startMonth]);

  // Fetch people also buy list
  useEffect(() => {
    if (hasCourseItem) {
      const forms: string[] = [];
      const courseIds: number[] = [];

      courses.forEach((item) => {
        forms.push(item.form.name);
        courseIds.push(item.id);
      });

      dispatch(
        getPeopleAlsoBuy({
          forms,
          courseIds,
        }),
      );
    }

    // No course item
    else {
      dispatch(resetPeopleAlsoBuy());
    }
  }, [dispatch, courses]);

  // Calculate course subtotal
  useEffect(() => {
    const validCourses = courses?.filter((course) => checkCourseCanPurchase(course));
    setValidCourses(validCourses);
    if (
      hasCourseItem &&
      duration !== 0 &&
      startMonth !== 0 &&
      validCourses &&
      validCourses.length > 0
    ) {
      const coursesPriceWithDiscout = validCourses
        .map((course) => {
          const { hasDiscount, discountPrice, originalPrice } = getCoursePriceStructure(course);
          return hasDiscount
            ? discountPrice * checkCourseAvailableDuration(course)
            : originalPrice * checkCourseAvailableDuration(course);
        })
        .reduce((accumulator, price) => accumulator + price);

      const coursesPriceNoDiscout = validCourses
        .map((course) => {
          const { originalPrice } = getCoursePriceStructure(course);
          return originalPrice * checkCourseAvailableDuration(course);
        })
        .reduce((accumulator, price) => accumulator + price);

      setCourseTotal(coursesPriceWithDiscout);
      setCourseNoSaving(coursesPriceNoDiscout);
    } else {
      setCourseTotal(0);
      setCourseNoSaving(0);
    }
  }, [courses, duration]);

  // Calculate others subtotal
  useEffect(() => {
    const hasQuestionBank = questionBank && Boolean(questionBank.length);
    const hasResources = resources && Boolean(resources.length);

    const otherItems: Array<QuestionBank | Resource> = [];

    if (hasQuestionBank) {
      otherItems.push(...questionBank);
    }

    if (hasResources) {
      otherItems.push(...resources);
    }

    if (otherItems && Boolean(otherItems.length)) {
      const othersPriceWithDiscount = otherItems
        .map(({ discountPrice, price }) => (discountPrice ? discountPrice : price))
        .reduce((accumulator, price) => accumulator + price);

      const othersPriceNoDiscount = otherItems
        .map(({ price }) => price)
        .reduce((accumulator, price) => accumulator + price);

      setOthersTotal(othersPriceWithDiscount);
      setOthersNoSaving(othersPriceNoDiscount);
    } else {
      setOthersTotal(0);
      setOthersNoSaving(0);
    }
  }, [questionBank, resources]);

  // Calculate grand total
  useEffect(() => {
    const grandTotal = courseTotal + othersTotal;
    const grandTotalNoSavings = courseNoSaving + othersNoSaving;

    if (hasPromoCode) {
      const { type, value } = promoCodeData;
      const discountNumber = Number(value);

      let grandTotalWithPromoCode = 0;

      if (type.id === 1) {
        grandTotalWithPromoCode = grandTotal - discountNumber;
      }

      if (type.id === 2) {
        grandTotalWithPromoCode = grandTotal * ((100 - discountNumber) / 100);
      }

      setGrandTotalWithPromo(grandTotalWithPromoCode);
    }

    setSavingsTotal(grandTotalNoSavings - grandTotal);
    setGrandTotal(grandTotal);
  }, [courseTotal, othersTotal, promoCodeData]);

  // Get latest bundle discount
  useEffect(() => {
    if (startMonth && duration) {
      const finalMonth = startMonth - 2 + duration;
      const endDate = new Date(currentYear, finalMonth, 1);
      dispatch(getCart(format(endDate, 'Y-M-d')));
    }
  }, [startMonth, duration, dispatch]);

  if (!questionBank || !courses || !resources) {
    return (
      <Box>
        <NavigateTopbar
          currentTab={t('pay_as_you_go')}
          firstTitle={t('subscription')}
          firstTo="/subscription"
          secondTitle={t('pay_as_you_go')}
          secondTo="/prepaid"
          isWithSearchbar={false}
        />
        <Box
          flex="1"
          px="10"
          py="5"
          as={OverlayScrollbarsComponent}
          options={{ scrollbars: { autoHide: 'scroll' } }}
          height="100vh">
          <Text fontSize={18} fontWeight="medium" mb={5}>
            {t('please_select_month_for_class')}
          </Text>
          <HStack mt="4" spacing="4">
            <Select
              w="fit-content"
              placeholder={t('starting_month')}
              borderColor="black"
              _hover={{ borderColor: 'black' }}
              onChange={(e) => setStartMonth(Number(e.target.value))}
              disabled={!hasCourseItem}>
              {startMonthOption.map((m) => (
                <option key={m} value={m}>
                  {t(month[m - 1].toLowerCase())}
                </option>
              ))}
            </Select>
            <Select
              w="fit-content"
              placeholder={t('duration')}
              borderColor="black"
              _hover={{ borderColor: 'black' }}
              value={duration}
              onChange={(e) => setDuration(Number(e.target.value))}
              disabled={!hasCourseItem}>
              {durationOption.map((d) => (
                <option key={d} value={d}>
                  {t('month', { count: d })}
                </option>
              ))}
            </Select>
          </HStack>
          <Loader />
        </Box>
      </Box>
    );
  }

  const onCheckout = () => {
    const courseDuration = () => {
      const duration: number[] = [];
      validCourses?.forEach((course) => {
        duration.push(checkCourseAvailableDuration(course));
      });
      return duration;
    };

    history.push({
      pathname: '/checkout/billing-info',
      state: {
        from: 'prepaid',
        duration: courseDuration(),
        startMonth,
        promoCode: hasPromoCode ? promoCode : null,
        grandTotalWithPromo,
        totalAmount: grandTotal,
        courses: validCourses,
        resources,
        questionBank,
      },
    });
  };

  const checkCourseCanPurchase = (course: Course, d = duration) => {
    if (d === 0 || startMonth === 0) {
      return false;
    }

    const finalMonth = startMonth - 1 + (d - 1);
    const endDate = new Date(course.endDate);
    const startDate = new Date(course.startDate);

    if (currentYear < startDate.getFullYear()) {
      return false;
    } else if (endDate.getFullYear() < currentYear) {
      return false;
    } else if (currentYear === endDate.getFullYear() && finalMonth > endDate.getMonth()) {
      return false;
    } else if (currentYear === endDate.getFullYear() && finalMonth < startDate.getMonth()) {
      return false;
    } else {
      return true;
    }
  };

  const checkCourseAvailableDuration = (course: Course) => {
    let totalDuration = 0;
    for (let d = 1; d <= duration; d++) {
      const finalMonth = startMonth - 1 + (d - 1);
      const endDate = new Date(course.endDate);
      const startDate = new Date(course.startDate);

      if (
        !(currentYear === endDate.getFullYear() && finalMonth > endDate.getMonth()) &&
        !(currentYear === endDate.getFullYear() && finalMonth < startDate.getMonth())
      ) {
        totalDuration = totalDuration + 1;
      } else {
        totalDuration = 0;
      }
    }
    return totalDuration;
  };

  return (
    <Box>
      <NavigateTopbar
        currentTab={t('pay_as_you_go')}
        firstTitle={t('subscription')}
        firstTo="/subscription"
        secondTitle={t('pay_as_you_go')}
        secondTo="/prepaid"
        isWithSearchbar={false}
      />
      <Box
        flex="1"
        px="10"
        py="5"
        as={OverlayScrollbarsComponent}
        options={{ scrollbars: { autoHide: 'scroll' } }}
        height="100vh">
        <Text fontSize={18} fontWeight="medium" mb={5}>
          {t('please_select_month_for_class')}
        </Text>
        <HStack mt="4" spacing="4">
          <Select
            w="fit-content"
            placeholder={t('starting_month')}
            borderColor="black"
            _hover={{ borderColor: 'black' }}
            onChange={(e) => setStartMonth(Number(e.target.value))}
            disabled={!hasCourseItem}>
            {startMonthOption.map((m) => (
              <option key={m} value={m}>
                {t(month[m - 1].toLowerCase())}
              </option>
            ))}
          </Select>
          <Select
            w="fit-content"
            placeholder={t('duration')}
            borderColor="black"
            _hover={{ borderColor: 'black' }}
            value={duration}
            onChange={(e) => setDuration(Number(e.target.value))}
            disabled={!hasCourseItem}>
            {durationOption.map((d) => (
              <option key={d} value={d}>
                {t('month', { count: d })}
              </option>
            ))}
          </Select>
        </HStack>
        {hasCourseItem && (
          <Box overflow="hidden" w="100%" mt="8">
            {courses.map((course) => {
              const canPurchase = checkCourseCanPurchase(course);
              return (
                <CourseItem
                  key={course.cartId}
                  course={course}
                  duration={checkCourseAvailableDuration(course)}
                  canPurchase={canPurchase}
                  resetPromoCode={resetPromoCodeMaster}
                />
              );
            })}
            <Flex w="100%" justifyContent="flex-end" pr={6} mt={5}>
              <Text fontSize={20} fontWeight="semibold" borderBottom="1px solid #707070">
                {t('subtotal')}: {formatPrice(String(courseTotal))}
              </Text>
            </Flex>
          </Box>
        )}
        {hasOthersItem && (
          <Box mt={5}>
            <Text fontSize={20} fontWeight="semibold">
              {t('others')}
            </Text>
            {questionBank.map((questionBank) => (
              <QuestionBankItem
                key={questionBank.id}
                questionBank={questionBank}
                resetPromoCode={resetPromoCodeMaster}
              />
            ))}
            {resources?.map((resource) => (
              <ResourceItem
                key={resource.cartId}
                resource={resource}
                resetPromoCode={resetPromoCodeMaster}
              />
            ))}
          </Box>
        )}
        <Flex
          mt="8"
          flexDirection={{
            base: 'column',
            md: 'row',
          }}>
          {hasPeopleAlsoBuy && (
            <Box minW="50%" maxW="55%">
              <Text fontSize={20} fontWeight="bold" mb={5}>
                {t('people_also_buy')}
              </Text>
              {peopleAlsoBuy.map((item) => (
                <PeopleAlsoBuyCard key={item.id} {...item} />
              ))}
            </Box>
          )}
          <Spacer />
          <Stack
            spacing={3}
            direction="column"
            pr={6}
            w="100%"
            alignItems={{
              base: 'flex-start',
              md: 'flex-end',
            }}>
            {hasOthersItem && (
              <Flex>
                <Text fontSize={20} fontWeight="semibold" borderBottom="1px solid #707070">
                  {t('subtotal')}: {formatPrice(String(othersTotal))}
                </Text>
              </Flex>
            )}
            <Flex>
              <Text fontSize={20} fontWeight="semibold">
                {t('total_savings')}: {formatPrice(String(savingsTotal))}
              </Text>
            </Flex>
            {/* Promo */}
            <PromoCode
              canCheckout={canCheckout}
              promoCode={promoCode}
              setPromoCode={setPromoCode}
              resetPromoCode={resetPromoCodeMaster}
            />
            {/* End of Promo */}
            <Flex fontSize={20} fontWeight="bold">
              {validPromoCode ? (
                <HStack spacing="2">
                  <Text>{t('grand_total')}:</Text>
                  <Text textDecoration="line-through">{formatPrice(String(grandTotal))}</Text>
                  <Text color="red.500">{formatPrice(String(grandTotalWithPromo))}</Text>
                </HStack>
              ) : (
                <Text>
                  {t('grand_total')}: {formatPrice(String(grandTotal))}
                </Text>
              )}
            </Flex>
            <Flex direction="column">
              <Button
                bg="#02F950"
                color="white"
                fontSize={20}
                fontWeight="semibold"
                onClick={onCheckout}
                _hover={{ backgroundColor: '#00C414' }}
                _active={{ backgroundColor: '#00C414' }}
                disabled={!canCheckout}>
                {t('checkout_now')}
              </Button>
              <Flex justifyContent="center">
                <Link
                  mt={1}
                  fontSize={14}
                  color="#1900FF"
                  href="/privacy-policy"
                  cursor="pointer"
                  _hover={{ textDecoration: 'underline' }}>
                  {t('payment_policy')}
                </Link>
              </Flex>
            </Flex>
          </Stack>
        </Flex>
      </Box>
    </Box>
  );
}

export default Prepaid;
