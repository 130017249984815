import { applyMiddleware, createStore } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createFilter from 'redux-persist-transform-filter';

import rootEpic from './rootEpic';
import rootReducer from './rootReducer';

const epicMiddleware = createEpicMiddleware();
const middlewares = [epicMiddleware];

const authFilter = createFilter('auth', [
  'loginStatus',
  'rememberMe',
  'moodleSession',
  'loginRole',
  'loginMethod',
  'freeTrial',
  'freeTrialExpiry',
  'registerDate',
  'freeTrialAccessStartDate',
]);

export const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'language'],
  transforms: [authFilter],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(...middlewares)),
);

epicMiddleware.run(rootEpic);

export const persistor = persistStore(store);
