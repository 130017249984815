import Clock from 'src/images/careerImage/img_clock.png';
import Institution from 'src/images/careerImage/img_institution.png';
import Briefcase from 'src/images/careerImage/img_briefcase.png';
import Pen from 'src/images/careerImage/img_pen.png';

// Section 1
export const Section1 = {
  title: 'Become an online tutor with Tavis?',
  paragraph: 'We provide support to manage your lesson through our platform.',
  btnTitle: 'Apply Now',
}

// Section 2
export const Section2 = {
  title: 'The benefits of tutoring with us',
  items: [
    {
      paragraph: 'Run classes anytime you want.',
      icon: Clock,
    },
    {
      paragraph: 'We take care of all administration.',
      icon: Institution,
    },
    {
      paragraph:
        'Working independently and free of instructions.',
      icon: Briefcase,
    },
    {
      paragraph: 'Time-saving support for your content preparation process.',
      icon: Pen,
    },
  ],
}

// Section 3
export const Section3 = {
  title: 'For other positions',
  paragraph: 'We are always on the lookout for talent who are passionate in education, so tell us more about yourself at careers@tavis.live on how you can make an impact at Tavis. We are open to Full time, Part-Time and Internship positions.',
}