import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function InfoIcon(props: IconProps): JSX.Element {
  return (
    <Icon viewBox="0 0 34 34" {...props}>
      <defs>
        <filter id="Ellipse_118" x="0" y="0" width="34" height="34" filterUnits="userSpaceOnUse">
          <feOffset />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="Information" transform="translate(-233 9)">
        <g transform="matrix(1, 0, 0, 1, 233, -9)" filter="url(#Ellipse_118)">
          <circle
            id="Ellipse_118-2"
            data-name="Ellipse 118"
            cx="8"
            cy="8"
            r="8"
            transform="translate(9 9)"
            fill="currentColor"
          />
        </g>
        <text
          id="i"
          transform="translate(250 13)"
          fill="#fff"
          fontSize="12"
          fontFamily="Poppins-Bold, Poppins"
          fontWeight="700">
          <tspan x="-1.77" y="0">
            i
          </tspan>
        </text>
      </g>
    </Icon>
  );
}
