import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function HideIcon(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <path
        d="M12.0028682,4.41784718 C15.0044079,4.40812158 17.4465802,6.88186032 17.4604905,9.94601227 C17.4606001,10.639845 17.3276945,11.3269928 17.0692672,11.9687117 L20.2564176,15.1942554 C21.9168973,13.7937252 23.2044796,11.9882212 24,9.94484105 C21.4154303,3.35920641 14.1924922,0.0386835302 7.64893159,2.427942 L10.0054496,4.81723369 C10.6403835,4.5558679 11.3182637,4.42032487 12.0028682,4.41784718 Z M1.08991826,1.4054657 L3.58296286,3.92593419 L4.08661982,4.43424428 C2.26242609,5.86163988 0.850363472,7.76613844 0,9.94601227 C2.65591056,16.6964466 10.1566597,19.986131 16.7812993,17.3059677 L17.2402122,17.7744562 L20.4376882,21 L21.8247526,19.5945343 L2.47468808,0 L1.08991826,1.4054657 Z M7.1211817,7.51338539 L8.8134232,9.22453988 C8.75776338,9.45928267 8.72888942,9.69981042 8.72737703,9.94132738 C8.73010724,10.8241116 9.07671828,11.6695267 9.69076261,12.2911104 C10.3048069,12.912694 11.1358407,13.2593831 12.0005736,13.2547128 C12.2386841,13.2534359 12.4758453,13.2239582 12.7072996,13.1668712 L14.3983938,14.874512 C12.7176769,15.732811 10.7216817,15.6427171 9.12172597,14.6363372 C7.5217702,13.6299573 6.54543408,11.8504406 6.54065682,9.93195761 C6.5498583,9.09070086 6.74931787,8.26298761 7.12347627,7.51338539 L7.1211817,7.51338539 Z M11.8250394,6.65488009 L15.2668866,10.1380926 L15.2886849,9.96358059 C15.2859547,9.08079634 14.9393437,8.23538124 14.3252993,7.61379759 C13.711255,6.99221394 12.8802212,6.6455249 12.0154883,6.6501952 L11.8250394,6.65488009 Z"
        id="Icon_ionic-md-eye-off"
        fill="currentColor"
        fillRule="nonzero"
      ></path>
    </Icon>
  );
}
