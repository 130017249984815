import { FilterState } from './models';
import { GET_FILTER_LIST, GET_FILTER_LIST_SUCCESS } from './constants';
import { FilterActionType } from './models';
import { Subject } from 'src/models/Subject';

export const filterInitialState: FilterState = {
  // Subject list
  filterList: {
    forms: null,
    subjects: null,
    languages: null,
  },
  filterListLoading: false,
  filterListErrors: false,
};

export const filterReducer = (
  state = filterInitialState,
  action: FilterActionType,
): FilterState => {
  switch (action.type) {
    case GET_FILTER_LIST:
      return {
        ...state,
        filterListLoading: true,
      };
    case GET_FILTER_LIST_SUCCESS: {
      const formatSubject = () => {
        if (!action.payload.data.objects.subjects) return null;
        return action.payload.data.objects.subjects.map(
          ({ id, subject_name }): Subject => ({
            id: id,
            name: subject_name,
          }),
        );
      };

      const newFilterList = { ...action.payload.data.objects, subjects: formatSubject() };

      return {
        ...state,
        filterListLoading: false,
        filterList: newFilterList,
      };
    }
    default:
      return state;
  }
};
