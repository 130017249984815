import React from 'react';
import { Flex, Spinner } from '@chakra-ui/react';

import DashboardShell from 'src/components/DashboardShell';

function Loader(): JSX.Element {
  return (
    <DashboardShell>
      <Flex h="100vh" justifyContent="center" alignItems="center">
        <Spinner />
      </Flex>
    </DashboardShell>
  );
}

export default Loader;
