export const progress = {
  progressRed: {
    50: '#FFE5E7',
    100: '#FFB8BA',
    200: '#FF8A8E',
    300: '#FF5C62',
    400: '#FF2E36',
    500: '#FF000A',
    600: '#CC0008',
    700: '#990006',
    800: '#660004',
    900: '#330002',
  },
  progressYellow: {
    50: '#FFFCE5',
    100: '#FFF6B8',
    200: '#FFF08A',
    300: '#FFEB5C',
    400: '#FFE52E',
    500: '#FFDF00',
    600: '#CCB300',
    700: '#998600',
    800: '#665900',
    900: '#332D00',
  },
  progressBlue: {
    50: '#E5F0FF',
    100: '#B8D4FF',
    200: '#8AB9FF',
    300: '#5C9DFF',
    400: '#2E82FF',
    500: '#0067FF',
    600: '#0052CC',
    700: '#003E99',
    800: '#002966',
    900: '#001533',
  },
  progressGreen: {
    50: '#ECF9EF',
    100: '#C9EED1',
    200: '#A6E2B4',
    300: '#84D797',
    400: '#61CC7A',
    500: '#3FC15C',
    600: '#329A4A',
    700: '#267337',
    800: '#194D25',
    900: '#0C2712',
  },
};
