import { of } from 'rxjs';
import { ajax, AjaxError, AjaxResponse } from 'rxjs/ajax';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { combineEpics, ofType, ActionsObservable, Epic } from 'redux-observable';

import { API_URL } from 'src/constants/api';
import {
  ADD_TO_CART,
  ADD_TO_CART_SUCCESS,
  GET_CART,
  GET_CART_SUCCESS,
  GET_PEOPLE_ALSO_BUY,
  GET_PEOPLE_ALSO_BUY_SUCCESS,
  DELETE_CART_ITEM,
  DELETE_CART_ITEM_SUCCESS,
  ADD_TO_CART_NETWORK_ERROR,
  ADD_TO_CART_ERROR,
  GET_CART_NETWORK_ERROR,
  GET_CART_ERROR,
  GET_CUT_OFF_DATE,
  GET_CUT_OFF_DATE_SUCCESS,
  GET_CUT_OFF_DATE_NETWORK_ERROR,
  GET_CUT_OFF_DATE_ERROR,
  APPLY_PROMO_CODE,
  APPLY_PROMO_CODE_SUCCESS,
  APPLY_PROMO_CODE_ERROR,
} from './constants';
import {
  AddToCartAction,
  AddToCartSuccessAction,
  GetCartAction,
  GetCartSuccessAction,
  GetPeopleAlsoBuyAction,
  GetPeopleAlsoBuySuccessAction,
  DeleteCartItemAction,
  DeleteCartItemSuccessAction,
  AddToCartSuccessResponse,
  CartResponse,
  PeopleAlsoBuyResponse,
  DeleteCartItemResponse,
  GetCutOffDateAction,
  GetCutOffDateSuccessAction,
  CutOffDateResponse,
} from './models';
import { LOGOUT } from '../auth/constants';
import { ErrorResponse } from 'src/models/ErrorResponse';
import { ApplyPromoCodeAction, ApplyPromoCodeSuccessAction } from './models/actions';
import { ApplyPromoCodeErrorResponse, ApplyPromoCodeSuccessResponse } from './models/responses';

function addToCartEpic(action$: ActionsObservable<AddToCartAction>) {
  return action$.pipe(
    ofType(ADD_TO_CART),
    switchMap((action) => {
      return ajax({
        url: API_URL + '/cart',
        method: 'post',
        withCredentials: true,
        body: {
          cartType: action.payload.cartType,
          instanceId: action.payload.instanceId,
        },
        headers: {
          'content-type': 'application/json;charset=UTF-8',
        },
      }).pipe(
        map((payload: AjaxResponse): AddToCartSuccessAction => {
          return {
            type: ADD_TO_CART_SUCCESS,
            payload: payload.response as AddToCartSuccessResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: ADD_TO_CART_NETWORK_ERROR,
            });
          }
          return of({
            type: ADD_TO_CART_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function getCartEpic(action$: ActionsObservable<GetCartAction>) {
  return action$.pipe(
    ofType(GET_CART),
    switchMap((action) => {
      return ajax({
        url: action.date ? API_URL + '/cart?date=' + action.date : API_URL + '/cart',
        method: 'get',
        withCredentials: true,
      }).pipe(
        map((payload: AjaxResponse): GetCartSuccessAction => {
          return {
            type: GET_CART_SUCCESS,
            payload: payload.response as CartResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: GET_CART_NETWORK_ERROR,
            });
          }
          return of({
            type: GET_CART_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function getPeopleAlsoBuyEpic(action$: ActionsObservable<GetPeopleAlsoBuyAction>) {
  return action$.pipe(
    ofType(GET_PEOPLE_ALSO_BUY),
    switchMap((action) => {
      return ajax({
        url: API_URL + '/cart/people-also-buy',
        method: 'post',
        withCredentials: true,
        headers: {
          'content-type': 'application/json;charset=UTF-8',
        },
        body: {
          forms: action.payload.forms,
          courseIds: action.payload.courseIds,
        },
      }).pipe(
        map((payload: AjaxResponse): GetPeopleAlsoBuySuccessAction => {
          return {
            type: GET_PEOPLE_ALSO_BUY_SUCCESS,
            payload: payload.response as PeopleAlsoBuyResponse,
          };
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function deleteCartItemEpic(action$: ActionsObservable<DeleteCartItemAction>) {
  return action$.pipe(
    ofType(DELETE_CART_ITEM),
    switchMap((action) => {
      return ajax({
        url: `${API_URL}/cart/${action.id}`,
        method: 'delete',
        withCredentials: true,
      }).pipe(
        map((payload: AjaxResponse): DeleteCartItemSuccessAction => {
          return {
            type: DELETE_CART_ITEM_SUCCESS,
            payload: payload.response as DeleteCartItemResponse,
          };
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function getCutOffDateEpic(action$: ActionsObservable<GetCutOffDateAction>) {
  return action$.pipe(
    ofType(GET_CUT_OFF_DATE),
    switchMap(() => {
      return ajax({
        url: API_URL + '/setting',
        method: 'get',
        withCredentials: true,
      }).pipe(
        map((payload: AjaxResponse): GetCutOffDateSuccessAction => {
          return {
            type: GET_CUT_OFF_DATE_SUCCESS,
            payload: payload.response as CutOffDateResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: GET_CUT_OFF_DATE_NETWORK_ERROR,
            });
          }
          return of({
            type: GET_CUT_OFF_DATE_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function applyPromoCodeEpic(action$: ActionsObservable<ApplyPromoCodeAction>) {
  return action$.pipe(
    ofType(APPLY_PROMO_CODE),
    switchMap((action) => {
      return ajax({
        url: `${API_URL}/order/applyPromo?code=${action.code}`,
        method: 'get',
        withCredentials: true,
      }).pipe(
        map((payload: AjaxResponse): ApplyPromoCodeSuccessAction => {
          if (payload.status === 204) {
            return {
              type: APPLY_PROMO_CODE_SUCCESS,
              payload: null,
            };
          }
          return {
            type: APPLY_PROMO_CODE_SUCCESS,
            payload: payload.response as ApplyPromoCodeSuccessResponse,
          };
        }),
        catchError((error: AjaxError) => {
          return of({
            type: APPLY_PROMO_CODE_ERROR,
            payload: error.response as ApplyPromoCodeErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

const cartEpicsArray = [
  addToCartEpic,
  getCartEpic,
  getPeopleAlsoBuyEpic,
  deleteCartItemEpic,
  applyPromoCodeEpic,
  getCutOffDateEpic,
];

export const cartEpics: Epic = combineEpics(...cartEpicsArray);
