import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { compareAsc, format, parseISO } from 'date-fns';
import {
  AspectRatio,
  Box,
  Flex,
  Heading,
  Text,
  Tooltip,
  VStack,
  Image,
  Stack,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Divider,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {
  CalendarIcon,
  CourseIcon,
  InfoIcon,
  LanguageIcon,
  StudentsIcon,
  TimeIcon,
} from 'src/icons';

import { addToCart, getCutOffDate } from 'src/redux/cart/actions';
import {
  selectAddToCartStatusCode,
  selectAddToCartErrorMessage,
  selectAddToCartNetworkError,
  selectAddToCartLoading,
  selectCurrentDate,
  selectCutOffDate,
  selectCutOffDateStatusCode,
} from 'src/redux/cart/selectors';
import { formatPrice } from 'src/utils/formatPrice';
import { getCourseDetails } from 'src/redux/course/actions';
import {
  selectCourseDetails,
  selectCourseDetailsNetworkError,
  selectCourseDetailsStatusCode,
} from 'src/redux/course/selectors';
import BackTopbar from 'src/components/Topbar/BackTopbar';
import CourseLoader from '../Courses/CourseLoader';
import StudyTrack from './StudyTrack';
import TavisPlaceholder from 'src/images/TavisPlaceholder.svg';
import useSessionExpired from 'src/components/SessionExpired';
import { selectFreeTrialAccessDate, selectLoginStatus, selectRegisterDate } from 'src/redux/auth/selectors';
import useAddToCart from 'src/components/AddToCart';
import NetworkErrorComponent from 'src/components/NetworkError';
import { createFreeClassOrder, createOrder } from '../../redux/order/actions';
import {
  selectFreeClassOrderLoading,
  selectFreeClassOrderNetworkError,
  selectFreeClassOrderStatusCode,
  selectNewOrder,
  selectNewOrderLoading,
} from '../../redux/order/selectors';
import { selectProfile } from 'src/redux/profile/selectors';
import { selectFreeTrialExpiry } from 'src/redux/auth/selectors';
import { CreateOrderPayload, PaymentDetails } from 'src/redux/order/models/payload';
import { IS_DEV } from 'src/constants/environment';
import { getFreeTrial } from 'src/utils/getFreeTrial';

function CourseDetails(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const loginStatus = useSelector(selectLoginStatus);
  const params = useParams<{ id?: string }>();
  const toast = useToast();

  const [checkoutNow, setCheckoutNow] = useState(false);
  const [checkoutTo, setCheckoutTo] = useState('');
  const [showSubscribeInfo, setShowSubscribeInfo] = useState(false);
  const [showAddToCartInfo, setShowAddToCartInfo] = useState(false);
  const [freeTrial, setFreeTrial] = useState(false);

  const statusCode = useSelector(selectCourseDetailsStatusCode);
  const networkError = useSelector(selectCourseDetailsNetworkError);

  const {
    isOpen: isOpenBuyAddToCart,
    onOpen: onOpenBuyAddToCart,
    onClose: onClosenBuyAddToCart,
  } = useDisclosure();
  const {
    isOpen: isOpenSubscribe,
    onOpen: onOpenSubscribe,
    onClose: onCloseSubscribe,
  } = useDisclosure();

  const courseArray = useSelector(selectCourseDetails);
  const courseId = Number(params.id);
  const addToCartStatusCode = useSelector(selectAddToCartStatusCode);
  const addToCartErrorMessage = useSelector(selectAddToCartErrorMessage);
  const addToCartNetworkError = useSelector(selectAddToCartNetworkError);

  const freeClassOrderStatusCode = useSelector(selectFreeClassOrderStatusCode);
  const freeClassOrderNetworkError = useSelector(selectFreeClassOrderNetworkError);
  const freeClassOrderLoading = useSelector(selectFreeClassOrderLoading);

  const currentDate = useSelector(selectCurrentDate);
  const freeTrialExpiryDate = useSelector(selectFreeTrialExpiry);
  const registerDate = useSelector(selectRegisterDate);
  const freeTrialAccessDate = useSelector(selectFreeTrialAccessDate);

  const cutOffDate = useSelector(selectCutOffDate);
  const cutOffDateStatusCode = useSelector(selectCutOffDateStatusCode);

  // Create order
  const orderCreated = useSelector(selectNewOrder);
  const createOrderLoading = useSelector(selectNewOrderLoading);

  useEffect(() => {
    dispatch(getCourseDetails(courseId));
    if (loginStatus) {
      dispatch(getCutOffDate());
    }
  }, [dispatch, courseId, loginStatus]);

  useEffect(() => {
    if (freeClassOrderStatusCode === 200) {
      toast({
        position: 'top',
        title: t('enrol_for_free_successful'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      history.push({
        pathname: '/dashboard',
        state: {
          needDelay: true,
        },
      });
    }
  }, [freeClassOrderStatusCode]);

  useEffect(() => {
    if (freeClassOrderNetworkError) {
      toast({
        title: t('network_error'),
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  }, [freeClassOrderNetworkError]);

  useEffect(() => {
    if (currentDate && freeTrialExpiryDate) {
      setFreeTrial(getFreeTrial(new Date(currentDate), freeTrialExpiryDate));
    }
  }, [currentDate, freeTrialExpiryDate]);

  useEffect(() => {
    if (orderCreated) {
      toast({
        position: 'top',
        title: t('enrol_for_free_successful'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      history.push({
        pathname: '/dashboard',
        state: {
          needDelay: true,
        },
      });
    }
  }, [orderCreated]);

  useAddToCart(addToCartStatusCode, addToCartErrorMessage, addToCartNetworkError);
  useSessionExpired(cutOffDateStatusCode);
  useSessionExpired(addToCartStatusCode);
  useSessionExpired(statusCode);
  useSessionExpired(freeClassOrderStatusCode);

  useEffect(() => {
    if (addToCartStatusCode === 200 && checkoutNow) {
      setTimeout(() => {
        history.push(`/${checkoutTo}`);
      }, 500);
    }
  }, [addToCartStatusCode]);

  if (networkError) return <NetworkErrorComponent />;

  // Loading state
  if (!courseArray) return <CourseLoader />;
  const course = courseArray[0];

  const hasDiscount = Boolean(course.discountPrice);

  const calculateDiscount = () => {
    if (!hasDiscount) return '';

    const { discountPrice, price } = course;
    const discount = (100 - (Number(discountPrice) / Number(price)) * 100).toFixed(0);

    return `${discount}% off`;
  };

  const onAddToCart = () => {
    dispatch(
      addToCart({
        cartType: 'course',
        instanceId: courseId.toString(),
      }),
    );

    onClosenBuyAddToCart();
    onCloseSubscribe();
  };

  const onCheckoutNow = (checkoutTo: string) => {
    onAddToCart();
    setCheckoutNow(true);
    setCheckoutTo(checkoutTo);
  };

  const goToLogin = () => {
    history.push('/signin');
  };

  const onClickEnrolFreeCourse = () => {
    dispatch(
      createFreeClassOrder({
        id: course.id,
        categoryType: 'course',
      }),
    );
  };

  // For free trial
  const onClickEnrolCourse = () => {
    if (freeTrialAccessDate) {
      let startMonth;
      let startYear;
      const startDate = new Date(course.startDate);
      const result = compareAsc(freeTrialAccessDate, startDate);
      if (result === -1) {
        startMonth = startDate.getMonth() + 1;
        startYear = startDate.getFullYear();
      } else {
        startMonth = freeTrialAccessDate.getMonth() + 1;
        startYear = freeTrialAccessDate.getFullYear();
      }
      const paymentDetails: PaymentDetails = {
        freeTrial: true,
        gateway: 'n/a',
        startMonth: startMonth,
        startYear: startYear,
      };
      const newOrder: CreateOrderPayload = {
        paymentDetails,
        cartItems: [
          {
            id: course.id,
            bundleDiscount: 0,
          },
        ],
        testMode: IS_DEV,
      };
      dispatch(createOrder(newOrder));
    }
  };

  const checkFreeTrialEligibility = () => {
    if (loginStatus === null || loginStatus === false) {
      return true;
    } else if (freeTrialAccessDate) {
      const endDate = new Date(course.endDate);
      const result = compareAsc(freeTrialAccessDate, endDate);
      if (result === 1) {
        return false;
      } else {
        return true;
      }
    }
  };

  return (
    <Box>
      <BackTopbar title={course.courseName} to="/classes" isWithSearchbar={false} />
      <Flex
        pt="4"
        pb="8"
        px="8"
        direction={{
          base: 'column',
          lg: 'row',
        }}>
        <Flex
          direction="column"
          w={{
            base: '100%',
            lg: '33%',
          }}
          alignItems="center"
          borderColor="gray.400"
          borderWidth="1px"
          my="4">
          {course.videoUrl ? (
            <AspectRatio w="full" ratio={16 / 9}>
              <Box
                as="video"
                width="full"
                height="full"
                controls
                poster={course.videoThumbnailUrl || TavisPlaceholder}
                controlsList="nodownload">
                <source src={course.videoUrl} />
              </Box>
            </AspectRatio>
          ) : (
            <AspectRatio w="full" ratio={16 / 9}>
              <Image
                w="100%"
                h="100%"
                src={course.backgroundImageUrl}
                fallbackSrc={TavisPlaceholder}
              />
            </AspectRatio>
          )}
          <Stack py="8" width="80%">
            <Flex
              justifyContent="space-between"
              alignItems="center"
              display={hasDiscount ? 'flex' : 'none'}>
              <Text
                fontSize={{
                  base: '16px',
                  lg: '20px',
                }}
                color="gray.300"
                textDecoration="line-through">
                {formatPrice(course.price)}/{t('month')}
              </Text>
              <Text
                fontSize={{
                  base: '16px',
                  lg: '20px',
                }}>
                {calculateDiscount()}
              </Text>
            </Flex>
            <Text
              mt="4"
              textAlign="center"
              fontSize={{
                base: '20px',
                lg: '26px',
              }}
              fontWeight="semibold">
              {hasDiscount
                ? formatPrice(course.discountPrice) + '/' + t('month')
                : formatPrice(course.price) + '/' + t('month')}
            </Text>
            {course.isFree ? (
              <VStack mt="4" minW="fit-content">
                <Flex w="full" justifyContent="center">
                  <Button
                    isLoading={freeClassOrderLoading}
                    p="2"
                    minW="fit-content"
                    w="250px"
                    fontSize={18}
                    fontWeight="semibold"
                    bg="#161DFF"
                    color="white"
                    mr="2"
                    onClick={onClickEnrolFreeCourse}
                    disabled={course.purchased}
                    _hover={{ bg: '#0000CA' }}
                    _active={{ bg: '#0000CA' }}>
                    {t('enrol_for_free')}
                  </Button>
                </Flex>
              </VStack>
            ) : (
              <VStack mt="4" minW="fit-content">
                <Flex w="full" alignItems="center" mt="4">
                  <Button
                    p="2"
                    w="250px"
                    bg="#1BC35C"
                    color="white"
                    mr="2"
                    onClick={(loginStatus === null || loginStatus === false) ? goToLogin : onOpenSubscribe}
                    _hover={{ bg: '#00912F' }}
                    _active={{ bg: '#00912F' }}
                  >
                    {t('subscribe')}
                  </Button>
                  <Modal isOpen={isOpenSubscribe} onClose={onCloseSubscribe} isCentered>
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader fontSize={26} fontWeight="semibold">
                        {t('please_choose')}
                        <Divider borderColor="black" mt={3} />
                      </ModalHeader>
                      <ModalFooter as={Flex} justifyContent="space-around" alignItems="center">
                        <Button
                          fontWeight="medium"
                          backgroundColor="#3007A3"
                          color="white"
                          _hover={{ backgroundColor: '#000073' }}
                          _active={{ backgroundColor: '#000073' }}
                          onClick={() => onCheckoutNow('subscription')}>
                          {t('checkout_now')}
                        </Button>
                        <Button
                          background="linear-gradient(to right, #2E62B7 , #4F91FC)"
                          color="white"
                          fontWeight="medium"
                          _hover={{ background: 'linear-gradient(to right, #2E62B7 , #4F91FC)' }}
                          _active={{ background: 'linear-gradient(to right, #2E62B7 , #4F91FC)' }}
                          onClick={onAddToCart}>
                          {t('add_to_subs')}
                        </Button>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                  <Tooltip
                    label={t('subscribe_info')}
                    fontSize={10}
                    fontWeight="medium"
                    placement="right"
                    maxW="150px"
                    isOpen={showSubscribeInfo}
                  >
                    <Box
                      as="span"
                      onMouseEnter={() => setShowSubscribeInfo(true)}
                      onMouseLeave={() => setShowSubscribeInfo(false)}
                      onClick={() => setShowSubscribeInfo(true)}
                    >
                      <InfoIcon boxSize="34" />
                    </Box>
                  </Tooltip>
                </Flex>
                <Flex w="full" alignItems="center">
                  <Button
                    p="2"
                    minW="fit-content"
                    w="250px"
                    mr="2"
                    bg="white"
                    border="1px solid #1BC35C"
                    onClick={(loginStatus === null || loginStatus === false) ? goToLogin : onOpenBuyAddToCart}
                  >
                    {t('pay_as_you_go')}
                  </Button>
                  <Modal isOpen={isOpenBuyAddToCart} onClose={onClosenBuyAddToCart} isCentered>
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader fontSize={26} fontWeight="semibold">
                        {t('please_choose')}
                        <Divider borderColor="black" mt={3} />
                      </ModalHeader>
                      <ModalFooter as={Flex} justifyContent="space-around" alignItems="center">
                        <Button
                          fontWeight="medium"
                          backgroundColor="#3007A3"
                          color="white"
                          _hover={{ backgroundColor: '#000073' }}
                          _active={{ backgroundColor: '#000073' }}
                          onClick={() => onCheckoutNow('prepaid')}>
                          {t('checkout_now')}
                        </Button>
                        <Button
                          background="linear-gradient(to right, #2E62B7 , #4F91FC)"
                          color="white"
                          fontWeight="medium"
                          _hover={{ background: 'linear-gradient(to right, #2E62B7 , #4F91FC)' }}
                          _active={{ background: 'linear-gradient(to right, #2E62B7 , #4F91FC)' }}
                          onClick={onAddToCart}>
                          {t('add_to_cart')}
                        </Button>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                  <Tooltip
                    label={t('buy_and_add_to_cart_info')}
                    fontSize={10}
                    fontWeight="medium"
                    placement="right"
                    isOpen={showAddToCartInfo}
                  >
                    <Box
                      as="span"
                      onMouseEnter={() => setShowAddToCartInfo(true)}
                      onMouseLeave={() => setShowAddToCartInfo(false)}
                      onClick={() => setShowAddToCartInfo(true)}
                    >
                      <InfoIcon boxSize="34" />
                    </Box>
                  </Tooltip>
                </Flex>
                {console.log('result: ', (freeTrial && !course.purchased) || loginStatus === null || loginStatus === false)}
                {((freeTrial && !course.purchased) || loginStatus === null || loginStatus === false) && (
                  <Flex w="full" alignItems="center">
                    {console.log('print here')}
                    <Button
                      isLoading={createOrderLoading}
                      p="2"
                      minW="fit-content"
                      w="250px"
                      mr="2"
                      bg="white"
                      border="1px solid #1BC35C"
                      onClick={(loginStatus === null || loginStatus === false) ? goToLogin : onClickEnrolCourse}
                      disabled={!checkFreeTrialEligibility() || course.ordered}
                    >
                      {t('free_trial_30_days')}
                    </Button>
                  </Flex>
                )}
              </VStack>
            )}
            {/* End of free course */}
            <Flex
              direction="column"
              pt={{
                base: '8',
                lg: '16',
              }}>
              <Text fontWeight="medium">{t('description')}:</Text>
              <Box fontSize="sm">{parse(course.description)}</Box>
            </Flex>
          </Stack>
        </Flex>
        <Flex
          direction="column"
          w={{
            base: '100%',
            lg: '33%',
          }}
          my="4"
          mx={{
            base: '0',
            md: '4',
          }}>
          <Heading size="md">{t('study_track')}</Heading>
          <StudyTrack track={course.studyTrack} {...course.duration} lectures={course.lectures} />
        </Flex>
        <Flex
          w={{
            base: '100%',
            lg: '33%',
          }}
          direction="column"
          my="4"
          mx={{
            base: '0',
            md: '4',
          }}>
          <Heading size="md">{t('tutor')}</Heading>

          <Image
            w="150px"
            h="150px"
            mt="4"
            bg="white"
            objectFit="scale-down"
            src={course.teacher.imageUrl}
            fallbackSrc={TavisPlaceholder}
          />

          <Flex direction="column" mt="4">
            <Text
              fontSize="lg"
              textDecoration="underline"
              fontWeight="medium"
              textTransform="capitalize">
              {`Cikgu ${course.teacher.firstname} ${course.teacher.lastname}`}
            </Text>
            <Text fontSize="sm" mt="4">
              {course.teacher.qualification}
            </Text>
            <Text fontSize="sm" mt="4">
              <StudentsIcon boxSize="20px" mr="4" />
              {t('student_taught', { count: course.teacher.students })}
            </Text>
            <Text fontSize="sm" mt="4">
              <CourseIcon boxSize="20px" mr="4" />
              {t('class', { count: course.teacher.courses })}
            </Text>
          </Flex>

          <Flex direction="column" mt="4">
            <Heading size="md" mt="8">
              {t('about_class')}
            </Heading>
            <Flex w="80%" direction="column">
              <Text fontSize="sm" mt="4">
                <LanguageIcon boxSize="20px" mr="4" />
                {t('language')}: {course.language.name}
              </Text>
              <Text fontSize="sm" mt="4">
                <TimeIcon boxSize="20px" mr="4" />
                {t('length')}:{' '}
                {Boolean(course.duration.months) &&
                  t('month', { count: Number(course.duration.months) })}{' '}
                {Boolean(course.duration.weeks) &&
                  t('week', { count: Number(course.duration.weeks) })}
                {!Boolean(course.duration.months) &&
                    !Boolean(course.duration.weeks) &&
                    `${t('month', { count: Number(course.duration.months) })} ${t('week', {
                      count: Number(course.duration.weeks),
                    })}`}
              </Text>
              <Text fontSize="sm" mt="4">
                <CalendarIcon boxSize="20px" mr="4" />
                {t('start_date')}: {format(parseISO(course.startDate), 'do MMM yyyy')}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
}

export default CourseDetails;
