// Important note: Not to alter the data structure
// Content can be updated / edited in object or array of object value
// - To make newline, use \n and \n\n
export const PpData = {
  title: 'Privacy Policy',
  description:
    'This Privacy informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service offered by Tavis Sdn Bhd (1405866M) (called “We”, the “Company”)\nWe use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms of Service.',
  content: [
    {
      subtitle: 'Information Collection and Use',
      description:
        'We collect several different types of information for various purposes to provide and improve our Service to you.',
    },
    {
      subtitle: 'Personal Data',
      description:
        'While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“Personal Data”). Personally identifiable information may include, but is not limited to information about you, from which you are identifiable, including but not limited to your name, telephone number, email address, credit or debit card details, race, gender, email address and any information about you which you have provided in registration forms or any other similar forms and/or any information about you that has been or may be collected, stored, used and processed by the Company.\n\nWe may use your Personal Data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt-out of receiving any, or all, of these communications from us. We may use your Personal Data to contact you regarding important information that could impact your usage of our Service and its related services.',
    },
    {
      subtitle: 'Usage Data',
      description:
        "We may also collect information that your browser sends whenever you visit our Service or when you access the Service by or through a mobile device (“Usage Data”).\n\nThis Usage Data may include information such as your computer’s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.\n\nWhen you access the Service by or through a mobile device, this Usage Data may include information such as the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data.",
    },
    {
      subtitle: 'Location Data',
      description:
        'We may use and store information about your location if you give us permission to do so (“Location Data”). We use this data to provide features of our Service, to improve and customise our Service. You can enable or disable location services when you use our Service at any time, through your device settings.',
    },
    {
      subtitle: 'Tracking & Cookies Data',
      description:
        'We use cookies and similar tracking technologies to track the activity on our Service and hold certain information.\n\nCookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyse our Service.\n\nYou can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.',
    },
    {
      subtitle: "Use of Data",
      description:
        'The Company uses the collected data for various purposes:\n • To provide and maintain our Service\n • To notify you about changes to our Service\n	•	To provide customer support\n	•	To gather analysis or valuable information so that we can improve our Service\n	•	To monitor the usage of our Service\n	•	To detect, prevent and address technical issues\n	•	To provide you with news, special offers and general information about other goods, services, and events which we offer that are like those that you have already purchased or enquired about unless you have opted not to receive such information\n	•	To personalise your experience (your information helps us to better respond to your individual needs)\n	•	For research into learning and/or teaching and/or on-line engagement',
    },
    {
      subtitle: 'Transfer of Data',
      description:
        'Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.\n\nThe Company will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organisation or a country unless there are adequate controls in place including the security of your data and other personal information.',
    },
    {
      subtitle: 'Disclosure of Data',
      description:
        'If the Company is involved in a merger, acquisition or asset sale, your Personal Data may be transferred. We will provide notice before your Personal Data is transferred and becomes subject to a different Privacy Policy.\n\nUnder certain circumstances, the Company may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency).\n\nThe Company may disclose your Personal Data in the good faith belief that such action is necessary to:\n	•	To comply with a legal obligation\n	•	To protect and defend the rights or property of the Company\n	•	To prevent or investigate possible wrongdoing in connection with the Service\n • To protect the personal safety of users of the Service or the public\n •	To protect against legal liability',
    },
    {
      subtitle: 'Security of Data',
      description:
        'We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information.\n\nThe security of your data is important to us; however, no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.',
    },
    {
      subtitle: 'Service Providers',
      description:
        'We may employ third party companies and individuals to facilitate our Service (“Service Providers”), to provide the Service on our behalf, to perform Service-related services or to assist us in analysing how our Service is used.\n\nThese third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.\n\nWe may provide paid products and/or services within the Service. In that case, we use third-party services for payment processing (e.g. payment processors).\n\nWe will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy.',
    },
    {
      subtitle: 'Children’s Privacy',
      description:
        'For courses and accounts created through our Service’s publicly accessible account registration system, we do not knowingly collect personally identifiable information from anyone under the age of 13. If you are a parent or guardian and you are aware that your child has provided us with Personal Data, please contact us.\n\nIf you are a parent, legal guardian, school, approved teachers, facilitator, or third-party provider permitting a Minor to create an account and/or use our Service, you agree to:\n • supervise the Minor’s use of the Service and their account.\n • assume all risks associated with, and liabilities resulting from, the Minor’s use of the Service and their account.\n • ensure that the courses that the Minor enrols in on our Service are suitable for the Minor.\n • ensure all information submitted to us by the Minor is accurate; and\n	•	provide the consents, representations, and warranties contained in the Privacy Policy and Terms of Service on the Minor’s behalf.',
    },
    {
      subtitle: 'Changes To This Privacy Policy',
      description:
        'We may update our Privacy Policy from time to time.\n\nYou are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.',
    },
    {
      subtitle: 'Contact',
      description:
        'If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at +603 2201 6686 or email to support@tavis.live.',
    },
  ],
};
