import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@chakra-ui/react';
import { LeftArrowIcon, RightArrowIcon } from 'src/icons';

interface CalendarIconButtonProps {
  isNextMonth: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
}

function CalendarIconButton({
  isNextMonth,
  isDisabled,
  onClick,
}: CalendarIconButtonProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <IconButton
      aria-label={isNextMonth ? t('next_month') : t('previous_month')}
      icon={isNextMonth ? <RightArrowIcon /> : <LeftArrowIcon />}
      bg="white"
      isDisabled={isDisabled}
      onClick={onClick}
    />
  );
}

export default CalendarIconButton;
