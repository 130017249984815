import { CoursesQuery } from 'src/models/Course';
import { AppActions } from '../rootActions';
import { GET_COURSES, GET_COURSE_DETAILS, GET_MORE_COURSES, RESET_COURSES } from './constants';

/**
 * Functions to be called in React components
 */

export const getCourses = (query: CoursesQuery): AppActions => ({
  type: GET_COURSES,
  payload: query,
});

export const getMoreCourses = (query: CoursesQuery): AppActions => ({
  type: GET_MORE_COURSES,
  payload: query,
});

export const resetCourses = (): AppActions => ({ type: RESET_COURSES });

export const getCourseDetails = (id: number): AppActions => ({ type: GET_COURSE_DETAILS, id });
