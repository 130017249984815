import {
  GET_MY_PROFILE,
  GET_MY_PROFILE_NETWORK_ERROR,
  GET_MY_PROFILE_SUCCESS,
  GET_MY_PROFILE_ERROR,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_ERROR,
  UPDATE_PROFILE_NETWORK_ERROR,
  UPLOAD_PROFILE_IMAGE,
  UPLOAD_PROFILE_IMAGE_SUCCESS,
  UPLOAD_PROFILE_IMAGE_ERROR,
  UPLOAD_PROFILE_IMAGE_NETWORK_ERROR,
} from './constants';
import { ProfileActionType, ProfileState } from './models';

export const profileInitialState: ProfileState = {
  // Profile
  profile: null,
  profileLoading: false,
  profileNetworkError: false,
  profileStatusCode: null,

  // Update Profile
  updateProfileLoading: false,
  updateProfileNetworkError: false,
  updateProfileStatusCode: null,

  // Upload Profile Image
  uploadProfileImageLoading: false,
  uploadProfileImageNetworkError: false,
  uploadProfileImageStatusCode: null,
};

export const profileReducer = (
  state = profileInitialState,
  action: ProfileActionType,
): ProfileState => {
  switch (action.type) {
    case GET_MY_PROFILE:
      return {
        ...state,
        profileLoading: true,
        profileStatusCode: null,
        profileNetworkError: false,
      };
    case GET_MY_PROFILE_SUCCESS:
      return {
        ...state,
        profileLoading: false,
        profile: action.payload.data.objects,
        profileStatusCode: action.payload.code,
      };
    case GET_MY_PROFILE_NETWORK_ERROR:
      return {
        ...state,
        profileLoading: false,
        profileNetworkError: true,
      };
    case GET_MY_PROFILE_ERROR:
      return {
        ...state,
        profileLoading: false,
        profileStatusCode: action.payload.code,
      };
    case UPDATE_PROFILE:
      return {
        ...state,
        updateProfileLoading: true,
        updateProfileNetworkError: false,
        updateProfileStatusCode: null,
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        updateProfileLoading: false,
        updateProfileStatusCode: action.payload.code,
      };
    case UPDATE_PROFILE_ERROR:
      return {
        ...state,
        updateProfileLoading: false,
        updateProfileStatusCode: action.payload.code,
      };
    case UPDATE_PROFILE_NETWORK_ERROR:
      return {
        ...state,
        updateProfileLoading: false,
        updateProfileNetworkError: true,
      };
    case UPLOAD_PROFILE_IMAGE:
      return {
        ...state,
        uploadProfileImageLoading: true,
        uploadProfileImageNetworkError: false,
        uploadProfileImageStatusCode: null,
      };
    case UPLOAD_PROFILE_IMAGE_SUCCESS:
      window.location.reload();
      return {
        ...state,
        uploadProfileImageLoading: false,
        uploadProfileImageStatusCode: action.payload.code,
      };
    case UPLOAD_PROFILE_IMAGE_ERROR:
      return {
        ...state,
        uploadProfileImageLoading: false,
        uploadProfileImageStatusCode: action.payload.code,
      };
    case UPLOAD_PROFILE_IMAGE_NETWORK_ERROR:
      return {
        ...state,
        uploadProfileImageLoading: false,
        uploadProfileImageNetworkError: true,
      };
    default:
      return state;
  }
};
