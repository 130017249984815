import React from 'react';
import { Button, Text, VStack } from '@chakra-ui/react';

import DashboardShell from 'src/components/DashboardShell';

function NetworkError(): JSX.Element {
  const onClickRetry = () => {
    window.location.reload();
  };

  return (
    <DashboardShell>
      <VStack h="100vh" justifyContent="center">
        <Text>Check your internet and try again</Text>
        <Button onClick={onClickRetry}>Retry</Button>
      </VStack>
    </DashboardShell>
  );
}

export default NetworkError;
