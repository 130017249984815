import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Image, LinkBox, LinkOverlay, Spacer, Text, Button } from '@chakra-ui/react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';

import { formatPrice } from 'src/utils/formatPrice';
import { getPurchaseHistory } from 'src/redux/purchase/actions';
import { selectPurchaseHistory, selectPurchaseHistoryLoading } from 'src/redux/purchase/selectors';
import Rectangle from 'src/images/rectangle.png';
import NavigateTopbar from 'src/components/Topbar/NavigateTopbar';
import Loader from 'src/components/Loader';
import { MyPurchasesBookIcon } from 'src/icons';
import NoPurchase from 'src/components/NoPurchase';

// TODO: Check DashboardShell - Need to refactor
function MyPurchases(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const purchases = useSelector(selectPurchaseHistory);
  const loading = useSelector(selectPurchaseHistoryLoading);

  const { search } = useLocation();
  const { from } = queryString.parse(search);

  useEffect(() => {
    // Coming after payment success
    if (from) {
      setTimeout(() => {
        dispatch(getPurchaseHistory());
      }, 2000);
    }

    // Default behavior
    else {
      dispatch(getPurchaseHistory());
    }
  }, [dispatch]);

  if (!purchases || loading) {
    return (
      <Box>
        <NavigateTopbar
          currentTab={t('my_purchases')}
          firstTitle={t('profile')}
          firstTo="/profile"
          secondTitle={t('my_purchases')}
          secondTo="/my-purchases"
          secondTitleWidth="220px"
          isWithSearchbar={false}
        />
        <Loader />
      </Box>
    );
  }

  const hasPurchase = Boolean(purchases.length);

  if (!hasPurchase) {
    return (
      <Box p={6} pt={0}>
        <NavigateTopbar
          currentTab={t('my_purchases')}
          firstTitle={t('profile')}
          firstTo="/profile"
          secondTitle={t('my_purchases')}
          secondTo="/my-purchases"
          secondTitleWidth="220px"
          isWithSearchbar={false}
        />
        <NoPurchase />
      </Box>
    );
  }

  return (
    <Box>
      <NavigateTopbar
        currentTab={t('my_purchases')}
        firstTitle={t('profile')}
        firstTo="/profile"
        secondTitle={t('my_purchases')}
        secondTo="/my-purchases"
        secondTitleWidth="220px"
        isWithSearchbar={false}
      />
      <Box px="8" overflowY="auto" h="full" pt="8">
        {purchases.map((order) => {
          const isPrepaid = order.gateway === 'iPayOnlineBanking';
          return (
            <LinkBox as={Flex} key={order.id} direction="column" boxShadow="base" p="8" my="6">
              <LinkOverlay
                display="flex"
                flexDirection={{
                  base: 'column',
                  md: 'row',
                }}
                as={Link}
                to={`/my-purchases/${order.id}`}
                w="full"
                justifyContent="space-between"
                fontSize="large">
                <Text fontWeight="medium">#{order.refNo}</Text>
                <Text>{order.date}</Text>
              </LinkOverlay>
              <Box
                w="full"
                mt={{
                  base: '0',
                  md: '4',
                }}>
                {order.items?.map((item) => (
                  <Flex
                    flexDirection={{
                      base: 'column',
                      md: 'row',
                    }}
                    alignItems={{
                      base: 'flex-start',
                      md: 'center',
                    }}
                    key={item.id}
                    borderBottomColor="gray.500"
                    borderBottomWidth="1px"
                    py="8">
                    <Flex
                      justifyContent="center"
                      w={{
                        base: '100%',
                        md: 'auto',
                      }}
                      mb={{
                        base: '4',
                        md: '0',
                      }}>
                      <Image
                        src={item.imageURL || Rectangle}
                        minW="55px"
                        minH="55px"
                        w={{
                          base: '150px',
                          md: '55px',
                        }}
                        h={{
                          base: '150px',
                          md: '55px',
                        }}
                        fit="cover"
                      />
                    </Flex>
                    <Text
                      ml={{
                        base: '0',
                        md: '4',
                      }}
                      textTransform="capitalize">
                      {item.title
                        .split('- ')
                        .map((title, id) => {
                          if (id === 3) {
                            return 'Cikgu ' + title;
                          }
                          return title;
                        })
                        .join('- ')}
                    </Text>
                    <Spacer />
                    <Text>
                      {formatPrice(item.priceInfo.finalPrice)}
                      {isPrepaid ? '' : '/month'}
                    </Text>
                  </Flex>
                ))}
              </Box>
            </LinkBox>
          );
        })}
      </Box>
    </Box>
  );
}

export default MyPurchases;
