import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Flex,
  Heading,
  Input,
  Button,
  Text,
  HStack,
  Stack,
  Box,
  IconButton,
  Img,
  VStack,
  InputGroup,
  InputRightElement,
  useToast,
  Tooltip,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import parse from 'html-react-parser';

import TavisLogo from 'src/images/tavis_logo.svg';
import Background from 'src/images/img_login_register.png';
import { BackIcon, HideIcon, ShowIcon } from 'src/icons';
import { register, clearStatus, login } from 'src/redux/auth/actions';
import {
  selectRegisterStatus,
  selectRegisterErrorMessage,
  selectRegisterLoading,
  selectLoginStatus,
} from 'src/redux/auth/selectors';
import { InfoIcon } from '@chakra-ui/icons';

function Register(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const toast = useToast();

  const registerStatus = useSelector(selectRegisterStatus);
  const errorMessageFromRedux = useSelector(selectRegisterErrorMessage);
  const registerLoading = useSelector(selectRegisterLoading);
  const loginStatus = useSelector(selectLoginStatus);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPasswordTooltip, setShowPasswordTooltip] = useState(false);

  const onClickRegister = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const emailInput = document.getElementById('email') as HTMLInputElement;
    const firstNameInput = document.getElementById('firstname') as HTMLInputElement;
    const lastNameInput = document.getElementById('lastname') as HTMLInputElement;
    const passwordInput = document.getElementById('password') as HTMLInputElement;
    const confirmPasswordInput = document.getElementById('confirmPassword') as HTMLInputElement;

    const email = emailInput.value;
    const firstname = firstNameInput.value;
    const lastname = lastNameInput.value;
    const password = passwordInput.value;
    const confirmPassword = confirmPasswordInput.value;

    const isPasswordValid = password === confirmPassword;

    if (!isPasswordValid) {
      setErrorMessage('Please ensure password and confirm password are the same');
    } else {
      setErrorMessage(null);
      dispatch(
        register({
          firstname: firstname,
          lastname: lastname,
          email: email,
          password: password,
          role: 'student',
        }),
      );
    }
  };

  useEffect(() => {
    if (registerStatus && errorMessageFromRedux) {
      const emailInput = document.getElementById('email') as HTMLInputElement;
      const passwordInput = document.getElementById('password') as HTMLInputElement;
      const email = emailInput.value;
      const password = passwordInput.value;

      toast({
        position: 'top',
        title: parse(errorMessageFromRedux),
        status: 'success',
      });

      dispatch(clearStatus());
      dispatch(
        login({
          email,
          password,
          role: 'student',
          rememberMe: true,
        }),
      );
    }
  }, [registerStatus, dispatch, history, errorMessageFromRedux, toast]);

  useEffect(() => {
    if (loginStatus) {
      const emailInput = document.getElementById('email') as HTMLInputElement;
      const email = emailInput.value;
      history.push({
        pathname: '/email-verification',
        state: { email: email },
      });
    }
  }, [loginStatus]);

  const displayErrorMessage = () => {
    if (errorMessage) {
      return (
        <Box color="red" mb={10}>
          {errorMessage}
        </Box>
      );
    } else if (errorMessageFromRedux) {
      return (
        <Box color="red" mb={10}>
          {parse(errorMessageFromRedux)}
        </Box>
      );
    }
  };

  return (
    <Flex
      h={{
        base: '100%',
        sm: '100vh',
      }}>
      <Stack
        display={{
          base: 'none',
          lg: 'flex',
        }}
        direction="column"
        align="center"
        justify="center"
        spacing={20}
        w="40%"
        h="100%"
        backgroundColor="white"
        overflow="hidden">
        <Img src={TavisLogo} width="328px" />
        <Img src={Background} />
      </Stack>
      <Stack
        color="white"
        backgroundColor="#65CD7D"
        h="100%"
        w={{
          base: '100%',
          lg: '60%',
        }}
        direction="column"
        align="center"
        justify="center"
        spacing={8}
        m="auto"
        px="20px"
        py={{
          base: '100px',
          md: '20px',
        }}>
        <VStack color="white" spacing={8} m="auto">
          <HStack maxW="700px">
            <IconButton
              aria-label="Back"
              icon={<BackIcon boxSize="24px" />}
              bg="none"
              _hover={{ bg: 'none' }}
              _active={{ bg: 'none' }}
              onClick={() => history.push('/signin')}
            />
            <Heading
              as="h1"
              fontSize={{
                base: 24,
                sm: 34,
              }}
              fontWeight="bold">
              {t('register_with_us')}
            </Heading>
          </HStack>
          <Box
            as="form"
            onSubmit={onClickRegister}
            width={{
              base: '100%',
              sm: 'auto',
            }}
            maxW="700px">
            <Flex
              fontSize={18}
              fontWeight="bold"
              mb={10}
              direction={{
                base: 'column',
                sm: 'row',
              }}>
              <Box
                w={{
                  base: '100%',
                  sm: '50%',
                }}
                mb={{
                  base: 5,
                  sm: 0,
                }}>
                <Text mb={2} ml={3}>
                  {t('firstname')}
                  <Box as="span" color="red">
                    *
                  </Box>
                </Text>
                <Input required bg="white" color="black" borderRadius="full" id="firstname" />
              </Box>
              <Box
                w={{
                  base: '100%',
                  sm: '50%',
                }}
                ml={{
                  base: '0',
                  sm: '20px',
                }}>
                <Text mb={2} ml={3}>
                  {t('lastname')}
                  <Box as="span" color="red">
                    *
                  </Box>
                </Text>
                <Input required bg="white" color="black" borderRadius="full" id="lastname" />
              </Box>
            </Flex>
            <Text fontSize={18} fontWeight="bold" ml={3} mb={2}>
              {t('email')}
              <Box as="span" color="red">
                *
              </Box>
            </Text>
            <Input
              required
              type="email"
              mb={10}
              bg="white"
              w="100%"
              color="black"
              borderRadius="full"
              id="email"
            />
            <Text fontSize={18} fontWeight="bold" ml={3} mb={2}>
              {t('password')}
              <Box as="span" color="red">
                *
              </Box>
              <Tooltip
                label={`*${t('password_validation')}`}
                fontSize={12}
                placement="right"
                isOpen={showPasswordTooltip}
              >
                <Box
                  as="span"
                  onMouseEnter={() => setShowPasswordTooltip(true)}
                  onMouseLeave={() => setShowPasswordTooltip(false)}
                  onClick={() => setShowPasswordTooltip(true)}
                >
                  <InfoIcon color="#595959" boxSize="15px" />
                </Box>
              </Tooltip>
            </Text>
            <InputGroup mb={10}>
              <Input
                required
                type={showPassword ? 'text' : 'password'}
                bg="white"
                color="black"
                borderRadius="full"
                id="password"
              />
              <InputRightElement cursor="pointer" onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <ShowIcon /> : <HideIcon color="black" />}
              </InputRightElement>
            </InputGroup>
            <Text fontSize={18} fontWeight="bold" ml={3} mb={2}>
              {t('confirm_password')}
              <Box as="span" color="red">
                *
              </Box>
            </Text>
            <InputGroup mb={10}>
              <Input
                required
                type={showConfirmPassword ? 'text' : 'password'}
                bg="white"
                color="black"
                borderRadius="full"
                id="confirmPassword"
              />
              <InputRightElement
                cursor="pointer"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? <ShowIcon /> : <HideIcon color="black" />}
              </InputRightElement>
            </InputGroup>
            {displayErrorMessage()}
            <Flex justifyContent="center">
              <Button
                isLoading={registerLoading}
                p={5}
                w="50%"
                bg="none"
                fontSize={{
                  base: 16,
                  md: 27,
                }}
                fontWeight="bold"
                borderRadius="full"
                border="4px solid white"
                type="submit"
                _active={{ backgroundColor: '#2F9B50' }}
                _hover={{ backgroundColor: '#2F9B50' }}>
                {t('continue')}
              </Button>
            </Flex>
          </Box>
        </VStack>
      </Stack>
    </Flex>
  );
}

export default Register;
