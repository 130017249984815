import { CareerState } from './models';
import AppState from '../rootState';

const selectCareerState = (state: AppState): CareerState => state.career;

export const selectSubmitFormStatus = (state: AppState): boolean | null =>
  selectCareerState(state).submitTutorStatus;

export const selectSubmitFormErrorMessage = (state: AppState): string | null =>
  selectCareerState(state).submitTutorErrorMessage;

export const selectSubmitFormLoading = (state: AppState): boolean | undefined =>
  selectCareerState(state).submitTutorLoading;
