import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Button } from '@chakra-ui/react';
import { AppleIcon } from 'src/icons';
import AppleSignin from 'react-apple-signin-auth';
import { API_URL } from '../../constants/api';

const { v4 } = require('uuid');

function SignInWithApple(): JSX.Element {
  const { t } = useTranslation();

  const clientId = String(process.env.REACT_APP_APPLE_CLIENT_ID);
  const redirectUri = API_URL + '/auth/appleRedirect';

  // Custom apple signin button
  const appleLoginButton = (renderProps: { onClick: () => void }) => {
    return (
      <Button
        minW="250px"
        w="250px"
        color="white"
        bg="black"
        fontWeight="regular"
        rounded="full"
        justifyContent="flex-start"
        _hover={{ bg: 'gray.800' }}
        _active={{ bg: 'gray.800' }}
        onClick={renderProps.onClick}>
        <AppleIcon />
        <Flex justifyContent="center" flex="1">
          {t('sign_in_with', { provider: 'Apple' })}
        </Flex>
      </Button>
    );
  };

  return (
    <AppleSignin
      authOptions={{
        clientId: clientId,
        redirectURI: redirectUri,
        scope: 'name email',
        responseType: 'id_token code',
        responseMode: 'form_post',
        state: v4(),
        usePopup: false,
      }}
      render={appleLoginButton}
    />
  );
}

export default SignInWithApple;
