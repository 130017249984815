// tavisW  - White
// tavisGy - Gray
// tavisG  - Green
export const main = {
  tavisGy: {
    10: '#07080F', // Heading, Title
    20: '#595959', // Paragraph, Text
    30: '#F8F8F8', // Content Background
    40: '#EBEBEB', // Footer Background
    50: '#D4D4D4', // Divider
    60: '#929292',
  },
  tavisG: {
    10: '#E7F9EB',
    20: '#65CD7D',
    30: '#5FC276',
  },
};
