import React from 'react';
import { Box, Container, Heading, Stack, Flex } from '@chakra-ui/react';
import Profile from './Profile';

import Husna from 'src/images/tutors/Husna.png';
import Kelly from 'src/images/tutors/Kelly.png';
import Suhada from 'src/images/tutors/Suhada.png';
import Winnie from 'src/images/tutors/Winnie.png';

function OurTutor() {
  return (
    <Box py={{ base: '40px', md: '100px' }}>
      <Container maxW={'7xl'}>
        <Stack
          m="auto"
          maxW="90%"
          align="center"
          justify="center"
          spacing={{ base: '50px', md: '20px'}}
        >
          <Heading as="h1" fontSize="41px" fontWeight="bold">Our Tutors</Heading>
          <Flex direction={{ base: 'column', md: 'row'}} wrap="wrap" justify="space-evenly">
            {tutors.map((tutor) => (
              <Profile key={tutor.name} {...tutor} />
            ))}
          </Flex>
        </Stack>
      </Container>
    </Box>
  )
}

export default OurTutor;

// Old Structure
// const tutors = [
//   {
//     image: Tutor1,
//     name: 'Ms. Nurul Suhada Mazlan',
//     subjects: 'Science, Biology & Chemistry',
//     qualifications: ['Bachelors in Forensics & Chemistry, University Kebangsaan Malaysia.'],
//     teachingExperience: [
//       'Clinical Pathologist at HUKM for 1 year.',
//       'Pure Science Teacher at Asia Pacific School for 4 years.',
//       'Tutor at MC Plus.',
//       'Private Tutor for 6 years.',
//       'Writer & Editor for Buku Modul Biologi, Kimia & Sains for Nilam Publications.',
//     ],
//     description: 'Teacher Su is a graduate of Forensics & Chemistry from Universiti Kebangsaan Malaysia. She has been a forensic pathologist in Hospital Universiti Kebangsaan Malaysia before pursuing into teaching pure science subjects in Asia Pacific School for 4 years. She started private tutoring since her degree time and still is a private tutor until now. Briefly, she was a tutor for MC Plus during her time teaching in Asia Pacific School. She then worked closely with Nilam Publications as a writer and editor for the biology, chemistry and science modules in Malay. Currently, she is a full-time tutor in Tavis.'
//   },
//   {
//     image: Tutor2,
//     name: 'Ms. Husna Syairah',
//     subjects: 'English',
//     qualifications: ['Bachelor of English, UniTAR International University.'],
//     teachingExperience: [
//       'Taught English language subjects & other English skill-related subjects for years in language centres & private colleges.',
//     ],
//     description: 'Teacher Husna is a graduate of English from Universiti Tun Abdul Razak. She possesses a broad experience in teaching English & English skill-related subjects for more than 10 years. She has been teaching local & international students in private colleges & language centres.'
//   },
//   {
//     image: NoTutorImage,
//     name: 'Ms. Nur Atikah',
//     subjects: 'Prinsip Perakaunan, Ekonomi Asas, Geografi',
//     qualifications: [
//       'Master in Business Administration, UniTAR International University.',
//       'Bachelor of Finance (Honours), Management & Science University (MSU)',
//     ],
//     teachingExperience: [
//       'Over 3 years experience in private tutoring for Business & Management foundation students.',
//       'Over 3 years teaching Prinsip Perakaunan students in Form 4 & Form 5.',
//     ],
//     description: 'Teacher Artikah is an accountant in a private firm and has been in the industry for more than 5 years. She holds a Master’s Degree in Business Administration from Management & Science University. She has been tutoring for Business & Management foundation students for 3 years. She has also been tutoring Form 4 & Form 5 students in Prinsip Perakaunan subject.'
//   },
//   {
//     image: NoTutorImage,
//     name: 'Ms. Winnie Lim Li Sze',
//     subjects: 'Physics',
//     qualifications: [
//       'Bachelor of Science in Education, University Pendidikan Sultan Idris.',
//     ],
//     teachingExperience: [
//       'Over 12 years of teaching experience at SMK Damansara Jaya.',
//     ],
//     description: 'Teacher Winnie is a government school teacher with over 12 years of experience. She holds a Bachelor of Science in Education from Universiti Pendidikan Sultan Idris. She possesses a vast experience in private tutoring for over 15 years. Currently, she is a part-time tutor in Tavis.'
//   },
//   {
//     image: NoTutorImage,
//     name: 'Ms. Tan Ai Li ',
//     subjects: 'Bahasa Melayu, Science',
//     qualifications: [
//       'Masters in Business Administration, Charles Sturt University, Australia.',
//       'Bachelor of Business (Marketing & Management), Edith Cowan University, Australia',
//     ],
//     teachingExperience: [
//       '12 years teaching experience in Management & Marketing in Higher Learning Institutions',
//       '12 years teaching experience in Bahasa Melayu & Science in International & Private Schools ',
//     ],
//     description: 'Teacher Winnie is a government school teacher with over 12 years of experience. She holds a Bachelor of Science in Education from Universiti Pendidikan Sultan Idris. She possesses a vast experience in private tutoring for over 15 years. Currently, she is a part-time tutor in Tavis.'
//   },
// ]

export const tutors = [
  {
    image: Suhada,
    name: 'Ms. Nurul Suhada Mazlan',
    subjects: 'Science, Biology, Chemistry',
    description: 'Teacher Suhada is a graduate of Forensics & Chemistry. She possesses an experience of over 5 years in teaching pure science subjects. She has been actively involved in writing and editing sciences module with renowned publication firms.'
  },
  {
    image: Winnie,
    name: 'Ms. Winnie Lim Li Sze',
    subjects: 'Physics',
    description: 'Teacher Winnie is a government school teacher with over 12 years of experience. She holds a Bachelor of Science in Education. She possesses a vast experience in private tutoring for over 15 years.'
  },
  {
    image: Husna,
    name: 'Ms. Husna Syairah',
    subjects: 'English',
    description: 'Teacher Husna is an educator. She is a graduate of English (TESOL) and possesses a vast experience in teaching the English language and English skill-related subjects for more than 10 years. She has been teaching local & international students from private colleges and language centres.'
  },
  {
    image: Kelly,
    name: 'Ms. Tan Ai Li',
    subjects: 'Bahasa Melayu, Science',
    description: "Cikgu Tan is an educator, having over 12 years of teaching experience in Management & Marketing subjects. She holds a Master's Degree in Business Administration. She is also a tutor for Bahasa Melayu & Sains subjects for primary & secondary school students.",
  },
]