import { QuestionBankActionType, QuestionBankState } from './models';
import {
  GET_QUESTION_BANK,
  GET_QUESTION_BANK_SUCCESS,
  GET_QUESTION_BANK_ERROR,
  GET_QUESTION_BANK_NETWORK_ERROR,
  GET_MORE_QUESTION_BANK,
  GET_MORE_QUESTION_BANK_SUCCESS,
  GET_PURCHASED_QUESTION_BANK,
  GET_PURCHASED_QUESTION_BANK_SUCCESS,
  GET_PURCHASED_QUESTION_BANK_ERROR,
  GET_PURCHASED_QUESTION_BANK_NETWORK_ERROR,
  GET_MORE_PURCHASED_QUESTION_BANK,
  GET_MORE_PURCHASED_QUESTION_BANK_SUCCESS,
  GET_QUESTION,
  GET_QUESTION_SUCCESS,
  GET_QUESTION_ERROR,
  GET_QUESTION_NETWORK_ERROR,
  SAVE_ANSWER,
  SAVE_ANSWER_SUCCESS,
  SAVE_ANSWER_ERROR,
  SAVE_ANSWER_NETWORK_ERROR,
  RESET_QUESTION_BANK,
  RESET_PURCHASED_QUESTION_BANK,
  RESET_SAVE_ANSWER,
} from './constants';

export const questionBankInitialState: QuestionBankState = {
  // Question bank
  questionBank: null,
  questionBankLoading: false,
  questionBankStatusCode: null,
  questionBankRefetching: false,
  questionBankPagination: undefined,
  questionBankNetworkError: false,

  // Purchased question bank
  purchasedQuestionBank: null,
  purchasedQuestionBankLoading: false,
  purchasedQuestionBankStatusCode: null,
  purchasedQuestionBankRefetching: false,
  purchasedQuestionBankPagination: undefined,
  purchasedQuestionBankNetworkError: false,

  // Question
  question: null,
  questionLoading: false,
  questionStatusCode: null,
  questionNetworkError: false,

  // Save answer
  saveAnswerLoading: false,
  saveAnswerStatusCode: null,
  saveAnswerNetworkError: false,
  isFinished: false,
};

export const questionBankReducer = (
  state = questionBankInitialState,
  action: QuestionBankActionType,
): QuestionBankState => {
  switch (action.type) {
    case GET_QUESTION_BANK:
      return {
        ...state,
        questionBankStatusCode: null,
        questionBankLoading: true,
        questionBankNetworkError: false,
      };
    case GET_QUESTION_BANK_SUCCESS:
      return {
        ...state,
        questionBankLoading: false,
        questionBankStatusCode: action.payload.code,
        questionBank: action.payload.data.objects,
        questionBankPagination: action.payload.pagination,
      };
    case GET_QUESTION_BANK_ERROR:
      return {
        ...state,
        questionBankLoading: false,
        questionBankStatusCode: action.payload.code,
      };
    case GET_QUESTION_BANK_NETWORK_ERROR:
      return {
        ...state,
        questionBankLoading: false,
        questionBankNetworkError: true,
      };
    case RESET_QUESTION_BANK:
      return {
        ...state,
        questionBank: null,
        questionBankLoading: false,
        questionBankStatusCode: null,
        questionBankRefetching: false,
        questionBankPagination: undefined,
      };
    case GET_MORE_QUESTION_BANK:
      return {
        ...state,
        questionBankStatusCode: null,
        questionBankRefetching: true,
      };
    case GET_MORE_QUESTION_BANK_SUCCESS: {
      if (!state.questionBank) {
        return {
          ...state,
          questionBankStatusCode: action.payload.code,
        };
      }

      return {
        ...state,
        questionBankRefetching: false,
        questionBankStatusCode: action.payload.code,
        questionBank: [...state.questionBank, ...action.payload.data.objects],
        questionBankPagination: action.payload.pagination,
      };
    }
    case GET_PURCHASED_QUESTION_BANK:
      return {
        ...state,
        purchasedQuestionBankNetworkError: false,
        purchasedQuestionBankStatusCode: null,
        purchasedQuestionBankLoading: true,
      };
    case GET_PURCHASED_QUESTION_BANK_SUCCESS:
      return {
        ...state,
        purchasedQuestionBankStatusCode: action.payload.code,
        purchasedQuestionBankLoading: false,
        purchasedQuestionBank: action.payload.data.objects,
        purchasedQuestionBankPagination: action.payload.pagination,
      };
    case GET_PURCHASED_QUESTION_BANK_ERROR:
      return {
        ...state,
        purchasedQuestionBankLoading: false,
        purchasedQuestionBankStatusCode: action.payload.code,
      };
    case GET_PURCHASED_QUESTION_BANK_NETWORK_ERROR:
      return {
        ...state,
        purchasedQuestionBankLoading: false,
        purchasedQuestionBankNetworkError: true,
      };
    case RESET_PURCHASED_QUESTION_BANK:
      return {
        ...state,
        purchasedQuestionBank: null,
        purchasedQuestionBankLoading: false,
        purchasedQuestionBankStatusCode: null,
        purchasedQuestionBankRefetching: false,
        purchasedQuestionBankPagination: undefined,
      };
    case GET_MORE_PURCHASED_QUESTION_BANK:
      return {
        ...state,
        purchasedQuestionBankStatusCode: null,
        purchasedQuestionBankRefetching: true,
      };
    case GET_MORE_PURCHASED_QUESTION_BANK_SUCCESS: {
      if (!state.purchasedQuestionBank) {
        return {
          ...state,
          purchasedQuestionBankStatusCode: action.payload.code,
        };
      }

      return {
        ...state,
        purchasedQuestionBankRefetching: false,
        purchasedQuestionBankStatusCode: action.payload.code,
        purchasedQuestionBank: [...state.purchasedQuestionBank, ...action.payload.data.objects],
        purchasedQuestionBankPagination: action.payload.pagination,
      };
    }
    case GET_QUESTION: {
      return {
        ...state,
        questionNetworkError: false,
        questionLoading: true,
        questionStatusCode: null,
      };
    }
    case GET_QUESTION_SUCCESS: {
      return {
        ...state,
        questionLoading: false,
        questionStatusCode: action.payload.code,
        question: action.payload.data.objects,
      };
    }
    case GET_QUESTION_ERROR:
      return {
        ...state,
        questionLoading: false,
        questionStatusCode: action.payload.code,
      };
    case GET_QUESTION_NETWORK_ERROR:
      return {
        ...state,
        questionLoading: false,
        questionNetworkError: true,
      };
    case SAVE_ANSWER:
      return {
        ...state,
        saveAnswerLoading: true,
        saveAnswerNetworkError: false,
        question:
          state.question?.map((item) =>
            item.id !== action.payload.id ? item : { ...item, answer: action.payload.answer },
          ) || null,
      };
    case SAVE_ANSWER_SUCCESS:
      return {
        ...state,
        saveAnswerLoading: false,
        saveAnswerStatusCode: action.payload.code,
        isFinished: action.payload.data?.objects.isFinished,
      };
    case RESET_SAVE_ANSWER:
      return {
        ...state,
        saveAnswerLoading: false,
        saveAnswerStatusCode: null,
        saveAnswerNetworkError: false,
        isFinished: false,
      };
    case SAVE_ANSWER_ERROR:
      return {
        ...state,
        saveAnswerLoading: false,
        saveAnswerStatusCode: action.payload.code,
      };
    case SAVE_ANSWER_NETWORK_ERROR:
      return {
        ...state,
        saveAnswerLoading: false,
        saveAnswerNetworkError: true,
      };
    default:
      return state;
  }
};
