import React, { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { submit } from 'src/redux/career/actions';
import {
  Flex,
  Box,
  Textarea,
  Input,
  Container,
  Text,
  InputGroup,
  Button,
  Image,
  FormErrorMessage,
} from '@chakra-ui/react';
import TitleTopbar from '../../../components/Topbar/TitleTopbar';
import FileUploadIcon from 'src/images/icon_upload.svg';
import { FileUploadProps } from '../../../redux/career/models/payload';
import { FormValues } from '../../../redux/career/models/payload';
import { selectSubmitFormStatus, selectSubmitFormLoading } from '../../../redux/career/selectors';
import ReCAPTCHA from "react-google-recaptcha";

function CareerForm(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const submitFormStatus = useSelector(selectSubmitFormStatus);
  const submitTutorLoading = useSelector(selectSubmitFormLoading);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  let captcha: string = '';

  const {
    register,
    formState: { errors },
  } = useForm<FormValues>();
  const placeholder =
    'i. Teaching experience \nii. Teaching level : KSSR/KSSM/IGCSE etc. \niii. Subject(s)';

  const FileUpload = (props: FileUploadProps) => {
    const { register, accept, multiple, children } = props;
    const inputRef = useRef<HTMLInputElement | null>(null);
    const { ref, ...rest } = register as { ref: (instance: HTMLInputElement | null) => void };
    const handleClick = () => inputRef.current?.click();

    const handleFileUpload = (event: any) => {
      if (event.target.files[0]) {
        setFileName(event.target.files[0].name);
        setFile(event.target.files[0]);
      } else {
        setFileName('');
      }
    };

    return (
      <InputGroup
        onClick={handleClick}
        border="1px dashed #C4C4C4"
        borderRadius="5px"
        height="auto"
        py="40px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center">
        <Image src={FileUploadIcon} mb="12px" />
        <Text fontSize="11px" mb="20px">
          Accepted File Types: PDF, Word
        </Text>
        <Input
          type={'file'}
          multiple={multiple || false}
          hidden
          accept={accept}
          {...rest}
          ref={(e) => {
            ref(e);
            inputRef.current = e;
          }}
          onChange={handleFileUpload}
          id="resume"
        />
        <>{children}</>
        <Text mt="20px">{fileName}</Text>
      </InputGroup>
    );
  };

  const onClickSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const firstnameInput = document.getElementById('firstname') as HTMLInputElement;
    const lastnameInput = document.getElementById('lastname') as HTMLInputElement;
    const emailInput = document.getElementById('email') as HTMLInputElement;
    const phoneNoInput = document.getElementById('phoneNo') as HTMLInputElement;
    const aboutInput = document.getElementById('about') as HTMLInputElement;
    const resumeInput = file;

    if (captcha == '') {
      alert('Incorrect captcha, please try again.');
      return;
    }

    const firstname = firstnameInput.value;
    const lastname = lastnameInput.value;
    const email = emailInput.value;
    const phoneNo = phoneNoInput.value;
    const about = aboutInput.value;
    const resume = resumeInput;

    dispatch(
      submit({
        firstName: firstname,
        lastName: lastname,
        email: email,
        number: phoneNo,
        info: about,
        resume: resume,
        captcha: captcha,
      }),
    );
  };

  const onChange = (value: any) => {
    captcha = value;
  }

  useEffect(() => {
    if (submitFormStatus) {
      history.push({
        pathname: '/career/success',
      });
    }
  }, [submitFormStatus, history]);

  const validateFiles = (value: FileList) => {
    if (value.length < 1) {
      return 'Files is required';
    }

    for (const file of Array.from(value)) {
      const fsMb = file.size / (1024 * 1024);
      const MAX_FILE_SIZE = 10;
      if (fsMb > MAX_FILE_SIZE) {
        return 'Max file size 10MB';
      }
    }

    return true;
  };

  return (
    <Box>
      <TitleTopbar title={t('Tutor Recruitment Form')} isWithSearchbar={false} />
      <Container maxW="container.xl">
        <Flex mt="50px" mb="80px">
          <Box as="form" onSubmit={onClickSubmit} width="100%">
            {/* First Name */}
            <Text fontSize={16} mb={4}>
              {t('First Name')}
            </Text>
            <Input
              borderRadius="5"
              _focus={{
                borderColor: '#65CD7D',
              }}
              id="firstname"
            />
            {/* Last Name */}
            <Text fontSize={16} mt="28px" mb={4}>
              {t('Last Name')}
            </Text>
            <Input
              borderRadius="5"
              _focus={{
                borderColor: '#65CD7D',
              }}
              id="lastname"
            />
            {/* Email */}
            <Text fontSize={16} mt="28px" mb={4}>
              {t('email')}
            </Text>
            <Input
              type="email"
              borderRadius="5"
              _focus={{
                borderColor: '#65CD7D',
              }}
              id="email"
            />
            {/* Contact No */}
            <Text fontSize={16} mt="28px" mb={4}>
              {t('Contact No.')}
            </Text>
            <Input
              type="number"
              borderRadius="5"
              _focus={{
                borderColor: '#65CD7D',
              }}
              id="phoneNo"
            />
            {/* Tell Us About Yourself */}
            <Text fontSize={16} mt="28px" mb={4}>
              {t('Tell Us About Yourself')}
            </Text>
            <Textarea
              placeholder={placeholder}
              height="156"
              _focus={{
                borderColor: '#65CD7D',
              }}
              id="about"
            />
            {/* Attach Your Resume */}
            <Text fontSize={16} mt="28px" mb={4}>
              {t('Attach Your Resume')}
            </Text>
            <FileUpload
              accept={'.pdf, .doc, .docx'}
              multiple
              register={register('file_', { validate: validateFiles })}>
              <Button>Choose file</Button>
            </FileUpload>
            <FormErrorMessage>{errors.file_ && errors?.file_.message}</FormErrorMessage>

            <Box mt="40px">
              <ReCAPTCHA 
                sitekey={'6LeHPgEfAAAAACvHPFGT2FmAH_uiwvw9_77_36KS'}
                onChange={onChange}
              />
            </Box>

            {/* Submit Tutor Recruitment Form */}
            <Button
              isLoading={submitTutorLoading}
              py={5}
              px={10}
              mt="50px"
              fontSize={16}
              borderRadius="5"
              type="submit"
              bg="#65CD7D"
              color="white"
              width={{
                base: '100%',
                md: 'auto',
              }}
              _active={{ backgroundColor: '#2F9B50' }}
              _hover={{ backgroundColor: '#2F9B50' }}>
              Submit
            </Button>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
}

export default CareerForm;
