import { addHours, format, parseISO } from 'date-fns';

export const formatClassTime = (date: string): string => {
  const timeFormat = 'h:mmaaa';

  const startTime = format(parseISO(date), timeFormat);
  const endTime = format(addHours(parseISO(date), 1), timeFormat);

  return `${startTime} - ${endTime}`;
};
