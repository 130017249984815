import React from 'react';
import { Flex, Box, Container, Text, Button } from '@chakra-ui/react';
import { Heading } from '@chakra-ui/react';

function CareerSuccess(): JSX.Element {
  return (
    <Box py="40px">
      <Container maxW="container.xl">
        <Flex height="80vh" flexDirection="column" alignItems="center" justifyContent="center">
          <Box textAlign="center">
            <Heading fontSize="42px" mb="15px">
              Thank you for your submission
            </Heading>
            <Text mb="50px">We will get back to you as soon as possible!</Text>
          </Box>
          <Button
            as={'a'}
            href={'/'}
            fontSize={16}
            fontWeight={600}
            px="50px"
            color="white"
            bg="tavisG.20"
            _hover={{
              bg: 'tavisG.30',
            }}
            _focus={{
              boxShadow: 'none',
            }}>
            Back to home
          </Button>
        </Flex>
      </Container>
    </Box>
  );
}

export default CareerSuccess;
