import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Flex } from '@chakra-ui/react';
import { getMyProfile } from 'src/redux/profile/actions';

interface DashboardShellProps {
  children: React.ReactNode;
}

function DashboardShell({ children }: DashboardShellProps): JSX.Element {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMyProfile());
  }, [dispatch]);

  return (
    <Flex maxH="100vh" overflow="hidden">
      <Flex
        overflow="hidden"
        h="96vh"
        w="100%"
        direction="column"
        borderRadius="10"
        bg="white"
        my="4">
        {children}
      </Flex>
    </Flex>
  );
}

export default DashboardShell;
