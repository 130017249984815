import { ContactusState } from './models';
import AppState from '../rootState';

const selectContactusState = (state: AppState): ContactusState => state.contactus;

export const selectSubmitFormStatus = (state: AppState): boolean | null =>
  selectContactusState(state).submitMessageStatus;

export const selectSubmitFormErrorMessage = (state: AppState): string | null =>
  selectContactusState(state).submitMessageErrorMessage;

export const selectSubmitFormLoading = (state: AppState): boolean | undefined =>
  selectContactusState(state).submitMessageLoading;
