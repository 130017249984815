import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function StudentsIcon(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <path
        d="M16.3636783,7.28544444 C18.2473498,7.2007082 19.7075323,5.57011563 19.6257067,3.64272222 C19.7075323,1.71532882 18.2473498,0.084736249 16.3636783,0 C14.4773743,0.0822466554 13.0128578,1.71260435 13.0914965,3.64272222 C13.0128578,5.57284009 14.4773743,7.20319779 16.3636783,7.28544444 L16.3636783,7.28544444 Z M7.63632168,7.28544444 C9.51999317,7.2007082 10.9801757,5.57011563 10.8983501,3.64272222 C10.9801832,1.71568167 9.52054991,0.0852410964 7.63724472,0 C5.75094075,0.0822466554 4.28642423,1.71260435 4.36506288,3.64272222 C4.28642423,5.57284009 5.75094075,7.20319779 7.63724472,7.28544444 L7.63632168,7.28544444 Z M7.63632168,9.71361111 C5.09426561,9.71361111 0,11.1302778 0,13.9636111 L0,17 L15.2726434,17 L15.2726434,13.9645556 C15.2726434,11.135 10.1783778,9.71455556 7.63724472,9.71455556 L7.63632168,9.71361111 Z M16.3636783,9.71361111 C16.0470751,9.71361111 15.687089,9.73816667 15.3058729,9.77405556 C16.6337234,10.7404139 17.4318549,12.2965165 17.4547133,13.9636111 L17.4547133,17 L24,17 L24,13.9645556 C24,11.135 18.9057344,9.71455556 16.3636783,9.71455556 L16.3636783,9.71361111 Z"
        fill="currentColor"
        fillRule="nonzero"
      ></path>
    </Icon>
  );
}
