import {
  GET_PURCHASE_DETAILS,
  GET_PURCHASE_DETAILS_SUCCESS,
  GET_PURCHASE_HISTORY,
  GET_PURCHASE_HISTORY_SUCCESS,
  RESET_PURCHASE_HISTORY,
  TERMINATE_SUBSCRIPTION,
  TERMINATE_SUBSCRIPTION_SUCCESS,
} from './constants';
import { PurchaseActionType } from './models/actions';
import { PurchaseHistoryState } from './models/states';

export const purchaseInitialState: PurchaseHistoryState = {
  purchaseHistory: null,
  purchaseHistoryLoading: false,

  purchaseDetails: null,
  purchaseDetailsLoading: false,

  terminateSubscriptionLoading: false,
  terminateSubscriptionSuccess: false,
};

export const purchaseReducer = (
  state = purchaseInitialState,
  action: PurchaseActionType,
): PurchaseHistoryState => {
  switch (action.type) {
    case GET_PURCHASE_HISTORY:
      return {
        ...state,
        purchaseHistoryLoading: true,
      };
    case GET_PURCHASE_HISTORY_SUCCESS:
      return {
        ...state,
        purchaseHistoryLoading: false,
        purchaseHistory: action.payload.data,
      };
    case GET_PURCHASE_DETAILS:
      return {
        ...state,
        purchaseDetailsLoading: true,
      };
    case GET_PURCHASE_DETAILS_SUCCESS:
      return {
        ...state,
        purchaseDetailsLoading: false,
        purchaseDetails: action.payload.data,
      };
    case TERMINATE_SUBSCRIPTION:
      return {
        ...state,
        terminateSubscriptionLoading: true,
      };
    case TERMINATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        terminateSubscriptionLoading: false,
        terminateSubscriptionSuccess: true,
      };
    case RESET_PURCHASE_HISTORY:
      return purchaseInitialState;
    default:
      return state;
  }
};
