import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Flex,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Input,
  Checkbox,
  CheckboxGroup,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { getFilterList } from 'src/redux/filter/actions';
import { selectFilterList } from 'src/redux/filter/selectors';
import { weekdays } from 'src/constants/weekdays';

interface Props {
  subjectFilter?: {
    selectedSubject: string[];
    setSelectedSubject: React.Dispatch<React.SetStateAction<string[]>>;
  };
  levelFilter?: {
    selectedLevel: string[];
    setSelectedLevel: React.Dispatch<React.SetStateAction<string[]>>;
  };
  languageFilter?: {
    selectedLanguage: string[];
    setSelectedLanguage: React.Dispatch<React.SetStateAction<string[]>>;
  };
  priceFilter?: {
    minPrice: string | null;
    setMinPrice: React.Dispatch<React.SetStateAction<string | null>>;
    maxPrice: string | null;
    setMaxPrice: React.Dispatch<React.SetStateAction<string | null>>;
  };
  weekdayFilter?: {
    selectedWeekday: string[];
    setSelectedWeekday: React.Dispatch<React.SetStateAction<string[]>>;
  };
}

function FilterTab({
  subjectFilter,
  levelFilter,
  priceFilter,
  languageFilter,
  weekdayFilter,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const filters = useSelector(selectFilterList);

  useEffect(() => {
    dispatch(getFilterList());
  }, [dispatch]);

  return (
    <Box w="100%">
      <Accordion
        as={Flex}
        pl="8"
        pt="8"
        pr={{
          base: '8',
          md: '0',
        }}
        direction="column"
        allowMultiple>
        {subjectFilter && (
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  {t('subject')}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <CheckboxGroup
                value={subjectFilter.selectedSubject}
                onChange={(value: string[]) => subjectFilter.setSelectedSubject(value)}>
                <Flex direction="column">
                  {filters?.subjects?.map((subject: any) => (
                    <Checkbox
                      key={subject.id}
                      value={String(subject.id)}
                      textTransform="capitalize">
                      {subject.name}
                    </Checkbox>
                  ))}
                </Flex>
              </CheckboxGroup>
            </AccordionPanel>
          </AccordionItem>
        )}

        {levelFilter && (
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  {t('level')}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <CheckboxGroup
                value={levelFilter.selectedLevel}
                onChange={(value: string[]) => levelFilter.setSelectedLevel(value)}>
                <Flex direction="column">
                  {filters?.forms?.map((form: any) => (
                    <Checkbox key={form} value={String(form)} textTransform="capitalize">
                      {form}
                    </Checkbox>
                  ))}
                </Flex>
              </CheckboxGroup>
            </AccordionPanel>
          </AccordionItem>
        )}

        {languageFilter && (
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  {t('language')}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <CheckboxGroup
                value={languageFilter.selectedLanguage}
                onChange={(value: string[]) => languageFilter.setSelectedLanguage(value)}>
                <Flex direction="column">
                  {filters?.languages?.map((language: any) => (
                    <Checkbox
                      key={language.id}
                      value={String(language.id)}
                      textTransform="capitalize">
                      {language.name}
                    </Checkbox>
                  ))}
                </Flex>
              </CheckboxGroup>
            </AccordionPanel>
          </AccordionItem>
        )}

        {priceFilter && (
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  {t('price')}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Flex justifyContent="space-between" alignItems="center">
                <Input
                  type="number"
                  step="0.01"
                  placeholder="RM"
                  w="full"
                  maxW="24"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    priceFilter.setMinPrice(e.target.value)
                  }
                />
                –
                <Input
                  type="number"
                  step="0.01"
                  placeholder="RM"
                  w="full"
                  maxW="24"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    priceFilter.setMaxPrice(e.target.value)
                  }
                />
              </Flex>
            </AccordionPanel>
          </AccordionItem>
        )}

        {weekdayFilter && (
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  {t('weekdays_word')}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <CheckboxGroup
                value={weekdayFilter.selectedWeekday}
                onChange={(value: string[]) => weekdayFilter.setSelectedWeekday(value)}
              >
                <Flex direction="column">
                  {weekdays.map((day) => (
                    <Checkbox key={day} value={day} textTransform="capitalize">
                      {t(day.toLowerCase())}
                    </Checkbox>
                  ))}
                </Flex>
              </CheckboxGroup>
            </AccordionPanel>
          </AccordionItem>
        )}
      </Accordion>
    </Box>
  );
}

export default FilterTab;
