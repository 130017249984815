import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { getCart, resetAddToCart } from 'src/redux/cart/actions';

function useAddToCart(
  addToCartStatusCode: number | null,
  addToCartErrorMessage: string | null,
  addToCartNetworkError: boolean,
): void {
  const dispatch = useDispatch();
  const toast = useToast();
  const { t } = useTranslation();

  useEffect(() => {
    if (addToCartStatusCode) {
      if (addToCartStatusCode === 200) {
        dispatch(getCart());
        setTimeout(() => dispatch(resetAddToCart()), 500);
        toast({
          title: t('added_to_cart'),
          position: 'top',
          status: 'success',
          isClosable: true,
          duration: 1500,
        });
      } else if (addToCartStatusCode !== 200) {
        setTimeout(() => dispatch(resetAddToCart()), 500);
        toast({
          title: addToCartErrorMessage,
          position: 'top',
          status: 'error',
          isClosable: true,
          duration: 1500,
        });
      } else if (addToCartNetworkError) {
        setTimeout(() => dispatch(resetAddToCart()), 500);
        toast({
          title: t('add_to_cart_error'),
          position: 'top',
          status: 'error',
          isClosable: true,
          duration: 1500,
        });
      }
    }
  }, [addToCartStatusCode, addToCartErrorMessage, addToCartNetworkError, dispatch, toast]);
}

export default useAddToCart;
