import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

import { QuestionBank } from 'src/models/cart/QuestionBank';
import { formatPrice } from 'src/utils/formatPrice';

interface QuestionBankItemProps {
  question: QuestionBank;
}

function QuestionBankItem({ question }: QuestionBankItemProps): JSX.Element {
  return (
    <Flex key={question.cartId} mt="4" borderBottomWidth="1px" borderBottomColor="white" pb="4">
      <Text w="70%" mr="5%">
        {question.questionBankName} - {question.form.name}
      </Text>
      <Text w="25%">
        {formatPrice(String(question.discountPrice ? question.discountPrice : question.price))}
      </Text>
    </Flex>
  );
}

export default QuestionBankItem;
