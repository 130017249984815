import { EventsQuery } from 'src/models/Event';
import { AppActions } from '../rootActions';
import { GET_EVENTS, GET_CLASSROOM_LINK, RESET_EVENTS } from './constants';

/**
 * Functions to be called in React components
 */

export const getEvents = (query: EventsQuery): AppActions => ({
  type: GET_EVENTS,
  payload: query,
});

export const resetEvents = (): AppActions => ({
  type: RESET_EVENTS,
});

export const getClassroomLink = (payload: { eventId: number }): AppActions => ({
  type: GET_CLASSROOM_LINK,
  payload: payload,
});
