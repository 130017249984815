import { CombinedState, combineReducers } from 'redux';
import { courseReducer } from './course/reducers';
import { eventReducer } from './event/reducers';
import { levelReducer } from './level/reducers';
import { authReducer } from './auth/reducers';
import { questionBankReducer } from './questionBank/reducers';
import { subjectReducer } from './subject/reducers';
import { profileReducer } from './profile/reducers';
import { filterReducer } from './filter/reducers';
import AppState from './rootState';
import { AppActions } from './rootActions';
import { LOGOUT } from './auth/constants';
import { careerReducer } from './career/reducers';
import { cartReducer } from './cart/reducers';
import { homeworkReducer } from './homework/reducers';
import { orderReducer } from './order/reducer';
import { purchaseReducer } from './purchase/reducer';
import { resourcesReducer } from './resources/reducers';
import { contactusReducer } from './contactus/reducers';
import { parentsNotificationReducer } from './parentsNotification/reducers';

const allReducers = combineReducers({
  course: courseReducer,
  questionBank: questionBankReducer,
  subject: subjectReducer,
  level: levelReducer,
  event: eventReducer,
  auth: authReducer,
  profile: profileReducer,
  filter: filterReducer,
  career: careerReducer,
  contactus: contactusReducer,
  cart: cartReducer,
  homework: homeworkReducer,
  order: orderReducer,
  purchase: purchaseReducer,
  resources: resourcesReducer,
  parentsNotification: parentsNotificationReducer,
});

const rootReducer = (state: AppState | undefined, action: AppActions): CombinedState<AppState> => {
  if (action.type === LOGOUT) {
    state = undefined;
  }
  return allReducers(state, action);
};

export default rootReducer;
