import AppState from '../rootState';
import { Purchase } from 'src/models/Purchase';
import { PurchaseHistoryState } from './models/states';
import { PurchaseDetails } from 'src/models/PurchaseDetails';

const selectPurchaseState = (state: AppState): PurchaseHistoryState => state.purchase;

export const selectPurchaseHistory = (state: AppState): Purchase[] | null => {
  const purchaseHistroy = selectPurchaseState(state).purchaseHistory;
  return purchaseHistroy ? filterPurchaseHistory(purchaseHistroy) : null;
};

const filterPurchaseHistory = (purchases: Purchase[]) =>
  // Filter order with paid status (3) and free trial status (5)
  purchases.filter((p) => p.orderStatus.id === '3' || p.orderStatus.id === '5');

export const selectPurchaseHistoryLoading = (state: AppState): boolean =>
  selectPurchaseState(state).purchaseHistoryLoading;

export const selectPurchaseDetails = (state: AppState): PurchaseDetails | null =>
  selectPurchaseState(state).purchaseDetails;

export const selectPurchaseDetailsLoading = (state: AppState): boolean =>
  selectPurchaseState(state).purchaseDetailsLoading;

export const selectTerminateSubscriptionLoading = (state: AppState): boolean =>
  selectPurchaseState(state).terminateSubscriptionLoading;

export const selectTerminateSubscriptionSuccess = (state: AppState): boolean =>
  selectPurchaseState(state).terminateSubscriptionSuccess;
