import React from 'react';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import { Link } from 'react-router-dom';
import { Flex, Image, Text, Badge, HStack, Box, LinkBox, LinkOverlay } from '@chakra-ui/react';

import { Course } from 'src/models/cart/Course';
import { formatPrice } from 'src/utils/formatPrice';
import { formatClassTime } from 'src/utils/formatClassTime';
import Rectangle from 'src/images/rectangle.png';

function PeopleAlsoBuyCard(course: Course): JSX.Element {
  const { t } = useTranslation();
  const hasDiscount = Boolean(course.discountPrice);

  return (
    <Flex
      as={LinkBox}
      w="100%"
      mb="4"
      borderBottomWidth="1px"
      borderBottomColor="gray.600"
      pb="4"
      flexDirection={{
        base: 'column',
        md: 'row',
      }}>
      <Flex
        justifyContent="center"
        mb={{
          base: 3,
          md: 0,
        }}>
        <Image
          src={course.courseImageUrl || Rectangle}
          minW="70px"
          minH="70px"
          w={{
            base: '150px',
            md: '70px',
          }}
          h={{
            base: '150px',
            md: '70px',
          }}
        />
      </Flex>
      <Box
        ml={{
          base: 0,
          md: 3,
        }}>
        <LinkOverlay as={Link} to={`/subjects/${course.id}`}>
          <Text fontSize={18} fontWeight="semibold" textTransform="capitalize">
            {course.courseName} - {course.form.name} - {format(parseISO(course.startDate), 'yyyy')}{' '}
            - Cikgu {course.teacher.firstname} {course.teacher.lastname}
          </Text>
        </LinkOverlay>
        <HStack mt="2" spacing="4" flexWrap="wrap">
          {course.isFeatured && (
            <Badge
              fontSize="xs"
              textAlign="center"
              fontWeight="normal"
              px="2"
              py="1"
              bg="yellow.200"
              textTransform="capitalize"
              borderRadius={5}>
              <Text>{t('featured')}</Text>
            </Badge>
          )}
          <Text fontSize="xs">Every {course.weekDays.join(', ')}</Text>
          <Text fontSize="xs">{formatClassTime(course.startDate)}</Text>
          {hasDiscount ? (
            <Flex>
              <Text textDecoration="line-through" mr="2">
                {formatPrice(String(course.price))}
              </Text>
              <Text color="red.500" fontWeight="medium">
                {formatPrice(String(course.discountPrice))}
              </Text>
            </Flex>
          ) : (
            <Text fontWeight="medium">{formatPrice(String(course.price))}</Text>
          )}
        </HStack>
      </Box>
    </Flex>
  );
}

export default PeopleAlsoBuyCard;
