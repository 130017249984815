import { ajax, AjaxError, AjaxResponse } from 'rxjs/ajax';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { combineEpics, ofType, ActionsObservable, Epic } from 'redux-observable';

import { API_URL } from 'src/constants/api';
import {
  GET_MY_PROFILE,
  GET_MY_PROFILE_NETWORK_ERROR,
  GET_MY_PROFILE_SUCCESS,
  GET_MY_PROFILE_ERROR,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_NETWORK_ERROR,
  UPDATE_PROFILE_ERROR,
  UPLOAD_PROFILE_IMAGE,
  UPLOAD_PROFILE_IMAGE_SUCCESS,
  UPLOAD_PROFILE_IMAGE_NETWORK_ERROR,
  UPLOAD_PROFILE_IMAGE_ERROR,
} from './constants';
import {
  GetMyProfileAction,
  GetMyProfileSuccessAction,
  ProfileResponse,
  UpdateProfileAction,
  UpdateProfileSuccessAction,
  UpdateProfileResponse,
  UploadProfileImageResponse,
} from './models';
import { of } from 'rxjs';
import { LOGOUT } from '../auth/constants';
import { ErrorResponse } from 'src/models/ErrorResponse';
import { UploadProfileImageAction, UploadProfileImageSuccessAction } from './models/actions';

function getProfileEpic(action$: ActionsObservable<GetMyProfileAction>) {
  return action$.pipe(
    ofType(GET_MY_PROFILE),
    switchMap(() => {
      return ajax({
        url: API_URL + '/student/profile',
        method: 'get',
        withCredentials: true,
      }).pipe(
        map((payload: AjaxResponse): GetMyProfileSuccessAction => {
          return {
            type: GET_MY_PROFILE_SUCCESS,
            payload: payload.response as ProfileResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: GET_MY_PROFILE_NETWORK_ERROR,
            });
          }
          return of({
            type: GET_MY_PROFILE_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function updateProfileEpic(action$: ActionsObservable<UpdateProfileAction>) {
  return action$.pipe(
    ofType(UPDATE_PROFILE),
    switchMap((action) => {
      return ajax({
        url: API_URL + `/student/${action.payload.id}`,
        method: 'put',
        withCredentials: true,
        body: {
          firstname: action.payload.firstname,
          lastname: action.payload.lastname,
          email: action.payload.email,
          force_update_password: false,
          mobile: action.payload.mobile,
          birthday: action.payload.birthday,
          gender: action.payload.gender,
        },
        headers: {
          'content-type': 'application/json;charset=UTF-8',
        },
      }).pipe(
        map((payload: AjaxResponse): UpdateProfileSuccessAction => {
          return {
            type: UPDATE_PROFILE_SUCCESS,
            payload: payload.response as UpdateProfileResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: UPDATE_PROFILE_NETWORK_ERROR,
            });
          }
          return of({
            type: UPDATE_PROFILE_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function uploadProfileImageEpic(action$: ActionsObservable<UploadProfileImageAction>) {
  return action$.pipe(
    ofType(UPLOAD_PROFILE_IMAGE),
    switchMap((action) => {
      const formData = new FormData();
      formData.append('email', action.payload.email);
      formData.append('file', action.payload.filepath);

      return ajax({
        url: API_URL + '/media/user',
        method: 'post',
        withCredentials: true,
        body: formData,
      }).pipe(
        map((payload: AjaxResponse): UploadProfileImageSuccessAction => {
          return {
            type: UPLOAD_PROFILE_IMAGE_SUCCESS,
            payload: payload.response as UploadProfileImageResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: UPLOAD_PROFILE_IMAGE_NETWORK_ERROR,
            });
          }
          return of({
            type: UPLOAD_PROFILE_IMAGE_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

export const profileEpics: Epic = combineEpics(
  ...[getProfileEpic, updateProfileEpic, uploadProfileImageEpic],
);
