import {
  CREATE_FREE_CLASS_ORDER,
  CREATE_FREE_CLASS_ORDER_ERROR,
  CREATE_FREE_CLASS_ORDER_NETWORK_ERROR,
  CREATE_FREE_CLASS_ORDER_SUCCESS,
  CREATE_ORDER,
  CREATE_ORDER_SUCCESS,
  RESET_ORDER,
} from './constants';
import { OrderActionType } from './models/actions';
import { OrderState } from './models/states';

export const orderInitialState: OrderState = {
  newOrder: null,
  createOrderLoading: false,
  createOrderPayload: null,

  createFreeClassOrderStatusCode: null,
  createFreeClassOrderLoading: false,
  createFreeClassOrderNetworkError: false,
};

export const orderReducer = (state = orderInitialState, action: OrderActionType): OrderState => {
  switch (action.type) {
    case CREATE_ORDER:
      return {
        ...state,
        createOrderLoading: true,
        createOrderPayload: action.payload,
      };
    case CREATE_ORDER_SUCCESS:
      return {
        ...state,
        createOrderLoading: false,
        newOrder: action.payload.data,
      };
    case CREATE_FREE_CLASS_ORDER:
      return {
        ...state,
        createFreeClassOrderStatusCode: null,
        createFreeClassOrderLoading: true,
        createFreeClassOrderNetworkError: false,
      };
    case CREATE_FREE_CLASS_ORDER_SUCCESS:
      return {
        ...state,
        createFreeClassOrderStatusCode: action.payload.code,
        createFreeClassOrderLoading: false,
      };
    case CREATE_FREE_CLASS_ORDER_ERROR:
      return {
        ...state,
        createFreeClassOrderStatusCode: action.payload.code,
        createFreeClassOrderLoading: false,
      };
    case CREATE_FREE_CLASS_ORDER_NETWORK_ERROR:
      return {
        ...state,
        createFreeClassOrderLoading: false,
        createFreeClassOrderNetworkError: true,
      };
    case RESET_ORDER:
      return orderInitialState;
    default:
      return state;
  }
};
