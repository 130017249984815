import { AppActions } from '../rootActions';
import {
  ADD_TO_CART,
  GET_CART,
  DELETE_CART_ITEM,
  GET_PEOPLE_ALSO_BUY,
  RESET_ADD_TO_CART,
  RESET_PEOPLE_ALSO_BUY,
  GET_CUT_OFF_DATE,
  APPLY_PROMO_CODE,
  RESET_PROMO_CODE,
} from './constants';
import { AddToCartPayload, PeopleAlsoBuyPayload } from './models/payload';

/**
 * Functions to be called in React components
 */
export const addToCart = (payload: AddToCartPayload): AppActions => ({
  type: ADD_TO_CART,
  payload,
});

export const resetAddToCart = (): AppActions => {
  return { type: RESET_ADD_TO_CART };
};

export const getCart = (date?: string): AppActions => ({
  type: GET_CART,
  date,
});

export const getPeopleAlsoBuy = (payload: PeopleAlsoBuyPayload): AppActions => ({
  type: GET_PEOPLE_ALSO_BUY,
  payload,
});

export const resetPeopleAlsoBuy = (): AppActions => ({
  type: RESET_PEOPLE_ALSO_BUY,
});

export const deleteCartItem = (id: string): AppActions => ({
  type: DELETE_CART_ITEM,
  id,
});

export const getCutOffDate = (): AppActions => ({
  type: GET_CUT_OFF_DATE,
});

export const applyPromoCode = (code: string): AppActions => ({
  type: APPLY_PROMO_CODE,
  code,
});

export const resetPromoCode = (): AppActions => ({
  type: RESET_PROMO_CODE,
});
