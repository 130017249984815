import React from 'react';
import { Text, Stack, Image, } from '@chakra-ui/react';

function Profile({ image, name, subjects, description }: any) {
  return (
    <Stack
      spacing="25px"
      borderRadius="10px"
      boxShadow="0 0 5px gray"
      p="30px"
      maxW="500px"
      mt={{ base: '50px', md: '80px' }}
      mr={{ base: 0, md: '20px' }}
      align="center"
    >
      <Image src={image} w="200px" h="200px" borderRadius="full" />
      <Text fontSize="20px" fontWeight="bold" textAlign="center">{name}</Text>
      <Text fontSize="16px" fontWeight="medium" lineHeight="24px" textAlign="center" whiteSpace="pre-wrap">
        {subjects}
      </Text>
      {/* <Text fontSize="15px" fontWeight="bold" lineHeight="20px">
        Qualifications: 
        {qualifications.map((qualification: any) => (
          <Text as="span" fontWeight="medium" whiteSpace="pre-wrap">{'\n'}{qualification}</Text>
        ))}
      </Text>
      <Box fontSize="15px" fontWeight="bold" lineHeight="20px">
        Teaching Experience: 
        <ul>
          {teachingExperience.map((experience: any) => (
            <li>
              <Text as="span" fontWeight="medium" whiteSpace="pre-wrap">{experience}{'\n'}</Text>
            </li>
          ))}
        </ul>
      </Box> */}
      <Text fontSize="16px" fontWeight="medium" lineHeight="24px" textAlign="justify">
        {description}
      </Text>
    </Stack>
  );
}

export default Profile;