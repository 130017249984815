import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store, persistor } from 'src/redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { Helmet } from 'react-helmet';
import '../i18n';
import { Img } from '@chakra-ui/react';

import Navbar from '../components/Web/Navbar/Navbar';
import Footer from '../components/Web/Footer/Footer';
import ProtectedRoute from '../components/ProtectedRoute';
import Homepage from './Web/Homepage/Homepage';
import Login from './Login/Login';
import Register from './Register/Register';
import EmailVerification from './EmailVerification/EmailVerification';
import Courses from './Courses/Courses';
import CourseDetails from './CourseDetails/CourseDetails';
import TermsOfService from './Web/TermsOfService/TermsOfService';
import PrivacyPolicy from './Web/PrivacyPolicy/PrivacyPolicy';
import CareerForm from './Web/Career/CareerForm';
import Timetable from './Timetable/Timetable';
import Profile from './Profile/Profile';
import CareerSuccess from './Web/Career/CareerSuccess';
import MyPurchases from './MyPurchases/MyPurchases';
import MyPurchaseDetails from './MyPurchaseDetails/MyPurchaseDetails';
import Prepaid from './Prepaid/Prepaid';
import Subscription from './Subscription/Subscription';
import BillingInfo from './BillingInfo/BillingInfo';
import PageNotFound from '../components/PageNotFound';
import AppleSigninLoader from '../components/SocialLogin/AppleSigninLoader';
import VirtualClassroom from './VirtualClassroom/VirtualClassroom';
import Contactus from './Web/Contactus/Contactus';
import ContactusSuccess from './Web/Contactus/ContactusSuccess';
import Onboarding from './Web/Homepage/Onboarding';
import Whatsapp from 'src/images/Whatsapp.png';
import Career from './Web/Career/Career';
import AboutUs from './Web/AboutUs/AboutUs';
// import PricingPlan from './Web/PricingPlan/PricingPlan';
import OurTutor from './Web/OurTutor/OurTutor';

function App(): JSX.Element {
  const linkToWhatsapp = () => {
    window.open("https://wa.me/60106536780");
  };

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Helmet>
          <meta property="og:url" content={window.location.href} />
          <title>Tavis – Malaysia’s Preferred Online Tuition Platform</title>
          <meta name="Tavis" content="Tavis – Malaysia’s Preferred Online Tuition Platform" />
          <meta name="description" content="Think Tuition, Think Tavis! We provide accessible and affordable quality education to every student around Malaysia with live classes, game-like quizzes, exams, progress analysis, learning rewards, and more!" />
        </Helmet>
        <Navbar />
        <Img
          cursor="pointer"
          onClick={linkToWhatsapp}
          src={Whatsapp}
          h="74px"
          w="74px"
          position="fixed"
          right={{ base: '20px', md: '50px' }}
          top="85%"
          zIndex="3"
        />
        <Router>
          <Switch>

            <Route path="/" exact render={() =>
              window.location.href = 'https://www.tavis.live/'
            } />

            {/* <Route path="/" exact component={Homepage} /> */}

            <Route path="/signin" exact component={Login} />
            {/* Note: AppleSigninLoader component is where code value been appended in url after redirect from Apple's signin page. /apple-login used to match endpoint set up from api */}
            <Route path="/apple-login" exact component={AppleSigninLoader} />
            <Route path="/register" exact component={Register} />
            <Route path="/email-verification" exact component={EmailVerification} />
            <Route path="/onboarding" exact component={Onboarding} />
            {/* Note: Pathname for course and course details should be '/classes' and '/classes/:id' */}
            <Route path="/classes" exact component={Courses} />
            <Route path="/classes/:id" exact component={CourseDetails} />
            <Route path="/terms-of-service" exact component={TermsOfService} />
            <Route path="/privacy-policy" exact component={PrivacyPolicy} />
            <Route path="/career" exact component={Career} />
            <Route path="/career/form" exact component={CareerForm} />
            <Route path="/career/success" exact component={CareerSuccess} />
            <Route path="/contact-us" exact component={Contactus} />
            <Route path="/contact-us/success" exact component={ContactusSuccess} />
            <Route path="/about-us" exact component={AboutUs} />
            {/* <Route path="/pricing-plan" exact component={PricingPlan} /> */}
            <Route path="/tutors" exact component={OurTutor} />
            <Route path="/about-us" exact component={AboutUs} />
            <ProtectedRoute path="/dashboard" exact component={Timetable} />
            <ProtectedRoute path="/my-purchases" exact component={MyPurchases} />
            <ProtectedRoute path="/my-purchases/:id" exact component={MyPurchaseDetails} />
            <ProtectedRoute path="/prepaid" exact component={Prepaid} />
            <ProtectedRoute path="/subscription" exact component={Subscription} />
            <ProtectedRoute path="/checkout/billing-info" component={BillingInfo} />
            <ProtectedRoute path="/virtual-class/:eventId" exact component={VirtualClassroom} />
            <ProtectedRoute path="/profile" exact component={Profile} />

            <Route component={PageNotFound} />
          </Switch>
        </Router>
        <Footer />
      </PersistGate>
    </Provider>
  );
}

export default App;
