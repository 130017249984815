import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import {
  Box,
  Flex,
  Image,
  Text,
  LinkBox,
  LinkOverlay,
  Spacer,
  AspectRatio,
} from '@chakra-ui/react';

import { formatPrice } from 'src/utils/formatPrice';
import { CourseListItem } from 'src/models/CourseListItem';
import TavisPlaceholder from 'src/images/TavisPlaceholder.svg';

function CourseCard(props: CourseListItem, ref: React.LegacyRef<HTMLDivElement>): JSX.Element {
  const {
    id,
    courseName,
    price,
    discountPrice,
    backgroundImageUrl,
    startDate,
    endDate,
    teacher,
    weekDays,
    isFeatured,
    color,
  } = props;

  const { t } = useTranslation();

  const hasPromo = Boolean(discountPrice);

  return (
    <LinkBox
      as={Flex}
      flexDirection="column"
      mx="auto"
      rounded="lg"
      w="full"
      bg={color}
      boxShadow="lg"
      ref={ref}>
      <Box position="relative">
        <Box
          px="2"
          mt="8"
          position="absolute"
          bg="#ffee00"
          textTransform="capitalize"
          fontWeight="normal"
          visibility={isFeatured ? 'unset' : 'hidden'}
          borderRightRadius="md"
          zIndex="2">
          {t('featured')}
        </Box>
        <AspectRatio ratio={16 / 9}>
          <Image
            w="full"
            h="150px"
            fit="cover"
            src={backgroundImageUrl}
            roundedTop="lg"
            bg="white"
            fallbackSrc={TavisPlaceholder}
          />
        </AspectRatio>
      </Box>
      <Flex direction="column" py={4} px={8} h="full" zIndex="2">
        <Flex alignItems="flex-end" mb="8">
          <Image
            bg="white"
            w={20}
            h={20}
            mt={-16}
            fit="scale-down"
            rounded="full"
            boxShadow="md"
            src={teacher.imageUrl}
            fallbackSrc={TavisPlaceholder}
          />

          <Text fontSize="sm" ml="8">
            #{id}
          </Text>
        </Flex>

        <LinkOverlay as="a" href={`/classes/${id}`}>
          <Text as={Box} fontSize="lg" textAlign="center" fontWeight="medium">
            <Text noOfLines={2} textTransform="capitalize">
              {courseName}
            </Text>
          </Text>
        </LinkOverlay>

        <Text mt="4" fontSize="sm" textAlign="center" fontWeight="medium">
          {t('every')}{' '}
          {weekDays.map((day, index) => {
            if (weekDays.length - 1 > index) {
              return `${t(day.toLowerCase())}, `;
            } else {
              return t(day.toLowerCase());
            }
          })}
        </Text>
        <Text fontSize="sm" textAlign="center" fontWeight="medium">
          {`${format(new Date(startDate), 'p')} - ${format(new Date(endDate), 'p')}`}
        </Text>
        <Spacer />
        <Flex alignItems="flex-end" justifyContent="space-between" mt={8}>
          <Text textTransform="capitalize">{`Cikgu ${teacher.firstname} ${teacher.lastname}`}</Text>
          {hasPromo ? (
            <Flex direction="column" alignItems="flex-end">
              <Text textDecoration="line-through">{formatPrice(price)}</Text>
              <Text>{formatPrice(discountPrice)}</Text>
            </Flex>
          ) : (
            <Flex direction="column" alignItems="flex-end">
              <Text visibility="hidden">{formatPrice(price)}</Text>
              <Text>{formatPrice(price)}</Text>
            </Flex>
          )}
        </Flex>
      </Flex>
    </LinkBox>
  );
}

export default forwardRef(CourseCard);
