import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Flex, Spinner, Text } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { loginWithApple } from 'src/redux/auth/actions';
import { selectLoginStatus } from 'src/redux/auth/selectors';
const queryString = require('query-string');

function AppleLoginLoader(): JSX.Element {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const loginStatus = useSelector(selectLoginStatus);

  // Extract code from query parameter in redirect url
  const url = queryString.parse(search);

  useEffect(() => {
    // Wait 1s before make login request using Apple details
    const bufferDispatch = setTimeout(() => {
      if (url.code) {
        dispatch(loginWithApple({ code: url.code!, role: 'student' }));
      }
    }, 1000);
    return () => clearTimeout(bufferDispatch);
  });

  useEffect(() => {
    if (loginStatus) {
      history.push('/');
    }
  }, [history, loginStatus]);

  return (
    <Flex w="full" h="100vh" justifyContent="center" alignItems="center" direction="column">
      <Text mb="20px">Apple login redirect...</Text>
      <Spinner />
    </Flex>
  );
}

export default AppleLoginLoader;
