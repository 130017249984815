import { AppActions } from '../rootActions';
import {
  GET_PURCHASE_DETAILS,
  GET_PURCHASE_HISTORY,
  RESET_PURCHASE_HISTORY,
  TERMINATE_SUBSCRIPTION,
} from './constants';

/**
 * Functions to be called in React components
 */
export const getPurchaseHistory = (): AppActions => ({ type: GET_PURCHASE_HISTORY });

export const getPurhcaseDetails = (id: number): AppActions => ({ type: GET_PURCHASE_DETAILS, id });

export const terminateSubscription = (refNo: string): AppActions => ({
  type: TERMINATE_SUBSCRIPTION,
  refNo,
});

export const resetPurchaseHistory = (): AppActions => ({ type: RESET_PURCHASE_HISTORY });
