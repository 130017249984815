export const Section1 = {
  title: 'At Tavis',
  paragraph:
    "We aspire to provide accessible and affordable quality education to every student around Malaysia, With our integrated online tuition platform, we are here to support your child’s learning journey by enhancing student’s learning experience and absorption, with everything from live classes to live quizzes, recorded classes to question banks, progress reports to detailed analysis focusing on student’s attention monitoring, progress, strength and weaknesses. \n\nWith our robust Learning Management System (LMS), students are able to access countless study materials for revision and continuous improvements. Our classes are planned and designed based on the diverse individual needs of learning, creating the flexibility for each learner to progress well at their own pace. Be part of the Tavis Community today! Think Tuition, Think Tavis!",
  items: ['Live Classes', 'Learning Materials', 'Question Banks', 'Recorded Classes', 'Progress Reports'],
};

export const Section2 = {
  title: 'Our Vision',
  paragraph: 'To be the go-to online centre for education of all kinds',
};

export const Section3 = {
  title: 'Our Mission',
  paragraph: 'To allow everyone to access the best education from any corner of the country',
};
