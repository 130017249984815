import React from 'react';
import { Box, Container, Text, Heading, Stack, Flex, Img } from '@chakra-ui/react';
import { Section1, Section2, Section3 } from 'src/data/aboutus';
import TavisLogo from 'src/images/tavis_logo.png';

function AboutUs() {
  return (
    <Box py={{ base: '40px', md: '125px' }}>
      <Container maxW={'7xl'}>
        {/* Section 1 */}
        <Stack m="auto" maxW="80%" align="center" justify="center" spacing="50px" mb="100px">
          <Img src={TavisLogo} h="149px" w="149px" />
          <Heading as="h2" fontSize="44px" fontWeight="bold" textAlign="center">
            {Section1.title}
          </Heading>
          <Text fontSize="24px" fontWeight="medium" whiteSpace="pre-wrap" textAlign="justify" color="tavisGy.20">
            {Section1.paragraph}
          </Text>
          <Flex flexDirection={{ base: 'column', lg: 'row' }} flexWrap="wrap" justifyContent="center">
            {Section1.items.map((item) => (
              <Box
                mt="20px"
                mr="20px"
                fontSize="20px"
                fontWeight="bold"
                bg="tavisG.20"
                borderRadius="10px"
                color="white"
                p={5}
                whiteSpace="nowrap"
              >
                {item}
              </Box>
            ))}
          </Flex>
        </Stack>
        {/* Section 2 */}
        <Stack m="auto" maxW="80%" align="center" justify="center" spacing="20px" mb="100px">
          <Heading as="h2" fontSize="40px" fontWeight="bold">
            {Section2.title}
          </Heading>
          <Text fontSize="24px" fontWeight="medium" textAlign="center" color="tavisGy.20">
            {Section2.paragraph}
          </Text>
        </Stack>
        {/* Section 3 */}
        <Stack m="auto" maxW="80%" align="center" justify="center" spacing="20px" mb="100px">
          <Heading as="h2" fontSize="40px" fontWeight="bold">
            {Section3.title}
          </Heading>
          <Text fontSize="24px" fontWeight="medium" textAlign="center" color="tavisGy.20">
            {Section3.paragraph}
          </Text>
        </Stack>
      </Container>
   </Box>
  );
}

export default AboutUs;