export const GET_MY_PROFILE = 'profile/getMyProfile';
export const GET_MY_PROFILE_SUCCESS = 'profile/getMyProfileSuccess';
export const GET_MY_PROFILE_NETWORK_ERROR = 'profile/getMyProfileNetworkError';
export const GET_MY_PROFILE_ERROR = 'profile/getMyProfileError';

export const UPDATE_PROFILE = 'profile/updateProfile';
export const UPDATE_PROFILE_SUCCESS = 'profile/updateProfileSuccess';
export const UPDATE_PROFILE_ERROR = 'profile/updateProfileError';
export const UPDATE_PROFILE_NETWORK_ERROR = 'profile/updateProfileNetworkError';

export const UPLOAD_PROFILE_IMAGE = 'profile/uploadProfileImage';
export const UPLOAD_PROFILE_IMAGE_SUCCESS = 'profile/uploadProfileImageSuccess';
export const UPLOAD_PROFILE_IMAGE_ERROR = 'profile/uploadProfileImageError';
export const UPLOAD_PROFILE_IMAGE_NETWORK_ERROR = 'profile/uploadProfileImageNetworkError';
