import { IS_DEV } from './environment';

// API Endpoints
const DEV_API = 'https://api-staging.tavis.live/api/v1/server.php';
const PRODUCTION_API = 'https://api.tavis.live/api/v1/server.php';

export const API_URL = IS_DEV ? DEV_API : PRODUCTION_API;

// Payment Endpoints
const DEV_IPAY = 'https://api-staging.tavis.live/ipay88/payment.php';
const PROD_IPAY = 'https://api.tavis.live/ipay88/payment.php';

export const PAYMENT_URL = IS_DEV ? DEV_IPAY : PROD_IPAY;

// Download Links
// Windows Build
export const WIN_DEV_DL =
  'https://tavis-desktop-build.s3.ap-southeast-1.amazonaws.com/Development_desktop_TAVIS-1.0.0.exe';
export const WIN_PROD_DL = 'https://tavis-build.s3.ap-southeast-1.amazonaws.com/TAVIS.exe';
// Mac Build
export const MAC_DEV_DL =
  'https://tavis-desktop-build.s3.ap-southeast-1.amazonaws.com/Development_mac_TAVIS-1.0.0.dmg';
export const MAC_PROD_DL = 'https://tavis-build.s3.ap-southeast-1.amazonaws.com/TAVIS.dmg';
