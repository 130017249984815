import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function SaveIcon(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <g id="Save_icon" data-name="Save icon" transform="translate(-3.5 -3.5)">
        <path
          id="Path_1133"
          data-name="Path 1133"
          d="M13.5,13.778l3.158,3.333L27.184,6"
          transform="translate(-2.684 -0.389)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_1134"
          data-name="Path 1134"
          d="M23.447,14.5v7.778A2.166,2.166,0,0,1,21.342,24.5H6.605A2.166,2.166,0,0,1,4.5,22.278V6.722A2.166,2.166,0,0,1,6.605,4.5H18.184"
          transform="translate(0 0)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </Icon>
  );
}
