import { combineEpics } from 'redux-observable';
import { courseEpics } from './course/epics';
import { eventEpics } from './event/epics';
import { levelEpics } from './level/epics';
import { authEpics } from './auth/epics';
import { questionBankEpics } from './questionBank/epics';
import { subjectEpics } from './subject/epics';
import { profileEpics } from './profile/epics';
import { filterEpics } from './filter/epics';
import { careerEpics } from './career/epics';
import { cartEpics } from './cart/epics';
import { homeworkEpics } from './homework/epics';
import { orderEpics } from './order/epics';
import { purhcaseEpic } from './purchase/epics';
import { resourcesEpics } from './resources/epics';
import { contactusEpics } from './contactus/epics';
import { parentsNotificationEpics } from './parentsNotification/epics';

const epics = [
  courseEpics,
  questionBankEpics,
  subjectEpics,
  levelEpics,
  authEpics,
  eventEpics,
  profileEpics,
  filterEpics,
  careerEpics,
  contactusEpics,
  cartEpics,
  homeworkEpics,
  orderEpics,
  purhcaseEpic,
  resourcesEpics,
  parentsNotificationEpics,
];
const rootEpic = combineEpics(...epics);

export default rootEpic;
