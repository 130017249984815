import { ajax, AjaxResponse } from 'rxjs/ajax';
import { map, switchMap } from 'rxjs/operators';
import { combineEpics, ofType, ActionsObservable, Epic } from 'redux-observable';

import { API_URL } from 'src/constants/api';
import {
  GET_PURCHASE_DETAILS,
  GET_PURCHASE_DETAILS_SUCCESS,
  GET_PURCHASE_HISTORY,
  GET_PURCHASE_HISTORY_SUCCESS,
  TERMINATE_SUBSCRIPTION,
  TERMINATE_SUBSCRIPTION_SUCCESS,
} from './constants';
import {
  PurchaseDetailsAction,
  PurchaseDetailsSuccessAction,
  PurchaseHistoryAction,
  PurchaseHistorySuccessAction,
  TerminateSubscriptionAction,
  TerminateSubscriptionSuccessAction,
} from './models/actions';
import {
  PurchaseDetailsResponse,
  PurchaseHistoryResponse,
  TerminateSubscriptionResponse,
} from './models/responses';
import { IS_DEV } from 'src/constants/environment';

function getPurchaseHistoryEpic(action$: ActionsObservable<PurchaseHistoryAction>) {
  return action$.pipe(
    ofType(GET_PURCHASE_HISTORY),
    switchMap(() => {
      return ajax({
        url: API_URL + '/order/getAllPurchased',
        method: 'get',
        withCredentials: true,
      }).pipe(
        map((payload: AjaxResponse): PurchaseHistorySuccessAction => {
          return {
            type: GET_PURCHASE_HISTORY_SUCCESS,
            payload: payload.response as PurchaseHistoryResponse,
          };
        }),
      );
    }),
  );
}

function getPurchaseDetailsEpic(action$: ActionsObservable<PurchaseDetailsAction>) {
  return action$.pipe(
    ofType(GET_PURCHASE_DETAILS),
    switchMap((action) => {
      return ajax({
        url: `${API_URL}/order/getDetailPurchased?orderid=${action.id}`,
        method: 'get',
        withCredentials: true,
      }).pipe(
        map((payload: AjaxResponse): PurchaseDetailsSuccessAction => {
          return {
            type: GET_PURCHASE_DETAILS_SUCCESS,
            payload: payload.response as PurchaseDetailsResponse,
          };
        }),
      );
    }),
  );
}

function terminateSubscriptionEpic(action$: ActionsObservable<TerminateSubscriptionAction>) {
  return action$.pipe(
    ofType(TERMINATE_SUBSCRIPTION),
    switchMap((action) => {
      return ajax({
        url: `${API_URL}/order/terminate`,
        method: 'post',
        withCredentials: true,
        headers: { 'content-type': 'application/json;charset=UTF-8' },
        body: {
          refNo: action.refNo,
          testMode: IS_DEV,
        },
      }).pipe(
        map((payload: AjaxResponse): TerminateSubscriptionSuccessAction => {
          return {
            type: TERMINATE_SUBSCRIPTION_SUCCESS,
            payload: payload.response as TerminateSubscriptionResponse,
          };
        }),
      );
    }),
  );
}

const purchaseEpicsArray = [
  getPurchaseHistoryEpic,
  getPurchaseDetailsEpic,
  terminateSubscriptionEpic,
];

export const purhcaseEpic: Epic = combineEpics(...purchaseEpicsArray);
