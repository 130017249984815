import {
  Button,
  Divider,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  PinInput,
  PinInputField,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { confirmVerificationCode, resendVerificationCode, clearVerificationStatus } from 'src/redux/auth/actions';
import { selectConfirmVerificationCodeLoading, selectConfirmVerificationCodeMessage, selectConfirmVerificationCodeStatusCode, selectResendVerificationCodeMessage, selectResendVerificationCodeStatusCode } from 'src/redux/auth/selectors';
import { selectProfile } from 'src/redux/profile/selectors';
import { getButtonStatus } from 'src/utils/getButtonStatus';

function VerifyStudentEmailPrompt() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [disableButton, setDisableButton] = useState(true);
  const { t } = useTranslation();
  const profile = useSelector(selectProfile);

  const dispatch = useDispatch();
  const toast = useToast();

  const confirmVerificationCodeLoading = useSelector(selectConfirmVerificationCodeLoading);
  const confirmVerificationCodeMessage = useSelector(selectConfirmVerificationCodeMessage);
  const confirmVerificationCodeStatusCode = useSelector(selectConfirmVerificationCodeStatusCode);

  const resendVerificationCodeMessage = useSelector(selectResendVerificationCodeMessage);
  const resendVerificationCodeStatusCode = useSelector(selectResendVerificationCodeStatusCode);

  const [verificationCode, setVerificationCode] = useState('');

  useEffect(() => {
    if (
      resendVerificationCodeMessage &&
      resendVerificationCodeStatusCode === 200 &&
      resendVerificationCodeMessage
    ) {
      toast({
        position: 'top',
        status: 'info',
        title: resendVerificationCodeMessage,
      });
    } else if (resendVerificationCodeMessage) {
      toast({
        position: 'top',
        status: 'error',
        title: resendVerificationCodeMessage,
      });
    }
  }, [resendVerificationCodeMessage, resendVerificationCodeStatusCode]);

  useEffect(() => {
    if (confirmVerificationCodeStatusCode === 200) {
      onClickClose();
    } else if (confirmVerificationCodeMessage) {
      toast({
        position: 'top',
        status: 'error',
        title: confirmVerificationCodeMessage,
      });
    }
  }, [confirmVerificationCodeMessage, confirmVerificationCodeStatusCode]);

  const onCompleteVerification = (value: string) => {
    setVerificationCode(value);
  };

  const onClickVerifyCode = () => {
    if (profile) {
      dispatch(
        confirmVerificationCode({
          email: profile?.email,
          code: verificationCode,
        }),
      );
    }
  };

  const onClickResendCode = () => {
    onOpen();
    if (profile) {
      dispatch(
        resendVerificationCode({
          email: profile.email,
        }),
      );
    }
  };

  const onClickClose = () => {
    dispatch(clearVerificationStatus());
    onClose();
  };

  return (
    <>
      <Text fontWeight="medium" textDecor="underline" cursor="pointer" onClick={onClickResendCode}>
        {t('resend_2')}
      </Text>
      <Modal
        isOpen={isOpen}
        onClose={onClickClose}
        size="3xl"
        isCentered
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="26px" fontWeight="semibold" color="#65CD7D">
            {t('verification')}
            <Divider borderColor="#595959" mt={3} />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody fontSize={{ base: '16px', md: '20px' }}>
            <Trans i18nKey={'email_verification_instruction_2'} values={{ email: profile?.email }}>
              Please enter the verification code sent to
              <Text as="span" fontWeight="semibold"></Text>
              to continue using our platform.
            </Trans>
            <Stack direction="column" spacing={10} my={10}>
              <Text fontSize={{ base: '16px', md: '18px' }} fontWeight="semibold">
                {t('verification_code')}
              </Text>
              <HStack spacing={{ base: '20px', md: '68px'}}>
                <PinInput
                  type="number"
                  placeholder=""
                  autoFocus={true}
                  onChange={(value) => setDisableButton(getButtonStatus(value))}
                  onComplete={onCompleteVerification}
                >
                  <PinInputField
                    backgroundColor="white"
                    color="black"
                    w={{
                      base: '30px',
                      sm: '60px',
                    }}
                    h={{
                      base: '30px',
                      sm: '60px',
                    }}
                    fontSize={{
                      base: '16px',
                      sm: '30px',
                    }}
                    fontWeight="bold"
                  />
                  <PinInputField
                    backgroundColor="white"
                    color="black"
                    w={{
                      base: '30px',
                      sm: '60px',
                    }}
                    h={{
                      base: '30px',
                      sm: '60px',
                    }}
                    fontSize={{
                      base: '16px',
                      sm: '30px',
                    }}
                    fontWeight="bold"
                  />
                  <PinInputField
                    backgroundColor="white"
                    color="black"
                    w={{
                      base: '30px',
                      sm: '60px',
                    }}
                    h={{
                      base: '30px',
                      sm: '60px',
                    }}
                    fontSize={{
                      base: '16px',
                      sm: '30px',
                    }}
                    fontWeight="bold"
                  />
                  <PinInputField
                    backgroundColor="white"
                    color="black"
                    w={{
                      base: '30px',
                      sm: '60px',
                    }}
                    h={{
                      base: '30px',
                      sm: '60px',
                    }}
                    fontSize={{
                      base: '16px',
                      sm: '30px',
                    }}
                    fontWeight="bold"
                  />
                  <PinInputField
                    backgroundColor="white"
                    color="black"
                    w={{
                      base: '30px',
                      sm: '60px',
                    }}
                    h={{
                      base: '30px',
                      sm: '60px',
                    }}
                    fontSize={{
                      base: '16px',
                      sm: '30px',
                    }}
                    fontWeight="bold"
                  />
                  <PinInputField
                    backgroundColor="white"
                    color="black"
                    w={{
                      base: '30px',
                      sm: '60px',
                    }}
                    h={{
                      base: '30px',
                      sm: '60px',
                    }}
                    fontSize={{
                      base: '16px',
                      sm: '30px',
                    }}
                    fontWeight="bold"
                  />
                </PinInput>
              </HStack>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              isLoading={confirmVerificationCodeLoading}
              disabled={disableButton}
              onClick={onClickVerifyCode}
              bg="#1BC35C"
              color="white"
              _hover={{ bg: '#00912F' }}
              _active={{ bg: '#00912F' }}
            >
              {t('verify')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default VerifyStudentEmailPrompt;
