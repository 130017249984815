import React from 'react';
import { Heading, IconButton, HStack } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import RightTopbar from './RightTopbar';
import { BackIcon } from 'src/icons';

interface BackTopbarProps {
  title: string;
  to?: string;
  isWithSearchbar?: boolean;
}

function BackTopbar({ title, to, isWithSearchbar = true }: BackTopbarProps): JSX.Element {
  const history = useHistory();

  const onClickBack = () => {
    if (to) {
      history.push(to);
    } else {
      history.goBack();
    }
  };

  return (
    <RightTopbar isSearchable={isWithSearchbar}>
      <HStack spacing={5} mb="12px">
        <IconButton aria-label="Back" icon={<BackIcon />} bg="white" onClick={onClickBack} />
        <Heading
          fontSize={{
            base: 24,
            md: 30,
          }}
          textTransform="capitalize"
          fontWeight="semibold">
          {title}
        </Heading>
      </HStack>
    </RightTopbar>
  );
}

export default BackTopbar;
