import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function FacebookIcon(props: IconProps): JSX.Element {
  return (
    <Icon viewBox="0 0 512 512" {...props}>
      <g>
        <g>
          <path
            d="M448,0H64C28.704,0,0,28.704,0,64v384c0,35.296,28.704,64,64,64h192V336h-64v-80h64v-64c0-53.024,42.976-96,96-96h64v80
			h-32c-17.664,0-32-1.664-32,16v64h80l-32,80h-48v176h96c35.296,0,64-28.704,64-64V64C512,28.704,483.296,0,448,0z"
            fill="currentColor"
          />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </Icon>
  );
}
