import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDisclosure } from '@chakra-ui/hooks';
import { Text } from '@chakra-ui/layout';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import { Button } from '@chakra-ui/button';
import { forgetPassword } from 'src/redux/auth/actions';
import { useDispatch } from 'react-redux';
import { Input } from '@chakra-ui/input';

function ForgotPassword(): JSX.Element {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');

  const onSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(forgetPassword({ email }));
    onClose();
  };

  return (
    <>
      <Text
        fontSize={14}
        onClick={onOpen}
        cursor="pointer"
        _hover={{ textDecoration: 'underline' }}>
        {t('forgot_password')}
      </Text>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent as="form" onSubmit={onSubmit} mx="12px">
          <ModalHeader>Forgot password?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Please enter your email</Text>
            <Input
              required
              mt="2"
              size="sm"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </ModalBody>

          <ModalFooter>
            <Button size="sm" variant="ghost" mr="3" onClick={onClose}>
              Cancel
            </Button>
            <Button size="sm" colorScheme="blue" type="submit">
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ForgotPassword;
