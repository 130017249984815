import { ContactusActionType, ContactusState } from './models';
import * as ContactusConstants from './constants';

export const contactusInitialState: ContactusState = {
  // Submit
  submitMessageErrorMessage: null,
  submitMessageStatus: null,
  submitMessageLoading: false,
  submitMessageErrors: false,
};

export const contactusReducer = (
  state = contactusInitialState,
  action: ContactusActionType,
): ContactusState => {
  switch (action.type) {
    case ContactusConstants.SUBMIT:
      return {
        ...state,
        submitMessageErrorMessage: null,
        submitMessageLoading: true,
        submitMessageStatus: null,
        submitMessageErrors: false,
      };
    case ContactusConstants.SUBMIT_SUCCESS:
      return {
        ...state,
        submitMessageErrorMessage: action.payload.data,
        submitMessageStatus: action.payload.status,
        submitMessageLoading: false,
      };
    case ContactusConstants.SUBMIT_ERROR:
      return {
        ...state,
        submitMessageErrorMessage: action.payload.error || null,
        submitMessageLoading: false,
      };
    case ContactusConstants.SUBMIT_NETWORK_ERROR:
      return {
        ...state,
        submitMessageLoading: false,
        submitMessageErrors: true,
        submitMessageErrorMessage: 'Submit message error. Please try again.',
      };
    default:
      return state;
  }
};
