import { AppActions } from '../rootActions';
import { GET_MY_PROFILE, UPDATE_PROFILE, UPLOAD_PROFILE_IMAGE } from './constants';
import { UpdateProfilePayload, UploadProfileImagePayload } from './models';

/**
 * Functions to be called in React components
 */

export const getMyProfile = (): AppActions => ({ type: GET_MY_PROFILE });

export const updateProfile = (payload: UpdateProfilePayload): AppActions => ({
  type: UPDATE_PROFILE,
  payload: payload,
});

export const uploadProfileImage = (payload: UploadProfileImagePayload): AppActions => ({
  type: UPLOAD_PROFILE_IMAGE,
  payload: payload,
});
