import { CartState } from './models';
import AppState from '../rootState';
import { Course } from 'src/models/cart/Course';
import { QuestionBank } from 'src/models/cart/QuestionBank';
import { Resource } from 'src/models/cart/Resource';
import { PromoCode } from 'src/models/cart/PromoCode';

const selectCartState = (state: AppState): CartState => state.cart;

export const selectAddToCartStatusCode = (state: AppState): number | null =>
  selectCartState(state).addToCartStatusCode;

export const selectAddToCartErrorMessage = (state: AppState): string | null =>
  selectCartState(state).addToCartErrorMessage;

export const selectAddToCartNetworkError = (state: AppState): boolean =>
  selectCartState(state).addToCartNetworkError;

export const selectAddToCartLoading = (state: AppState): boolean | undefined =>
  selectCartState(state).addToCartLoading;

export const selectAddToCartResouceId = (state: AppState): string =>
  selectCartState(state).addToCartResourceId;

export const selectCartItemCount = (state: AppState): number | null => {
  const courses = selectCartState(state).courses;
  const questionBank = selectCartState(state).questionBank;
  const resources = selectCartState(state).resources;

  if (!courses || !questionBank || !resources) {
    return null;
  } else {
    return courses.length + questionBank.length + resources.length;
  }
};

export const selectCartNetworkError = (state: AppState): boolean =>
  selectCartState(state).getCartNetworkError;

export const selectCartStatusCode = (state: AppState): number | null =>
  selectCartState(state).getCartStatusCode;

export const selectCartCourse = (state: AppState): Course[] | null =>
  selectCartState(state).courses;

export const selectCartQuestionBank = (state: AppState): QuestionBank[] | null =>
  selectCartState(state).questionBank;

export const selectCartResource = (state: AppState): Resource[] | null =>
  selectCartState(state).resources;

export const selectPeopleAlsoBuyItem = (state: AppState): Course[] | null =>
  selectCartState(state).peopleAlsoBuyItem;

export const selectCutOffDate = (state: AppState): number | null =>
  selectCartState(state).cutOffDate;

export const selectCutOffDateStatusCode = (state: AppState): number | null =>
  selectCartState(state).cutOffDateStatusCode;

export const selectCurrentDate = (state: AppState): string | null =>
  selectCartState(state).currentDate;

export const selectPromoCode = (state: AppState): PromoCode | null =>
  selectCartState(state).promoCode;

export const selectPromoCodeLoading = (state: AppState): boolean =>
  selectCartState(state).promoCodeLoading;

export const selectPromoCodeErrorMessage = (state: AppState): string =>
  selectCartState(state).promoCodeErrorMessage;
