export const GET_NOTIFICATION_DETAILS = 'parentsNotification/getNotificationDetails';
export const GET_NOTIFICATION_DETAILS_SUCCESS = 'parentsNotification/getNotificationDetailsSuccess';
export const GET_NOTIFICATION_DETAILS_ERROR = 'parentsNotification/getNotificationDetailsError';
export const GET_NOTIFICATION_DETAILS_NETWORK_ERROR =
  'parentsNotification/getNotificationDetailsNetworkError';

export const VERIFY_PASSWORD = 'parentsNotification/verifyPassword';
export const VERIFY_PASSWORD_SUCCESS = 'parentsNotification/verifyPasswordSuccess';
export const VERIFY_PASSWORD_ERROR = 'parentsNotification/verifyPasswordError';
export const VERIFY_PASSWORD_NETWORK_ERROR = 'parentsNotification/verifyPasswordNetworkError';

export const SAVE_NOTIFICATION_DETAILS = 'parentsNotification/saveNotificationDetails';
export const SAVE_NOTIFICATION_DETAILS_SUCCESS =
  'parentsNotification/saveNotificationDetailsSuccess';
export const SAVE_NOTIFICATION_DETAILS_ERROR = 'parentsNotification/saveNotificationDetailsError';
export const SAVE_NOTIFICATION_DETAILS_NETWORK_ERROR =
  'parentsNotification/saveNotificationDetailsNetworkError';

export const UPDATE_NOTIFICATION_DETAILS = 'parentsNotification/updateNotificationDetails';
export const UPDATE_NOTIFICATION_DETAILS_SUCCESS =
  'parentsNotification/updateNotificationDetailsSuccess';
export const UPDATE_NOTIFICATION_DETAILS_ERROR =
  'parentsNotification/updateNotificationDetailsError';
export const UPDATE_NOTIFICATION_DETAILS_NETWORK_ERROR =
  'parentsNotification/updateNotificationDetailsNetworkError';

export const CANCEL_NOTIFICATION = 'parentsNotification/cancelNotification';
export const CANCEL_NOTIFICATION_SUCCESS = 'parentsNotification/cancelNotificationSuccess';
export const CANCEL_NOTIFICATION_ERROR = 'parentsNotification/cancelNotificationError';
export const CANCEL_NOTIFICATION_NETWORK_ERROR =
  'parentsNotification/cancelNotificationNetworkError';

export const CONFIRM_OTP = 'parentsNotification/confirmOTP';
export const CONFIRM_OTP_SUCCESS = 'parentsNotification/confirmOTPSuccess';
export const CONFIRM_OTP_ERROR = 'parentsNotification/confirmOTPError';
export const CONFIRM_OTP_NETWORK_ERROR = 'parentsNotification/confirmOTPNetworkError';

export const RESEND_OTP = 'parentsNotification/resendOTP';
export const RESEND_OTP_SUCCESS = 'parentsNotification/resendOTPSuccess';
export const RESEND_OTP_ERROR = 'parentsNotification/resendOTPError';
export const RESEND_OTP_NETWORK_ERROR = 'parentsNotification/resendOTPNetworkError';

export const UPDATE_PASSWORD = 'parentsNotification/updatePassword';
export const UPDATE_PASSWORD_SUCCESS = 'parentsNotification/updatePasswordSuccess';
export const UPDATE_PASSWORD_ERROR = 'parentsNotification/updatePasswordError';
export const UPDATE_PASSWORD_NETWORK_ERROR = 'parentsNotification/updatePasswordNetworkError';

export const FORGOT_PASSWORD = 'parentsNotification/forgotPassword';
export const FORGOT_PASSWORD_SUCCESS = 'parentsNotification/forgotPasswordSuccess';
export const FORGOT_PASSWORD_ERROR = 'parentsNotification/forgotPasswordError';
export const FORGOT_PASSWORD_NETWORK_ERROR = 'parentsNotification/forgotPasswordNetworkError';

export const RESET_PARENTS_NOTIFICATION = 'parentsNotification/resetParentsNotification';
