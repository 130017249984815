import { CareerActionType, CareerState } from './models';
import * as CareerConstants from './constants';

export const careerInitialState: CareerState = {
  // Submit
  submitTutorErrorMessage: null,
  submitTutorStatus: null,
  submitTutorLoading: false,
  submitTutorErrors: false,
};

export const careerReducer = (
  state = careerInitialState,
  action: CareerActionType,
): CareerState => {
  switch (action.type) {
    case CareerConstants.SUBMIT:
      return {
        ...state,
        submitTutorErrorMessage: null,
        submitTutorLoading: true,
        submitTutorStatus: null,
        submitTutorErrors: false,
      };
    case CareerConstants.SUBMIT_SUCCESS:
      return {
        ...state,
        submitTutorErrorMessage: action.payload.data,
        submitTutorStatus: action.payload.status,
        submitTutorLoading: false,
      };
    case CareerConstants.SUBMIT_ERROR:
      return {
        ...state,
        submitTutorErrorMessage: action.payload.error || null,
        submitTutorLoading: false,
      };
    case CareerConstants.SUBMIT_NETWORK_ERROR:
      return {
        ...state,
        submitTutorLoading: false,
        submitTutorErrors: true,
        submitTutorErrorMessage: 'Submit tutor recruitment error. Please try again.',
      };
    default:
      return state;
  }
};
