import { Flex, Box, Text, Stack, Button, AspectRatio, Image, Container, Heading, Grid } from '@chakra-ui/react'
import React from 'react'
import { Section1, Section2, Section3 } from 'src/data/career';

import Banner from 'src/images/img_career_banner.png';

function Career(): JSX.Element {
  return (
    <Box>
      {/* Section 1 */}
      <Flex flexDirection={{ base: 'column', md: 'row' }} px={10} py={{ md: 10 }}>
        <Stack p={{ base: '30px', md: '50px' }} pl={{ base: '0', md: '50px' }} spacing={5} w={{ base: '100%', md: '50%'}}>
          <Text fontSize={{ base: '30px', md: '40px'}} fontWeight="bold">
            {Section1.title}
          </Text>
          <Text fontSize={{ base: '20px', md: '20px'}} fontWeight="bold" color="tavisGy.20">
            {Section1.paragraph}
          </Text>
          <Button
            as="a"
            href="/career/form"
            bg="tavisG.20"
            color="white"
            fontSize={20}
            fontWeight="bold"
            px={10}
            w="fit-content"
            _hover={{ bg: 'tavisG.30' }}
          >
            {Section1.btnTitle}
          </Button>
        </Stack>
        <AspectRatio w={{ base: '100%', md: '50%'}} ratio={16 / 9}>
          <Image
            w="600px"
            h="400px"
            src={Banner}
          />
        </AspectRatio>
      </Flex>
      {/* Section 2 */}
      <Box py={{ base: 100, md: 28 }}>
        <Container maxW="7xl">
          <Heading
            as="h3"
            fontWeight="bold"
            fontSize={{ base: '38px', md: '40px' }}
            pb={{ base: 50 }}
            color="tavisGy.10"
            textAlign="center"
          >
            {Section2.title}
          </Heading>
          <Grid
            templateColumns={{
              base: 'repeat(1, 1fr)',
              lg: 'repeat(4, 1fr)',
            }}
            gap={6}
          >
            {Section2.items.map((item) => (
              <Box
                textAlign="center"
                w="100%"
                py={{ base: '40px', md: '96px' }}
                px={{ base: '33px' }}
              >
                <Flex justifyContent="center">
                  <Image src={item.icon} h="61px" w="61px" />
                </Flex>
                <Text fontSize="20px" fontWeight="bold" py={{ base: 3 }} color="tavisGy.20">
                  {item.paragraph}
                </Text>
              </Box>
            ))}
          </Grid>
        </Container>
      </Box>
      {/* Section 3 */}
      <Box pb={{ base: 100, md: 28 }}>
        <Container maxW="7xl">
          <Heading
            as="h3"
            fontWeight="bold"
            fontSize={{ base: '38px', md: '41px' }}
            pb={{ base: 50 }}
            color="tavisGy.10"
            textAlign="center"
          >
            {Section3.title}
          </Heading>
          <Text fontSize="24px" fontWeight="medium" maxW="80%" textAlign="center" m="auto" color="tavisGy.20">
            {Section3.paragraph}
          </Text>
        </Container>
      </Box>
    </Box>
  );
}

export default Career;