export const LOGIN = 'auth/login';
export const LOGIN_SUCCESS = 'auth/loginSuccess';
export const LOGIN_ERROR = 'auth/loginError';
export const LOGIN_NETWORK_ERROR = 'auth/loginNetworkError';

export const FORGET_PASSWORD = 'auth/forgetPassword';
export const FORGET_PASSWORD_SUCCESS = 'auth/forgetPasswordSuccess';
export const FORGET_PASSWORD_ERROR = 'auth/forgetPasswordError';
export const FORGET_PASSWORD_NETWORK_ERROR = 'auth/forgetPasswordNetowrkError';

export const REGISTER = 'auth/register';
export const REGISTER_SUCCESS = 'auth/registerSuccess';
export const REGISTER_ERROR = 'auth/registerError';
export const REGISTER_NETWORK_ERROR = 'auth/registerNetworkError';

export const LOGIN_GOOGLE = 'auth/loginWithGoogle';
export const LOGIN_GOOGLE_SUCCESS = 'auth/loginWithGoogleSuccess';
export const LOGIN_GOOGLE_ERROR = 'auth/loginWithGoogleError';

export const LOGIN_FACEBOOK = 'auth/loginWithFacebook';
export const LOGIN_FACEBOOK_SUCCESS = 'auth/loginWithFacebookSuccess';
export const LOGIN_FACEBOOK_ERROR = 'auth/loginWithFacebookError';

export const LOGIN_APPLE = 'auth/loginWithApple';
export const LOGIN_APPLE_SUCCESS = 'auth/loginWithAppleSuccess';
export const LOGIN_APPLE_ERROR = 'auth/loginWithAppleError';

export const REFRESH_TOKEN = 'auth/refreshToken';
export const REFRESH_TOKEN_SUCCESS = 'auth/refreshTokenSuccess';
export const REFRESH_TOKEN_ERROR = 'auth/refreshTokenError';
export const REFRESH_TOKEN_NETWORK_ERROR = 'auth/refreshTokenNetworkError';

export const LOGOUT = 'auth/logout';
export const LOGOUT_SUCCESS = 'auth/logoutSuccess';
export const LOGOUT_ERROR = 'auth/logoutError';

export const CLEAR_STATUS = 'auth/clearStatus';

export const CLEAR_VERIFICATION_STATUS = 'auth/clearVerificationStatus';

export const CONFIRM_VERIFICATION_CODE = 'auth/confirmVerificationCode';
export const CONFIRM_VERIFICATION_CODE_SUCCESS = 'auth/confirmVerificationCodeSuccess';
export const CONFIRM_VERIFICATION_CODE_ERROR = 'auth/confirmVerificationCodeError';
export const CONFIRM_VERIFICATION_CODE_NETWORK_ERROR = 'auth/confirmVerificationCodeNetworkError';

export const RESEND_VERIFICATION_CODE = 'auth/resendVerificationCode';
export const RESEND_VERIFICATION_CODE_SUCCESS = 'auth/resendVerificationCodeSuccess';
export const RESEND_VERIFICATION_CODE_ERROR = 'auth/resendVerificationCodeError';
export const RESEND_VERIFICATION_CODE_NETWORK_ERROR = 'auth/resendVerificationCodeNetworkError';

export const GET_USER_DETAILS = 'auth/getUserDetails';
export const GET_USER_DETAILS_SUCCESS = 'auth/getUserDetailsSuccess';
export const GET_USER_DETAILS_ERROR = 'auth/getUserDetailsError';
export const GET_USER_DETAILS_NETWORK_ERROR = 'auth/getUserDetailsNetworkError';
export const RESET_USER_DETAILS = 'auth/resetUserDetails';

export const UPDATE_ONBOARDING_DETAILS = 'auth/updateOnboardingDetails';
export const UPDATE_ONBOARDING_DETAILS_SUCCESS = 'auth/updateOnboardingDetailsSuccess';
export const UPDATE_ONBOARDING_DETAILS_ERROR = 'auth/updateOnboardingDetailsError';
export const UPDATE_ONBOARDING_DETAILS_NETWORK_ERROR = 'auth/updateOnboardingDetailsNetworkError';

export const GET_STUDY_LEVEL = 'auth/getStudyLevel';
export const GET_STUDY_LEVEL_SUCCESS = 'auth/getStudyLevelSuccess';
export const GET_STUDY_LEVEL_ERROR = 'auth/getStudyLevelError';
export const GET_STUDY_LEVEL_NETWORK_ERROR = 'auth/getStudyLevelNetworkError';

export const GET_MORE_STUDY_LEVEL = 'auth/getMoreStudyLevel';
export const GET_MORE_STUDY_LEVEL_SUCCESS = 'auth/getMoreStudyLevelSuccess';

export const SKIP_VERIFICATION = 'auth/skipVerification';
export const SKIP_VERIFICATION_SUCCESS = 'auth/skipVerificationSuccess';
export const SKIP_VERIFICATION_ERROR = 'auth/skipVerificationError';
export const SKIP_VERIFICATION_NETWORK_ERROR = 'auth/skipVerificationNetworkError';