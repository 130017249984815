import { ComponentStyleConfig } from '@chakra-ui/theme';

const styles: ComponentStyleConfig = {
  baseStyle: {
    control: {
      bg: 'white',
      borderColor: '#B4B4B4',
      padding: '6px',
      _checked: {
        bg: 'white',
        color: 'black',
        borderColor: '#B4B4B4',
        _hover: {
          bg: 'white',
        },
      },
    },
  },
};

export default styles;
