import React from 'react';
import { useDispatch } from 'react-redux';
import { Flex, Image, IconButton, Text, HStack } from '@chakra-ui/react';

import { deleteCartItem, getCart } from 'src/redux/cart/actions';
import { RemoveIcon } from 'src/icons';
import { formatPrice } from 'src/utils/formatPrice';
import { Resource } from 'src/models/cart/Resource';
import Rectangle from 'src/images/rectangle.png';

interface ResourceItemProps {
  resource: Resource;
  resetPromoCode: () => void;
}

function ResourceItem({ resource, resetPromoCode }: ResourceItemProps): JSX.Element {
  const dispatch = useDispatch();

  const hasDiscount = Boolean(resource.discountPrice);

  const onDelete = (id: string) => {
    resetPromoCode();
    dispatch(deleteCartItem(id));
    setTimeout(() => {
      dispatch(getCart());
    }, 500);
  };

  return (
    <Flex w="100%" py="4" borderBottomColor="gray.600" borderBottomWidth="1px">
      <Flex direction="column" justifyContent="center">
        <IconButton
          mx="4"
          aria-label="remove-cart-item"
          icon={<RemoveIcon boxSize="20px" />}
          variant="unstyled"
          _hover={{ color: 'red' }}
          _focus={{ outline: 'none' }}
          onClick={() => onDelete(String(resource.cartId))}
        />
      </Flex>
      <Image
        src={resource.resourceImageUrl || Rectangle}
        minW="100px"
        minH="100px"
        maxW="100px"
        maxH="100px"
        borderRadius="lg"
        fit="cover"
      />
      <Flex w="full" ml="4" justifyContent="space-between">
        <Flex direction="column">
          <Text fontSize="lg" fontWeight="medium">
            {resource.resourceName} - {resource.form.name} (#{resource.sku})
          </Text>
        </Flex>
        <Flex direction="column" fontSize="lg" fontWeight="medium" alignItems="flex-end">
          {hasDiscount ? (
            <HStack spacing="2">
              <Text>Price:</Text>
              <Text textDecoration="line-through">{formatPrice(String(resource.price))}</Text>
              <Text color="red.500">{formatPrice(String(resource.discountPrice))}</Text>
            </HStack>
          ) : (
            <Text>Price: {formatPrice(String(resource.price))}</Text>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}

export default ResourceItem;
