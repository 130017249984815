import { HStack, Box, Text } from '@chakra-ui/react';
 import React from 'react';
import { useTranslation } from 'react-i18next';
 import { AiOutlineWarning } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { selectProfile } from 'src/redux/profile/selectors';
 import VerifyStudentEmailPrompt from './VerifyStudentEmailPrompt';

 function VerifyEmailWarning() {
  const { t } = useTranslation();
  const profile = useSelector(selectProfile);
   return (
     <HStack spacing={5}>
       <AiOutlineWarning color="#FFBA00" size="25px" />
       <Box
         p={3}
         bg="rgba(255, 186, 0, 0.3)"
         border="1px solid #FFBA00"
         color="#BC8900"
         fontSize="14px"
         borderRadius="5px"
       >
         <Text>{t('please_verify_your_email', { email: profile?.email })}.</Text>
         <VerifyStudentEmailPrompt />
       </Box>
     </HStack>
   );
 }

 export default VerifyEmailWarning;
