import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function SignInIcon(props: IconProps): JSX.Element {
  return (
    <Icon {...props} viewBox="0 0 48 48">
      <g>
        <path
          className="st0"
          fill='#FFFFFF'
          d="M17.9,24H24v4c0,0.6,0.4,1,1,1s1-0.4,1-1v-4h4v5c0,0.6,0.4,1,1,1s1-0.4,1-1v-6c0-0.6-0.4-1-1-1H17.9
          c-0.6-3.8-4.1-6.5-7.9-5.9S3.5,20.2,4,24c0.6,3.8,4.1,6.5,7.9,5.9C15,29.5,17.5,27.1,17.9,24z M6,23c0-2.8,2.2-5,5-5s5,2.2,5,5
          s-2.2,5-5,5C8.2,28,6,25.8,6,23z"
        />
        <path
          className="st0"
          fill='#FFFFFF'
          d="M24,4c-5.5,0-10.8,2.3-14.6,6.3c-0.4,0.4-0.4,1,0,1.4c0.4,0.4,1,0.4,1.4,0c0,0,0,0,0,0C14.3,8,19,6,24,6
          c9.9,0,18,8,18,18c0,9.9-8,18-18,18c-5.8,0-11.3-2.8-14.7-7.6C9,34,8.4,33.8,8,34.2s-0.6,0.9-0.3,1.4c0,0,0,0,0,0
          c6.4,9,18.9,11.1,27.9,4.7s11.1-18.9,4.7-27.9C36.5,7.1,30.5,4,24,4z"
        />
      </g>
    </Icon>
  );
}
