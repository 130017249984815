import { compareAsc } from 'date-fns';

export const getFreeTrial = (currentDate: Date, expiryDate: Date): boolean => {
  const result = compareAsc(new Date(currentDate), expiryDate);
  if (result === 0 || result === -1) {
    return true;
  } else {
    return false;
  }
};
