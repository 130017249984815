import { ComponentStyleConfig } from '@chakra-ui/theme';

const styles: ComponentStyleConfig = {
  baseStyle: {
    label: {
      width: '100%',
    },
  },
};

export default styles;
