import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function RightArrowIcon(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <path
        d="M2.33305795,24 C1.38940494,24 0.538759512,23.4143343 0.177666402,22.5165481 C-0.183426708,21.6187618 0.0161191689,20.5853903 0.68328188,19.8981597 L8.3674581,11.9852363 L0.68328188,4.0723129 C-0.201045251,3.12944498 -0.188398129,1.6307092 0.711707089,0.703809108 C1.61181231,-0.223090981 3.06722256,-0.23611459 3.98283401,0.67453772 L13.3167863,10.2863487 C14.2277379,11.2247014 14.2277379,12.7457712 13.3167863,13.6841239 L3.98283401,23.2959348 C3.54532196,23.7466071 2.95188688,24 2.33305795,24 L2.33305795,24 Z"
        id="Arrow"
        fill="currentColor"
        fillRule="nonzero"
      ></path>
    </Icon>
  );
}
