import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  Avatar,
  Box,
  Flex,
  Text,
  Spacer,
  IconButton,
  Input,
  Select,
  HStack,
  useToast,
  Spinner,
  Button,
} from '@chakra-ui/react';

import {
  selectProfile,
  selectProfileLoading,
  selectProfileNetworkError,
  selectProfileStatusCode,
  selectUpdateProfileNetworkError,
  selectUpdateProfileStatusCode,
  selectUploadProfileImageNetworkError,
  selectUploadProfileImageStatusCode,
} from 'src/redux/profile/selectors';
import NavigateTopbar from 'src/components/Topbar/NavigateTopbar';
import { CancelIcon, EditIcon, SaveIcon } from 'src/icons';
import { getMyProfile, updateProfile, uploadProfileImage } from 'src/redux/profile/actions';
import useSessionExpired from 'src/components/SessionExpired';
import NetworkErrorComponent from 'src/components/NetworkError';
import User from 'src/images/User.svg';
import { formatDate } from 'src/utils/formatDate';

function Profile(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast();
  const networkErrorToastId = 'networkErrorToast';

  // Profile
  const profile = useSelector(selectProfile);
  const profileLoading = useSelector(selectProfileLoading);
  const profileStatusCode = useSelector(selectProfileStatusCode);
  const profileNetworkError = useSelector(selectProfileNetworkError);

  // Update profile
  const updateProfileStatusCode = useSelector(selectUpdateProfileStatusCode);
  const updateProfileNetworkError = useSelector(selectUpdateProfileNetworkError);

  // Upload profile image
  const uploadProfileImageStatusCode = useSelector(selectUploadProfileImageStatusCode);
  const uploadProfileImageNetworkError = useSelector(selectUploadProfileImageNetworkError);

  const [editProfile, setEditProfile] = useState(false);
  const [imageSrc, setImageSrc] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (profileStatusCode === 200 && profile && profile.profile_url) {
      setImageSrc(`${profile.profile_url}?${Date.now().toString()}`);
    }
  }, [profileStatusCode, profile]);

  useEffect(() => {
    if (updateProfileStatusCode === 200) {
      dispatch(getMyProfile());
    } else if (updateProfileNetworkError) {
      if (!toast.isActive(networkErrorToastId)) {
        toast({
          title: t('network_error'),
          position: 'top',
          status: 'error',
        });
      }
    }
  }, [updateProfileStatusCode, updateProfileNetworkError]);

  useEffect(() => {
    if (uploadProfileImageStatusCode === 200) {
      dispatch(getMyProfile());
    } else if (uploadProfileImageNetworkError) {
      if (!toast.isActive(networkErrorToastId)) {
        toast({
          title: t('network_error'),
          position: 'top',
          status: 'error',
        });
      }
    }
  }, [uploadProfileImageStatusCode, uploadProfileImageNetworkError]);

  useSessionExpired(profileStatusCode);
  useSessionExpired(updateProfileStatusCode);
  useSessionExpired(uploadProfileImageStatusCode);
  if (profileNetworkError) return <NetworkErrorComponent />;

  const showProfileContent = () => {
    if (profile) {
      const day = profile.birthday.slice(0, 2);
      const month = profile.birthday.slice(3, 5);
      const year = profile.birthday.slice(6, 10);
      return (
        <>
          <Flex
            mt="4"
            flexDirection={{
              base: 'column',
              sm: 'row',
            }}
          >
            <Box as="span" width="170px" display="inline-flex" color="#AFAFAF">
              {t('dob')}:
            </Box>
            {editProfile ? (
              <Input
                required
                minW="fit-content"
                w="50%"
                h="34px"
                maxW="400px"
                type="date"
                borderRadius="10px"
                defaultValue={`${year}-${month}-${day}`}
                id="dob"
                max={formatDate(new Date())}
                onInvalid={(e) =>
                  (e.target as HTMLInputElement).setCustomValidity(t('please_fill_up_this_field'))
                }
                onInput={(e) => (e.target as HTMLInputElement).setCustomValidity('')}
              />
            ) : (
              <Box as="span" display="inline-flex">
                {Boolean(profile?.birthday) ? profile.birthday : 'N/A'}
              </Box>
            )}
          </Flex>
          <Flex
            mt="4"
            flexDirection={{
              base: 'column',
              sm: 'row',
            }}
          >
            <Box as="span" width="170px" display="inline-flex" color="#AFAFAF">
              {t('gender')}:
            </Box>
            {editProfile ? (
              <Select
                required
                minW="fit-content"
                maxW="400px"
                w="50%"
                h="34px"
                defaultValue={profile.gender?.toLowerCase()}
                borderRadius="10px"
                id="gender"
                onInvalid={(e) =>
                  (e.target as HTMLInputElement).setCustomValidity(t('please_fill_up_this_field'))
                }
              >
                <option value="male">{t('male')}</option>
                <option value="female">{t('female')}</option>
              </Select>
            ) : Boolean(profile?.gender) ? (
              t(profile.gender.toLowerCase())
            ) : (
              'N/A'
            )}
          </Flex>
          <Flex
            mt="4"
            flexDirection={{
              base: 'column',
              sm: 'row',
            }}
          >
            <Box as="span" width="170px" display="inline-flex" color="#AFAFAF">
              {t('phone_number')}:
            </Box>
            {editProfile ? (
              <Input
                required
                minW="fit-content"
                maxW="400px"
                w="50%"
                h="34px"
                defaultValue={profile.mobile}
                borderRadius="10px"
                id="phoneNumber"
                maxLength={13}
                minLength={10}
                pattern="[0-9]{10,}"
                onInput={(event) => {
                  const target = event.currentTarget;
                  target.setCustomValidity('');
                  if (target.value.length > target.maxLength) {
                    target.value = target.value.slice(0, target.maxLength);
                  }
                }}
                onInvalid={(e) => {
                  const target = e.currentTarget as HTMLInputElement;
                  if (target.value.length === 0) {
                    target.setCustomValidity(t('please_fill_up_this_field'));
                  } else if (target.value.length < target.minLength) {
                    target.setCustomValidity(t('please_enter_valid_phone_number'));
                  } else {
                    target.setCustomValidity(t('please_enter_a_number'));
                  }
                }}
              />
            ) : profile.mobile.length === 0 ? (
              'N/A'
            ) : (
              profile.mobile
            )}
          </Flex>
        </>
      );
    }
  };

  const onClickSaveProfile = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const birthday = (document.getElementById('dob') as HTMLInputElement).value;
    const gender = (document.getElementById('gender') as HTMLInputElement).value;
    const firstname = (document.getElementById('firstname') as HTMLInputElement).value;
    const lastname = (document.getElementById('lastname') as HTMLInputElement).value;
    const phoneNumber = (document.getElementById('phoneNumber') as HTMLInputElement).value;

    if (profile) {
      setEditProfile(!editProfile);
      const day = profile.birthday.slice(0, 2);
      const month = profile.birthday.slice(3, 5);
      const year = profile.birthday.slice(6, 10);
      const profileBirthday = `${year}-${month}-${day}`;

      if (
        birthday !== profileBirthday ||
        gender !== profile.gender.toLowerCase() ||
        firstname !== profile.firstname ||
        lastname !== profile.lastname ||
        phoneNumber !== profile.mobile
      ) {
        dispatch(
          updateProfile({
            id: profile.id,
            firstname: firstname,
            lastname: lastname,
            email: profile.email,
            mobile: phoneNumber,
            birthday: birthday,
            gender: gender,
          }),
        );
      }
    }
  };

  const onClickUploadProfileImage = () => {
    const input = document.getElementById('file') as HTMLInputElement;
    if (profile && input.files) {
      dispatch(
        uploadProfileImage({
          email: profile.email,
          filepath: input.files[0],
        }),
      );
    }
  };

  return (
    <Flex w="100%" h="fit-content" flexDirection="column" minH="100vh">
      <NavigateTopbar
        currentTab={t('profile')}
        firstTitle={t('profile')}
        firstTo="/profile"
        secondTitle={t('my_purchases')}
        secondTo="/my-purchases"
        secondTitleWidth="220px"
        isWithSearchbar={false}
      />
      <Box mx="8" height="100%" minH="fit-content">
        <Flex p="8" flexDirection="column" borderBottom="1px solid #707070">
          <Flex
            mr="16"
            direction={{
              base: 'column',
              md: 'row',
            }}
            width="100%">
            {!profileLoading && profile ? (
              <>
                <Flex
                  mr={{
                    base: '0',
                    md: '8',
                  }}
                  direction="column"
                  pb={{
                    base: '8',
                    md: '0',
                  }}>
                  <Flex justifyContent="center">
                    <Avatar
                      borderRadius="xl"
                      bg="white"
                      boxSize={{
                        base: '150px',
                        sm: '250px',
                      }}
                      src={imageSrc || User}
                    />
                  </Flex>
                  {editProfile && (
                    <>
                      <Input type="file" id="file" mt="20px" />
                      <Button onClick={onClickUploadProfileImage} mt="12px">
                        {t('upload_image')}
                      </Button>
                    </>
                  )}
                </Flex>
                <Flex as="form" direction="column" fontSize={18} fontWeight="medium" w="100%" onSubmit={onClickSaveProfile}>
                  <Flex w="100%">
                    {editProfile ? (
                      <Flex>
                        <Input
                          required
                          maxW="400px"
                          w="50%"
                          h="34px"
                          mr={4}
                          defaultValue={profile.firstname}
                          borderRadius="10px"
                          id="firstname"
                          onInvalid={(e) =>
                            (e.target as HTMLInputElement).setCustomValidity(t('please_fill_up_this_field'))
                          }
                          onInput={(e) => (e.target as HTMLInputElement).setCustomValidity('')}
                        />
                        <Input
                          required
                          maxW="400px"
                          w="50%"
                          h="34px"
                          defaultValue={profile.lastname}
                          borderRadius="10px"
                          id="lastname"
                          onInvalid={(e) =>
                            (e.target as HTMLInputElement).setCustomValidity(t('please_fill_up_this_field'))
                          }
                          onInput={(e) => (e.target as HTMLInputElement).setCustomValidity('')}
                        />
                      </Flex>
                    ) : (
                      <Text fontSize={26} fontWeight="semibold">
                        {profile.fullname}
                      </Text>
                    )}
                    <Spacer />
                    {!editProfile ? (
                      <IconButton
                        aria-label="edit"
                        icon={<EditIcon boxSize="20px" />}
                        bg="white"
                        onClick={() => setEditProfile(!editProfile)}
                      />
                    ) : (
                      <HStack spacing={5}>
                        <IconButton
                          aria-label="edit"
                          icon={<CancelIcon />}
                          bg="white"
                          onClick={() => setEditProfile(!editProfile)}
                        />
                        <IconButton
                          aria-label="edit"
                          icon={<SaveIcon boxSize="20px" />}
                          bg="white"
                          type="submit"
                        />
                      </HStack>
                    )}
                  </Flex>
                  <Box
                    display="flex"
                    mt="4"
                    flexDirection={{
                      base: 'column',
                      sm: 'row',
                    }}>
                    <Box as="span" width="170px" display="inline-flex" color="#AFAFAF">
                      {t('email')}:
                    </Box>
                    <Box as="span" display="inline-flex">
                      {profile.email}
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    mt="4"
                    flexDirection={{
                      base: 'column',
                      sm: 'row',
                    }}>
                    <Box as="span" width="170px" display="inline-flex" color="#AFAFAF">
                      {t('id')}:
                    </Box>
                    <Box as="span" display="inline-flex">
                      {profile.idNumber}
                    </Box>
                  </Box>
                  {showProfileContent()}
                </Flex>
              </>
            ) : (
              <Flex flex="1" justifyContent="center" alignItems="center">
                <Spinner />
              </Flex>
            )}
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
}

export default Profile;
