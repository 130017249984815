import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { logout } from 'src/redux/auth/actions';

function useSessionExpired(statusCode: number | null): void {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    if (statusCode === 401) {
      alert(t('session_expired'));
      dispatch(logout());
      history.push('/');
    }
  }, [statusCode, dispatch, history]);
}

export default useSessionExpired;
