import { of } from 'rxjs';
import { ajax, AjaxResponse, AjaxError } from 'rxjs/ajax';
import { map, switchMap, catchError, takeUntil } from 'rxjs/operators';
import { combineEpics, ofType, ActionsObservable, Epic } from 'redux-observable';

import { toUrlString } from 'src/utils/queryString';
import { API_URL } from 'src/constants/api';
import {
  GET_EVENTS,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_ERROR,
  GET_EVENTS_NETWORK_ERROR,
  GET_CLASSROOM_LINK,
  GET_CLASSROOM_LINK_SUCCESS,
  GET_CLASSROOM_LINK_ERROR,
  GET_CLASSROOM_LINK_NETWORK_ERROR,
} from './constants';
import {
  EventsResponse,
  GetEventsAction,
  GetEventsSuccessAction,
  GetClassroomLinkAction,
  GetClassroomLinkSuccessAction,
  ClassroomLinkResponse,
} from './models';
import { LOGOUT } from '../auth/constants';
import { ErrorResponse } from 'src/models/ErrorResponse';

function getEventsEpic(action$: ActionsObservable<GetEventsAction>) {
  return action$.pipe(
    ofType(GET_EVENTS),
    switchMap((action) => {
      const apiQuery = toUrlString(action.payload);
      return ajax({
        url: API_URL + '/calendar?' + apiQuery,
        method: 'get',
        withCredentials: true,
      }).pipe(
        map((payload: AjaxResponse): GetEventsSuccessAction => {
          return {
            type: GET_EVENTS_SUCCESS,
            payload: payload.response as EventsResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: GET_EVENTS_NETWORK_ERROR,
            });
          }
          return of({
            type: GET_EVENTS_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function getClassroomLinkEpic(action$: ActionsObservable<GetClassroomLinkAction>) {
  return action$.pipe(
    ofType(GET_CLASSROOM_LINK),
    switchMap((action) => {
      return ajax({
        url: API_URL + '/meeting/link?',
        method: 'post',
        withCredentials: true,
        body: {
          eventId: action.payload.eventId,
        },
        headers: {
          'content-type': 'application/json;charset=UTF-8',
        },
      }).pipe(
        map((payload: AjaxResponse): GetClassroomLinkSuccessAction => {
          return {
            type: GET_CLASSROOM_LINK_SUCCESS,
            payload: payload.response as ClassroomLinkResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: GET_CLASSROOM_LINK_NETWORK_ERROR,
            });
          }
          return of({
            type: GET_CLASSROOM_LINK_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

export const eventEpics: Epic = combineEpics(...[getEventsEpic, getClassroomLinkEpic]);
