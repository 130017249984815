import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Flex, Button } from '@chakra-ui/react';

import { FacebookIcon } from 'src/icons';
import { loginWithFacebook } from 'src/redux/auth/actions';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { FacebookProfile } from 'src/models/SocialLogin';

interface SignInWithFacebookProps {
  setFirstLogin: () => void;
}

function SignInWithFacebook({ setFirstLogin }: SignInWithFacebookProps): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const appId = String(process.env.REACT_APP_FACEBOOK_APP_ID);

  const responseFacebook = (res: FacebookProfile) => {
    setFirstLogin();
    const data = res;

    dispatch(
      loginWithFacebook({
        id: Number(data.id),
        email: data.email,
        first_name: data.first_name,
        last_name: data.last_name,
        birthday: data.birthday,
        gender: data.gender,
        role: 'student',
      }),
    );
  };

  const fbLoginButton = (renderProps: { onClick: () => void }) => {
    return (
      <Button
        minW="250px"
        w="250px"
        color="white"
        bg="facebook.500"
        fontWeight="regular"
        rounded="full"
        justifyContent="flex-start"
        _hover={{ bg: 'facebook.700' }}
        _active={{ bg: 'facebook.700' }}
        onClick={renderProps.onClick}>
        <FacebookIcon />
        <Flex justifyContent="center" flex="1">
          {t('sign_in_with', { provider: 'Facebook' })}
        </Flex>
      </Button>
    );
  };

  return (
    <FacebookLogin
      appId={appId}
      autoLoad={false}
      fields="email,first_name,last_name,birthday,gender"
      callback={responseFacebook}
      render={fbLoginButton}
    />
  );
}

export default SignInWithFacebook;
