import { Container, Flex, Image, Text, Button } from '@chakra-ui/react';
import TelescopeImage from 'src/images/img_telescope.png';

export default function PageNotFound() {
  return (
    <Flex
      py={{ base: '100px', md: '125px' }}
      pb={{ base: '150px', md: '125px' }}
      color="tavisGy.10"
      alignItems="center"
      height={{
        base: 'auto',
        md: '80vh',
      }}>
      <Container maxW="7xl">
        <Flex
          direction={{
            base: 'column',
            md: 'row',
          }}>
          <Flex
            flex={1}
            justifyContent={{
              base: 'center',
              md: 'flex-start',
            }}>
            <Image
              alt="A green telescope searching for missing content"
              fit="cover"
              w={{
                base: '200px',
                md: '100%',
              }}
              h="100%"
              src={TelescopeImage}
            />
          </Flex>
          <Flex
            direction="column"
            flex={1}
            justifyContent="center"
            alignItems={{
              base: 'center',
              md: 'flex-start',
            }}>
            <Text
              fontSize={{
                base: '100px',
                md: '150px',
              }}
              fontWeight="bold"
              lineHeight="1">
              404
            </Text>
            <Text fontSize="16px" mb="34px">
              The page you're looking for doesn't exist.
            </Text>
            <Button
              as="a"
              href="/"
              rounded={10}
              fontSize="16px"
              fontWeight="normal"
              py="10px"
              px="50px"
              color="white"
              bg="tavisG.20"
              _hover={{ bg: 'tavisG.30' }}
              maxW={212}>
              Back to Home
            </Button>
          </Flex>
        </Flex>
      </Container>
    </Flex>
  );
}
