import {
  Box,
  chakra,
  Container,
  Link,
  SimpleGrid,
  Stack,
  Text,
  VisuallyHidden,
  useColorModeValue,
  Image,
  Flex,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { FaInstagram, FaTwitter, FaTiktok } from 'react-icons/fa';
import { AiFillFacebook } from 'react-icons/ai';
import TavisLogo from 'src/images/tavis_logo.svg';

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode;
  label: string;
  href: string;
}) => {
  return (
    <chakra.button
      w="fit-content"
      h="fit-content"
      cursor="pointer"
      as="a"
      href={href}
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      transition="background 0.3s ease"
      borderRadius={4}
      target="_blank">
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

const ListHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Text fontWeight="bold" fontSize="20px" mb={2} color="tavisGy.20">
      {children}
    </Text>
  );
};

export default function Footer() {
  const linkHoverColor = useColorModeValue('gray.800', 'white');

  return (
    <Flex flexDir="column" bg="tavisGy.40">
      <Container as={Stack} maxW="7xl" pt={10} pb={5}>
        <SimpleGrid
          templateColumns={{ sm: '1fr 1fr', md: '2fr 1fr 1fr 2fr' }}
          spacing={8}
          pb="30px"
          borderBottom={1}
          borderStyle="solid"
          borderColor="tavisGy.50">
          <Stack spacing={6}>
            <Box>
              <Link href="/">
                <Image src={TavisLogo} alt="Tavis Logo" htmlWidth="98" htmlHeight="25" />
              </Link>
            </Box>
            <Text textAlign="left" fontSize="16px" color="tavisGy.20" fontWeight="medium">
              We aspire to provide accessible and affordable quality education to every student around Malaysia.
            </Text>
          </Stack>
          <Stack align="flex-start" fontSize="16px" fontWeight="medium">
            <ListHeader>Company</ListHeader>
            <Link
              href="/about-us"
              color="tavisGy.20"
              _hover={{
                textDecoration: 'none',
                color: linkHoverColor,
              }}>
              About Us
            </Link>
            <Link
              href="/tutors"
              color="tavisGy.20"
              _hover={{
                textDecoration: 'none',
                color: linkHoverColor,
              }}>
              Our Tutors
            </Link>
            <Link
              href="/career"
              color="tavisGy.20"
              _hover={{
                textDecoration: 'none',
                color: linkHoverColor,
              }}>
              Careers
            </Link>
          </Stack>
          <Stack align="flex-start" fontSize="16px" fontWeight="medium">
            <ListHeader>Products</ListHeader>
            <Link
              href="/classes"
              color="tavisGy.20"
              _hover={{
                textDecoration: 'none',
                color: linkHoverColor,
              }}>
              Classes
            </Link>
          </Stack>
          <Stack align="flex-start">
            <Stack direction="row" spacing={6}>
              <SocialButton label="Facebook" href="https://www.facebook.com/tavislive">
                <AiFillFacebook fontSize="26px" color="#595959" />
              </SocialButton>
              <SocialButton label="Instagram" href="https://www.instagram.com/tavislive">
                <FaInstagram fontSize="26px" color="#595959" />
              </SocialButton>
              <SocialButton label="Twitter" href="https://twitter.com/Tavislive">
                <FaTwitter fontSize="26px" color="#595959" />
              </SocialButton>
              <SocialButton label="TikTok" href="https://vt.tiktok.com/ZSe4UDm5V">
                <FaTiktok fontSize="23px" color="#595959" />
              </SocialButton>
            </Stack>
          </Stack>
        </SimpleGrid>
        <Stack
          direction={{
            base: 'column',
            sm: 'row',
          }}
          align="flex-start"
          mt="20px !important"
          fontSize="16px"
          fontWeight="medium"
        >
          <Box
            mr="40px"
            mb={{
              base: '10px',
              sm: 0,
            }}
            w={{
              base: '100%',
              sm: 'auto',
            }}>
            <Text color="tavisGy.20">Ⓒ Copyright 2022</Text>
          </Box>
          <Stack direction="row" spacing="40px">
            <Link
              href="/terms-of-service"
              color="tavisGy.20"
              _hover={{
                textDecoration: 'none',
                color: linkHoverColor,
              }}>
              Terms of Service
            </Link>
            <Link
              href="/privacy-policy"
              ml="34px"
              color="tavisGy.20"
              _hover={{
                textDecoration: 'none',
                color: linkHoverColor,
              }}>
              Privacy Policy
            </Link>
          </Stack>
        </Stack>
      </Container>
    </Flex>
  );
}
