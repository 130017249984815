export const GET_PURCHASE_HISTORY = 'purchase/getPurchaseHistory';
export const GET_PURCHASE_HISTORY_SUCCESS = 'purchase/getPurchaseHistorySuccess';

export const GET_PURCHASE_DETAILS = 'purchase/getPurchaseDetails';
export const GET_PURCHASE_DETAILS_SUCCESS = 'purchase/getPurchaseDetailsSuccess';

export const TERMINATE_SUBSCRIPTION = 'order/terminateSubscription';
export const TERMINATE_SUBSCRIPTION_SUCCESS = 'order/terminateSubscriptionSuccess';

export const RESET_PURCHASE_HISTORY = 'purchase/resetPurchaseHistory';
