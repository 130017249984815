import { CourseActionType, CourseState } from './models';
import {
  GET_COURSES,
  GET_COURSES_SUCCESS,
  GET_COURSES_ERROR,
  GET_COURSES_NETWORK_ERROR,
  GET_COURSE_DETAILS,
  GET_COURSE_DETAILS_SUCCESS,
  GET_COURSE_DETAILS_ERROR,
  GET_COURSE_DETAILS_NETWORK_ERROR,
  GET_MORE_COURSES,
  GET_MORE_COURSES_SUCCESS,
  RESET_COURSES,
} from './constants';

export const courseInitialState: CourseState = {
  // Course list
  courseList: null,
  courseListPagination: undefined,
  courseListLoading: false,
  courseListRefetching: false,
  courseListStatusCode: null,
  courseListNetworkError: false,

  // Course details
  courseDetails: null,
  courseDetailsLoading: false,
  courseDetailsStatusCode: null,
  courseDetailsNetworkError: false,
};

export const courseReducer = (
  state = courseInitialState,
  action: CourseActionType,
): CourseState => {
  switch (action.type) {
    case GET_COURSES:
      return {
        ...state,
        courseListLoading: true,
        courseListStatusCode: null,
        courseListNetworkError: false,
      };

    case GET_COURSES_SUCCESS:
      return {
        ...state,
        courseListLoading: false,
        courseList: action.payload.data.objects,
        courseListPagination: action.payload.pagination,
        courseListStatusCode: action.payload.code,
      };

    case GET_COURSES_ERROR:
      return {
        ...state,
        courseListLoading: false,
        courseListStatusCode: action.payload.code,
      };

    case GET_COURSES_NETWORK_ERROR:
      return {
        ...state,
        courseListLoading: false,
        courseListNetworkError: true,
      };

    case GET_MORE_COURSES:
      return {
        ...state,
        courseListStatusCode: null,
        courseListRefetching: true,
      };

    case GET_MORE_COURSES_SUCCESS: {
      if (!state.courseList) {
        return state;
      }

      return {
        ...state,
        courseListRefetching: false,
        courseList: [...state.courseList, ...action.payload.data.objects],
        courseListPagination: action.payload.pagination,
        courseListStatusCode: action.payload.code,
      };
    }

    case GET_COURSE_DETAILS: {
      return {
        ...state,
        courseDetails: null,
        courseDetailsLoading: true,
        courseDetailsStatusCode: null,
        courseDetailsNetworkError: false,
      };
    }

    case GET_COURSE_DETAILS_ERROR:
      return {
        ...state,
        courseDetailsLoading: false,
        courseDetailsStatusCode: action.payload.code,
      };

    case GET_COURSE_DETAILS_NETWORK_ERROR:
      return {
        ...state,
        courseDetailsLoading: false,
        courseDetailsNetworkError: true,
      };

    case GET_COURSE_DETAILS_SUCCESS: {
      return {
        ...state,
        courseDetailsLoading: false,
        courseDetails: action.payload.data.objects,
        courseDetailsStatusCode: action.payload.code,
      };
    }
    case RESET_COURSES:
      return courseInitialState;
    default:
      return state;
  }
};
