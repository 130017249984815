import { of } from 'rxjs';
import { ajax, AjaxResponse, AjaxError } from 'rxjs/ajax';
import { map, switchMap, catchError, takeUntil } from 'rxjs/operators';
import { combineEpics, ofType, ActionsObservable, Epic } from 'redux-observable';

import { API_URL } from 'src/constants/api';
import { ErrorResponse } from 'src/models/ErrorResponse';
import { LOGOUT } from '../auth/constants';
import {
  GetLevelsAction,
  GetLevelsSuccessAction,
  GetMoreReadingMaterialsAction,
  GetMoreReadingMaterialsSuccessAction,
  GetMoreRecordedLecturesAction,
  GetMoreRecordedLecturesSuccessAction,
  GetReadingMaterialsAction,
  GetReadingMaterialsSuccessAction,
  GetRecordedLecturesAction,
  GetRecordedLecturesSuccessAction,
  LevelsResponse,
  ReadingMaterialResponse,
  RecordedLectureResponse,
} from './models';
import {
  GET_LEVELS,
  GET_LEVELS_ERROR,
  GET_LEVELS_NETWORK_ERROR,
  GET_LEVELS_SUCCESS,
  GET_MORE_READING_MATERIALS,
  GET_MORE_READING_MATERIALS_SUCCESS,
  GET_MORE_RECORDED_LECTURES,
  GET_MORE_RECORDED_LECTURES_SUCCESS,
  GET_READING_MATERIALS,
  GET_READING_MATERIALS_ERROR,
  GET_READING_MATERIALS_NETWORK_ERROR,
  GET_READING_MATERIALS_SUCCESS,
  GET_RECORDED_LECTURES,
  GET_RECORDED_LECTURES_ERROR,
  GET_RECORDED_LECTURES_NETWORK_ERROR,
  GET_RECORDED_LECTURES_SUCCESS,
} from './constants';
import { toUrlString } from 'src/utils/queryString';

function getLevelsEpic(action$: ActionsObservable<GetLevelsAction>) {
  return action$.pipe(
    ofType(GET_LEVELS),
    switchMap((action) => {
      const query = action.courseId?.toString() || '';
      return ajax({
        url: API_URL + '/media/material/level?courseid=' + query,
        method: 'get',
        withCredentials: true,
      }).pipe(
        map((payload: AjaxResponse): GetLevelsSuccessAction => {
          return {
            type: GET_LEVELS_SUCCESS,
            payload: payload.response as LevelsResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: GET_LEVELS_NETWORK_ERROR,
            });
          }
          return of({
            type: GET_LEVELS_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function getReadingMaterialsEpic(action$: ActionsObservable<GetReadingMaterialsAction>) {
  return action$.pipe(
    ofType(GET_READING_MATERIALS),
    switchMap((action) => {
      const apiQuery = toUrlString(action.payload);
      return ajax({
        url: API_URL + '/media/material?' + apiQuery,
        method: 'get',
        withCredentials: true,
      }).pipe(
        map((payload: AjaxResponse): GetReadingMaterialsSuccessAction => {
          return {
            type: GET_READING_MATERIALS_SUCCESS,
            payload: {
              ...(payload.response as ReadingMaterialResponse),
              pagination: {
                page: Number(payload.xhr.getResponseHeader('x-page')),
                limit: Number(payload.xhr.getResponseHeader('x-limit')),
                totalPages: Number(payload.xhr.getResponseHeader('x-total-pages')),
                totalRecords: Number(payload.xhr.getResponseHeader('x-total-records')),
              },
            },
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: GET_READING_MATERIALS_NETWORK_ERROR,
            });
          }
          return of({
            type: GET_READING_MATERIALS_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function getMoreReadingMaterialsEpic(action$: ActionsObservable<GetMoreReadingMaterialsAction>) {
  return action$.pipe(
    ofType(GET_MORE_READING_MATERIALS),
    switchMap((action) => {
      const apiQuery = toUrlString(action.payload);
      return ajax({
        url: API_URL + '/media/material?' + apiQuery,
        method: 'get',
        withCredentials: true,
      }).pipe(
        map((payload: AjaxResponse): GetMoreReadingMaterialsSuccessAction => {
          return {
            type: GET_MORE_READING_MATERIALS_SUCCESS,
            payload: {
              ...(payload.response as ReadingMaterialResponse),
              pagination: {
                page: Number(payload.xhr.getResponseHeader('x-page')),
                limit: Number(payload.xhr.getResponseHeader('x-limit')),
                totalPages: Number(payload.xhr.getResponseHeader('x-total-pages')),
                totalRecords: Number(payload.xhr.getResponseHeader('x-total-records')),
              },
            },
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: GET_READING_MATERIALS_NETWORK_ERROR,
            });
          }
          return of({
            type: GET_READING_MATERIALS_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function getRecordedLecturesEpic(action$: ActionsObservable<GetRecordedLecturesAction>) {
  return action$.pipe(
    ofType(GET_RECORDED_LECTURES),
    switchMap((action) => {
      const apiQuery = toUrlString(action.payload);
      return ajax({
        url: API_URL + '/media/recorded?' + apiQuery,
        method: 'get',
        withCredentials: true,
      }).pipe(
        map((payload: AjaxResponse): GetRecordedLecturesSuccessAction => {
          return {
            type: GET_RECORDED_LECTURES_SUCCESS,
            payload: {
              ...(payload.response as RecordedLectureResponse),
              pagination: {
                page: Number(payload.xhr.getResponseHeader('x-page')),
                limit: Number(payload.xhr.getResponseHeader('x-limit')),
                totalPages: Number(payload.xhr.getResponseHeader('x-total-pages')),
                totalRecords: Number(payload.xhr.getResponseHeader('x-total-records')),
              },
            },
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: GET_RECORDED_LECTURES_NETWORK_ERROR,
            });
          }
          return of({
            type: GET_RECORDED_LECTURES_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function getMoreRecordedLecturesEpic(action$: ActionsObservable<GetMoreRecordedLecturesAction>) {
  return action$.pipe(
    ofType(GET_MORE_RECORDED_LECTURES),
    switchMap((action) => {
      const apiQuery = toUrlString(action.payload);
      return ajax({
        url: API_URL + '/media/recorded?' + apiQuery,
        method: 'get',
        withCredentials: true,
      }).pipe(
        map((payload: AjaxResponse): GetMoreRecordedLecturesSuccessAction => {
          return {
            type: GET_MORE_RECORDED_LECTURES_SUCCESS,
            payload: {
              ...(payload.response as RecordedLectureResponse),
              pagination: {
                page: Number(payload.xhr.getResponseHeader('x-page')),
                limit: Number(payload.xhr.getResponseHeader('x-limit')),
                totalPages: Number(payload.xhr.getResponseHeader('x-total-pages')),
                totalRecords: Number(payload.xhr.getResponseHeader('x-total-records')),
              },
            },
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: GET_RECORDED_LECTURES_NETWORK_ERROR,
            });
          }
          return of({
            type: GET_RECORDED_LECTURES_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

export const resourcesEpics: Epic = combineEpics(
  ...[
    getLevelsEpic,
    getReadingMaterialsEpic,
    getMoreReadingMaterialsEpic,
    getRecordedLecturesEpic,
    getMoreRecordedLecturesEpic,
  ],
);
