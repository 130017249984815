import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function SearchIcon(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <path
        d="M23.7168704,22.2559543 L17.0200203,15.4914373 C20.1727544,11.5167644 19.6696989,5.76864255 15.8745664,2.40300444 C12.0794339,-0.962633672 6.31714552,-0.770808057 2.75387637,2.83979001 C-0.809392772,6.45038808 -0.929667574,12.2192605 2.4800115,15.9754718 C5.88969057,19.7316831 11.6389683,20.1639 15.5710764,16.9596267 L22.2257827,23.6799574 C22.6175993,24.0913168 23.2679396,24.1083739 23.6807471,23.7181183 C24.0919086,23.3233385 24.108035,22.6705878 23.7168704,22.2559543 Z M9.51842439,17.03394 C5.92349789,17.0333382 2.82956524,14.4914512 2.1286104,10.962679 C1.42765556,7.43390683 3.31488494,3.90096474 6.63623085,2.52429724 C9.95757676,1.14762973 13.7880894,2.31062003 15.7853557,5.30208298 C17.7826219,8.29354593 17.3904302,12.2803997 14.8486113,14.8246262 C13.4391473,16.2466164 11.5197678,17.0450746 9.51842439,17.0419828 L9.51842439,17.03394 Z"
        id="Search_icon"
        fill="currentColor"
        fillRule="nonzero"
      ></path>
    </Icon>
  );
}
