import { Container, Text, Heading, Box } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
// Data of Privacy Policy can be updated or edited in below imported url
import { PpData } from 'src/data/privacypolicy';

export default function PrivacyPolicy() {
  return (
    <>
      <Helmet>
        <title>Tavis - Privacy Policy</title>
      </Helmet>
      <Box py={{ base: '40px', md: '125px' }} color="tavisGy.20">
        <Container maxW={'7xl'}>
          <Heading as="h1" size="2xl" mb="50px" color="tavisGy.10">
            {PpData.title}
          </Heading>
          <Text whiteSpace="pre-line" verticalAlign="bottom">
            {PpData.description}
          </Text>
          {PpData.content.map((content: any, index: number) => (
            <Box key={index} mt="30px">
              <Heading as="h5" size="md" fontSize="24px" mb="12px">
                {content.subtitle}
              </Heading>
              <Text whiteSpace="pre-line" verticalAlign="bottom">
                {content.description}
              </Text>
            </Box>
          ))}
        </Container>
      </Box>
    </>
  );
}
