import { of } from 'rxjs';
import { ajax, AjaxResponse, AjaxError } from 'rxjs/ajax';
import { map, switchMap, catchError, takeUntil } from 'rxjs/operators';
import { combineEpics, ofType, ActionsObservable, Epic, StateObservable } from 'redux-observable';

import { toUrlString } from 'src/utils/queryString';
import { API_URL } from 'src/constants/api';
import {
  GET_COURSES,
  GET_COURSES_ERROR,
  GET_COURSES_NETWORK_ERROR,
  GET_COURSES_SUCCESS,
  GET_COURSE_DETAILS,
  GET_COURSE_DETAILS_SUCCESS,
  GET_COURSE_DETAILS_ERROR,
  GET_COURSE_DETAILS_NETWORK_ERROR,
  GET_MORE_COURSES,
  GET_MORE_COURSES_SUCCESS,
} from './constants';
import {
  CourseDetailsResponse,
  CoursesResponse,
  GetCoursesAction,
  GetCoursesSuccessAction,
  GetCourseDetailsAction,
  GetCourseDetailsSuccessAction,
} from './models';
import { GetMoreCoursesAction, GetMoreCoursesSuccessAction } from './models/actions';
import { LOGOUT } from '../auth/constants';
import { ErrorResponse } from 'src/models/ErrorResponse';
import AppState from '../rootState';

function getCoursesEpic(action$: ActionsObservable<GetCoursesAction>) {
  return action$.pipe(
    ofType(GET_COURSES),
    switchMap((action) => {
      const apiQuery = toUrlString(action.payload);
      return ajax({
        url: API_URL + '/site/course?' + apiQuery,
        method: 'get',
        withCredentials: true,
      }).pipe(
        map((payload: AjaxResponse): GetCoursesSuccessAction => {
          return {
            type: GET_COURSES_SUCCESS,
            payload: {
              ...(payload.response as CoursesResponse),
              pagination: {
                page: Number(payload.xhr.getResponseHeader('x-page')),
                limit: Number(payload.xhr.getResponseHeader('x-limit')),
                totalPages: Number(payload.xhr.getResponseHeader('x-total-pages')),
                totalRecords: Number(payload.xhr.getResponseHeader('x-total-records')),
              },
            },
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: GET_COURSES_NETWORK_ERROR,
            });
          }
          return of({
            type: GET_COURSES_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function getMoreCoursesEpic(action$: ActionsObservable<GetMoreCoursesAction>) {
  return action$.pipe(
    ofType(GET_MORE_COURSES),
    switchMap((action) => {
      const apiQuery = toUrlString(action.payload);

      return ajax({
        url: API_URL + '/site/course?' + apiQuery,
        method: 'get',
        withCredentials: true,
      }).pipe(
        map((payload: AjaxResponse): GetMoreCoursesSuccessAction => {
          return {
            type: GET_MORE_COURSES_SUCCESS,
            payload: {
              ...(payload.response as CoursesResponse),
              pagination: {
                page: Number(payload.xhr.getResponseHeader('x-page')),
                limit: Number(payload.xhr.getResponseHeader('x-limit')),
                totalPages: Number(payload.xhr.getResponseHeader('x-total-pages')),
                totalRecords: Number(payload.xhr.getResponseHeader('x-total-records')),
              },
            },
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: GET_COURSES_NETWORK_ERROR,
            });
          }
          return of({
            type: GET_COURSES_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function getCourseDetailsEpic(
  action$: ActionsObservable<GetCourseDetailsAction>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    ofType(GET_COURSE_DETAILS),
    switchMap((action) => {
      return ajax({
        url: state$.value.auth.loginStatus
          ? `${API_URL}/course/${action.id}`
          : `${API_URL}/site/course/${action.id}`,
        method: 'get',
        withCredentials: true,
      }).pipe(
        map((payload: AjaxResponse): GetCourseDetailsSuccessAction => {
          return {
            type: GET_COURSE_DETAILS_SUCCESS,
            payload: payload.response as CourseDetailsResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: GET_COURSE_DETAILS_NETWORK_ERROR,
            });
          }
          return of({
            type: GET_COURSE_DETAILS_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

const epics = [getCoursesEpic, getCourseDetailsEpic, getMoreCoursesEpic];

export const courseEpics: Epic = combineEpics(...epics);
