import { of } from 'rxjs';
import { ajax, AjaxError, AjaxResponse } from 'rxjs/ajax';
import { catchError, map, switchMap } from 'rxjs/operators';
import { combineEpics, ofType, ActionsObservable, Epic } from 'redux-observable';
import { API_URL } from 'src/constants/api';
import * as ContactusConstants from './constants';
import * as ContactusModels from './models';

function submitMessageEpic(action$: ActionsObservable<ContactusModels.SubmitMessageAction>) {
  return action$.pipe(
    ofType(ContactusConstants.SUBMIT),
    switchMap((action) => {
      let formData = new FormData();
      const payload = action.payload;

      // Append payload object to form data
      for (const [key, value] of Object.entries(payload)) {
        formData.append(key, value);
      }

      return ajax({
        url: API_URL + '/site/contact-us',
        method: 'post',
        body: formData,
      }).pipe(
        map((payload: AjaxResponse): ContactusModels.SubmitMessageSuccessAction => {
          return {
            type: ContactusConstants.SUBMIT_SUCCESS,
            payload: payload.response as ContactusModels.SubmitMessageResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: ContactusConstants.SUBMIT_NETWORK_ERROR,
            });
          }
          return of({
            type: ContactusConstants.SUBMIT_ERROR,
            payload: error.response as ContactusModels.SubmitMessageErrorResponse,
          });
        }),
      );
    }),
  );
}

export const contactusEpics: Epic = combineEpics(...[submitMessageEpic]);
