export const ADD_TO_CART = 'cart/addToCart';
export const ADD_TO_CART_SUCCESS = 'cart/addToCartSuccess';
export const ADD_TO_CART_ERROR = 'cart/addToCartError';
export const ADD_TO_CART_NETWORK_ERROR = 'cart/addToCartNetworkError';
export const RESET_ADD_TO_CART = 'cart/resetAddToCart';

export const GET_CART = 'cart/getCart';
export const GET_CART_SUCCESS = 'cart/getCartSuccess';
export const GET_CART_ERROR = 'cart/getCartError';
export const GET_CART_NETWORK_ERROR = 'cart/getCartNetworkError';

export const GET_CUT_OFF_DATE = 'cart/getCutOffDate';
export const GET_CUT_OFF_DATE_SUCCESS = 'cart/getCutOffDateSuccess';
export const GET_CUT_OFF_DATE_ERROR = 'cart/getCutOffDateError';
export const GET_CUT_OFF_DATE_NETWORK_ERROR = 'cart/getCutOffDateNetworkError';

export const DELETE_CART_ITEM = 'cart/deleteCartItem';
export const DELETE_CART_ITEM_SUCCESS = 'cart/deleteCartItemSuccess';

export const GET_PEOPLE_ALSO_BUY = 'cart/peopleAlsoBuy';
export const GET_PEOPLE_ALSO_BUY_SUCCESS = 'cart/peopleAlsoBuySuccess';

export const APPLY_PROMO_CODE = 'cart/applyPromoCode';
export const APPLY_PROMO_CODE_SUCCESS = 'cart/applyPromoCodeSuccess';
export const APPLY_PROMO_CODE_ERROR = 'cart/applyPromoCodeError';
export const RESET_PROMO_CODE = 'cart/resetPromoCode';

export const RESET_PEOPLE_ALSO_BUY = 'cart/resetPeopleAlsoBuy';
