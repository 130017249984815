import React from 'react';
import { useGoogleLogin } from 'react-google-login';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Flex, Button } from '@chakra-ui/react';

import { GoogleIcon } from 'src/icons';
import { loginWithGoogle } from 'src/redux/auth/actions';

interface SignInWithGoogleProps {
  setFirstLogin: () => void;
}

function SignInWithGoogle({ setFirstLogin }: SignInWithGoogleProps): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const clientId = String(process.env.REACT_APP_GOOGLE_CLIENT_ID);

  const onSuccess = (res: any) => {
    setFirstLogin();
    const { sub, email, givenName, familyName } = res.profileObj;

    dispatch(
      loginWithGoogle({
        sub,
        email,
        givenName,
        familyName,
        role: 'student',
      }),
    );
  };

  const onFailure = (res: any) => {
    console.log('Login Failed: res:', res);
  };

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
  });

  return (
    <Button
      minW="250px"
      w="250px"
      color="black"
      bg="white"
      fontWeight="regular"
      rounded="full"
      justifyContent="flex-start"
      onClick={signIn}>
      <GoogleIcon />
      <Flex justifyContent="center" flex="1">
        {t('sign_in_with', { provider: 'Google' })}
      </Flex>
    </Button>
  );
}

export default SignInWithGoogle;
