import { AppActions } from '../rootActions';
import { CREATE_FREE_CLASS_ORDER, CREATE_ORDER, RESET_ORDER } from './constants';
import { CreateFreeClassOrderPayload, CreateOrderPayload } from './models/payload';

/**
 * Functions to be called in React components
 */
export const createOrder = (payload: CreateOrderPayload): AppActions => ({
  type: CREATE_ORDER,
  payload,
});

export const createFreeClassOrder = (payload: CreateFreeClassOrderPayload): AppActions => ({
  type: CREATE_FREE_CLASS_ORDER,
  payload: payload,
});

export const resetOrder = (): AppActions => ({ type: RESET_ORDER });
