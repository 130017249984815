import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function ShowIcon(props: IconProps): JSX.Element {
  return (
    <Icon {...props} viewBox="0 0 20 20">
      <path
        id="Icon_ionic-md-eye"
        data-name="Icon ionic-md-eye"
        d="M12.711,7.383A11.249,11.249,0,0,0,2.25,14.435a11.285,11.285,0,0,0,20.922,0A11.249,11.249,0,0,0,12.711,7.383Zm0,11.755a4.7,4.7,0,1,1,4.754-4.7A4.742,4.742,0,0,1,12.711,19.137Zm0-7.523a2.821,2.821,0,1,0,2.853,2.821A2.846,2.846,0,0,0,12.711,11.614Z"
        transform="translate(-2.25 -5.935)"
      />
    </Icon>
  );
}
