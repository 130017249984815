import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function GoogleIcon(props: IconProps): JSX.Element {
  return (
    <Icon viewBox="0 0 16.87 17.214" {...props}>
      <path
        id="Path_463"
        data-name="Path 463"
        d="M138.813,108.521a7.375,7.375,0,0,0-.182-1.76H130.55v3.194h4.743a4.206,4.206,0,0,1-1.76,2.792l-.016.107,2.555,1.979.177.018a8.415,8.415,0,0,0,2.563-6.331"
        transform="translate(-121.943 -99.722)"
        fill="#4285f4"
      />
      <path
        id="Path_464"
        data-name="Path 464"
        d="M21.614,163.275a8.2,8.2,0,0,0,5.7-2.085l-2.716-2.1a5.094,5.094,0,0,1-2.984.861,5.181,5.181,0,0,1-4.9-3.577l-.1.009-2.657,2.056-.035.1a8.6,8.6,0,0,0,7.689,4.743"
        transform="translate(-13.007 -146.061)"
        fill="#34a853"
      />
      <path
        id="Path_465"
        data-name="Path 465"
        d="M3.711,76.92a5.3,5.3,0,0,1-.287-1.7,5.568,5.568,0,0,1,.277-1.7l0-.114-2.69-2.089-.088.042a8.589,8.589,0,0,0,0,7.727L3.711,76.92"
        transform="translate(0 -66.611)"
        fill="#fbbc05"
      />
      <path
        id="Path_466"
        data-name="Path 466"
        d="M21.614,3.328A4.77,4.77,0,0,1,24.942,4.61l2.429-2.372A8.269,8.269,0,0,0,21.614,0a8.6,8.6,0,0,0-7.689,4.743L16.708,6.9a5.2,5.2,0,0,1,4.906-3.577"
        transform="translate(-13.007 0)"
        fill="#eb4335"
      />
    </Icon>
  );
}
