import React, { useEffect, useState } from 'react';
import { Button, Flex, Stack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { MyPurchasesBookIcon } from 'src/icons';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentDate, selectCutOffDate, selectCutOffDateStatusCode } from 'src/redux/cart/selectors';
import { getCutOffDate } from 'src/redux/cart/actions';
import useSessionExpired from './SessionExpired';
import { format } from 'date-fns';
import { selectFreeTrialExpiry } from 'src/redux/auth/selectors';
import { getFreeTrial } from 'src/utils/getFreeTrial';

function NoPurchase(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [freeTrial, setFreeTrial] = useState(false);

  const cutOffDate = useSelector(selectCutOffDate);
  const cutOffDateStatusCode = useSelector(selectCutOffDateStatusCode);
  const [date, setDate] = useState(new Date());

  const currentDate = useSelector(selectCurrentDate);
  const freeTrialExpiryDate = useSelector(selectFreeTrialExpiry);

  useEffect(() => {
    dispatch(getCutOffDate());
  }, [dispatch]);

  useEffect(() => {
    if (currentDate && freeTrialExpiryDate) {
      setFreeTrial(getFreeTrial(new Date(currentDate), freeTrialExpiryDate));
    }
  }, [currentDate, freeTrialExpiryDate]);

  useEffect(() => {
    if (cutOffDate) {
      setDate(new Date(date.getFullYear(), date.getMonth(), cutOffDate));
    }
  }, [cutOffDate]);

  useSessionExpired(cutOffDateStatusCode);

  return (
    <Flex h="100%" justifyContent="center" alignItems="center" flexDirection="column">
      <MyPurchasesBookIcon boxSize="300px" />
      <Stack direction="column" justify="center" align="center" h="100%">
        <Text fontSize="md">{t('no_purchases_yet')} :(</Text>
        {freeTrial && (
          <Text fontSize="md" w={{ base: 'auto', md: '500px' }} textAlign="center">
            *{t('no_purchases_free_trial_description', { date: format(date, 'do') })}.
          </Text>
        )}
        <Button
          mt="2"
          fontWeight="medium"
          background="#65CD7D"
          color="white"
          _hover={{ background: '#2F9B50' }}
          _active={{ background: '#2F9B50' }}
          onClick={() => history.push('/classes')}
        >
          {t('browse_classes')}
        </Button>
      </Stack>
    </Flex>
  );
}

export default NoPurchase;
