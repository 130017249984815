import { EventActionType, EventState } from './models';
import {
  GET_EVENTS,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_ERROR,
  GET_EVENTS_NETWORK_ERROR,
  GET_CLASSROOM_LINK,
  GET_CLASSROOM_LINK_SUCCESS,
  GET_CLASSROOM_LINK_ERROR,
  GET_CLASSROOM_LINK_NETWORK_ERROR,
  RESET_EVENTS,
} from './constants';

export const eventInitialState: EventState = {
  // Events
  events: null,
  eventsLoading: false,
  eventsStatusCode: null,
  eventsNetworkError: false,

  // Classroom Link
  link: null,
  ks: null,
  classroomLinkLoading: false,
  classroomLinkStatusCode: null,
  classroomLinkNetworkError: false,
};

export const eventReducer = (state = eventInitialState, action: EventActionType): EventState => {
  switch (action.type) {
    case GET_EVENTS:
      return {
        ...state,
        eventsLoading: true,
        eventsStatusCode: null,
        eventsNetworkError: false,
      };
    case GET_EVENTS_SUCCESS:
      return {
        ...state,
        eventsLoading: false,
        eventsStatusCode: action.payload.code,
        events: action.payload.data.objects,
      };
    case GET_EVENTS_ERROR:
      return {
        ...state,
        eventsLoading: false,
        eventsStatusCode: action.payload.code,
      };
    case GET_EVENTS_NETWORK_ERROR:
      return {
        ...state,
        eventsLoading: false,
        eventsNetworkError: true,
      };
    case RESET_EVENTS:
      return {
        ...state,
        events: null,
        eventsLoading: false,
        eventsStatusCode: null,
        eventsNetworkError: false,
      };
    case GET_CLASSROOM_LINK:
      return {
        ...state,
        classroomLinkLoading: true,
        classroomLinkStatusCode: null,
        classroomLinkNetworkError: false,
      };
    case GET_CLASSROOM_LINK_SUCCESS:
      return {
        ...state,
        classroomLinkLoading: false,
        link: action.payload.data.objects.link,
        ks: action.payload.data.objects.ks,
        classroomLinkStatusCode: action.payload.code,
      };
    case GET_CLASSROOM_LINK_ERROR:
      return {
        ...state,
        classroomLinkLoading: false,
        classroomLinkStatusCode: action.payload.code,
      };
    case GET_CLASSROOM_LINK_NETWORK_ERROR:
      return {
        ...state,
        classroomLinkLoading: false,
        classroomLinkNetworkError: true,
      };
    default:
      return state;
  }
};
