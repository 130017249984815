import { SubjectState } from './models';
import { GET_SUBJECT_LIST, GET_SUBJECT_LIST_SUCCESS } from './constants';
import { SubjectActionType } from './models';
import { Subject } from 'src/models/Subject';

export const subjectInitialState: SubjectState = {
  // Subject list
  subjectList: null,
  subjectListLoading: false,
  subjectListErrors: false,
};

export const subjectReducer = (
  state = subjectInitialState,
  action: SubjectActionType,
): SubjectState => {
  switch (action.type) {
    case GET_SUBJECT_LIST:
      return {
        ...state,
        subjectListLoading: true,
      };
    case GET_SUBJECT_LIST_SUCCESS: {
      const formattedSubjects = action.payload.data.objects.map(
        ({ id, subjectName }): Subject => ({
          id,
          name: subjectName,
        }),
      );
      return {
        ...state,
        subjectListLoading: false,
        subjectList: formattedSubjects,
      };
    }
    default:
      return state;
  }
};
