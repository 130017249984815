import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useDisclosure,
  Image,
  Container,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Avatar,
  Center,
  HStack,
  useMediaQuery,
  Img,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon, ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import TavisLogo from 'src/images/tavis_logo.svg';
import { CartIcon, SignInIcon } from 'src/icons';
import { getUserDetails, logout, resetUserDetails } from 'src/redux/auth/actions';
import { selectLoginRole, selectLoginStatus, selectMoodleSession, selectUserDetailsStatusCode } from 'src/redux/auth/selectors';
import { selectCartItemCount } from 'src/redux/cart/selectors';
import { selectProfile } from 'src/redux/profile/selectors';
import { getCart } from 'src/redux/cart/actions';
import { getMyProfile } from 'src/redux/profile/actions';
import User from 'src/images/User.svg';

export default function Navbar() {
  const { isOpen, onToggle } = useDisclosure();
  const dispatch = useDispatch();
  const [isLargerThanMd] = useMediaQuery('(min-width: 768px)')

  const loginStatus = useSelector(selectLoginStatus);
  const moodleSession = useSelector(selectMoodleSession);
  const profile = useSelector(selectProfile);
  const cartItemCount = useSelector(selectCartItemCount);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const userDetailsStatusCode = useSelector(selectUserDetailsStatusCode);
  const loginRole = useSelector(selectLoginRole);

  useEffect(() => {
    return () => {
      dispatch(resetUserDetails());
    };
  }, [dispatch]);

  useEffect(() => {
    if (loginStatus && moodleSession) {
      document.cookie = `MoodleSession=${moodleSession}; domain=${process.env.REACT_APP_BASE_URL}; path=/; secure; SameSite=None`;
      dispatch(getUserDetails());
    } else {
      setIsLoggedIn(false);
    }
  }, [dispatch, loginStatus, moodleSession]);

  useEffect(() => {
    if (userDetailsStatusCode === 200 && loginStatus && loginRole === 'student') {
      setIsLoggedIn(true);
      dispatch(getCart());
      if (!profile) {
        dispatch(getMyProfile());
      }
    } else if (userDetailsStatusCode === 200 || userDetailsStatusCode === 401) {
      dispatch(logout());
      setIsLoggedIn(false);
    }
  }, [userDetailsStatusCode, loginStatus, loginRole]);

  const onLogout = () => {
    const result = window.confirm('Are you sure you want to log out?');
    if (result === true) {
      dispatch(logout());
    }
  };

  const displayIcon = () => {
    if (cartItemCount !== null && cartItemCount !== 0) {
      return (
        <>
          {/* Cart Icon */}
          <CartIcon boxSize="35px" />
          {/* Cart Icon Counter */}
          <Box
            as="span"
            pos="absolute"
            px={2}
            py={1}
            fontSize="xs"
            fontWeight="bold"
            lineHeight="none"
            color="red.100"
            transform="translate(50%,-50%)"
            bg="red.600"
            rounded="full">
            {cartItemCount}
          </Box>
        </>
      );
    } else {
      return <CartIcon boxSize="35px" />;
    }
  };

  return (
    <Box>
      <Container maxW={'7xl'}>
        <Flex bg={'white'} minH={'70px'} py={{ base: 2 }} px={{ base: 4 }} align={'center'}>
          <Flex
            flex={{ base: 1, md: 'auto' }}
            ml={{ base: -2 }}
            display={{ base: 'flex', md: 'none' }}>
            <IconButton
              onClick={onToggle}
              icon={isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />}
              variant={'ghost'}
              aria-label={'Toggle Navigation'}
            />
          </Flex>
          <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
            <Link href={'/'}>
              <Image src={TavisLogo} alt="Tavis Logo" htmlWidth="150" htmlHeight="25" />
            </Link>
            <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
              <DesktopNav />
            </Flex>
          </Flex>

          <Stack flex={{ base: 1, md: 0 }} justify={'flex-end'} direction={'row'} spacing={6}>
            {/* Not Logged In */}
            <HStack spacing={6} display={!isLoggedIn ? 'flex' : 'none'}>
              {isLargerThanMd ? (
                <Button
                  as={'a'}
                  fontSize={{
                    base: '12px',
                    md: 20,
                  }}
                  fontWeight={600}
                  color={'white'}
                  bg={'tavisG.20'}
                  href={'/signin'}
                  _hover={{
                    bg: 'tavisG.30',
                  }}
                  _focus={{
                    boxShadow: 'none',
                  }}
                  px={{ base: 1, md: 5 }}
                >
                  Sign In / Register
                </Button>
              ) : (
                <IconButton
                  as="a"
                  href={'/signin'}
                  icon={<SignInIcon boxSize="25px" />}
                  bg={'tavisG.20'}
                  aria-label="Sign In"
                  _hover={{
                    bg: 'tavisG.30',
                  }}
                />
              )}
            </HStack>
            {/* Logged In */}
            <Box display={isLoggedIn ? 'flex' : 'none'}>
              <Link href="/subscription">
                <IconButton
                  aria-label="cart"
                  bg="white"
                  mr="20px"
                  _hover={{ bg: 'none' }}
                  _active={{ bg: 'none' }}
                  icon={displayIcon()}
                />
              </Link>
              <Menu>
                <MenuButton
                  as={Button}
                  rounded={'full'}
                  variant={'link'}
                  cursor={'pointer'}
                  minW={0}
                  _focus={{
                    boxShadow: 'none',
                  }}>
                  <Avatar size={'sm'} src={profile?.profile_url || User} bg="white" />
                </MenuButton>
                <MenuList alignItems={'center'} zIndex={'3'}>
                  <br />
                  <Center>
                    <Avatar size={'2xl'} src={profile?.profile_url || User} bg="white" />
                  </Center>
                  <br />
                  <Center>
                    <p>{isLoggedIn ? profile?.fullname : ''}</p>
                  </Center>
                  <br />
                  <MenuDivider />
                  <MenuItem>
                    <Link _hover={{ textDecoration: 'none' }} href="/profile">
                      Account Settings
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={onLogout}>Logout</MenuItem>
                </MenuList>
              </Menu>
            </Box>
          </Stack>
        </Flex>

        <Collapse in={isOpen} animateOpacity>
          <MobileNav />
        </Collapse>
      </Container>
    </Box>
  );
}

const DesktopNav = () => {
  const linkHoverColor = useColorModeValue('gray.800', 'white');
  const popoverContentBgColor = useColorModeValue('white', 'gray.800');

  return (
    <Stack direction={'row'} spacing={4}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger>
              <Link
                p={2}
                href={navItem.href ?? '#'}
                fontSize={20}
                fontWeight={500}
                lineHeight={2}
                color={'tavisGy.20'}
                _hover={{
                  textDecoration: 'none',
                  color: linkHoverColor,
                }}>
                {navItem.label}
              </Link>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={'xl'}
                bg={popoverContentBgColor}
                p={4}
                rounded={'xl'}
                minW={'sm'}>
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
  return (
    <Link
      href={href}
      role={'group'}
      display={'block'}
      p={2}
      rounded={'md'}
      _hover={{ bg: 'tavisG.20' }}>
      <Stack direction={'row'} align={'center'}>
        <Box>
          <Text transition={'all .3s ease'} _groupHover={{ color: 'white' }} fontWeight={500}>
            {label}
          </Text>
          <Text fontSize={'sm'}>{subLabel}</Text>
        </Box>
        <Flex
          transition={'all .3s ease'}
          transform={'translateX(-10px)'}
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify={'flex-end'}
          align={'center'}
          flex={1}>
          <Icon color={'white'} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
};

const MobileNav = () => {
  return (
    <Stack bg={'white'} p={4} display={{ md: 'none' }}>
      {MOBILE_NAV_ITEMS.map((mobileNavItem) => (
        <MobileNavItem key={mobileNavItem.label} {...mobileNavItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure();
  const profile = useSelector(selectProfile);

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        href={href ?? '#'}
        justify={'space-between'}
        align={'center'}
        _hover={{
          textDecoration: 'none',
        }}>
        <Text
          fontWeight={600}
          color={'tavisGy.20'}
          display={profile?.fullname && label === 'Log In' ? 'none' : 'block'}>
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={'all .25s ease-in-out'}
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={'solid'}
          borderColor={'tavisGy.20'}
          align={'start'}>
          {children &&
            children.map((child) => (
              <Link key={child.label} py={2} href={child.href}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
}

const NAV_ITEMS: Array<NavItem> = [
  {
    label: 'Classes',
    // href: '/subjects',
    children: [
      {
        label: 'Classes',
        href: '/classes'
      },
      // {
      //   label: 'Pricing Plan',
      //   href: '/pricing-plan'
      // },
      {
        label: 'Our Tutors',
        href: '/tutors'
      },
    ],
  },
  {
    label: 'Dashboard',
    href: '/dashboard',
  },
];

const MOBILE_NAV_ITEMS: Array<NavItem> = [
  ...NAV_ITEMS,
];
