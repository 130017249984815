import { of } from 'rxjs';
import { ajax, AjaxResponse, AjaxError } from 'rxjs/ajax';
import { map, switchMap, catchError, takeUntil } from 'rxjs/operators';
import { combineEpics, ofType, ActionsObservable, Epic } from 'redux-observable';

import * as HomeworkConstants from './constants';
import {
  BookmarkQuestionAction,
  BookmarkQuestionSuccessAction,
  BookmarkResponse,
  EndHomeworkAction,
  EndHomeworkResponse,
  EndHomeworkSuccessAction,
  GetHomeworkListAction,
  GetHomeworkListSuccessAction,
  GetHomeworkQuestionsAction,
  GetHomeworkQuestionsSuccessAction,
  GetHomeworkRecordAction,
  GetHomeworkRecordsAction,
  GetHomeworkRecordsSuccessAction,
  GetHomeworkRecordSuccessAction,
  HomeworkListResponse,
  HomeworkQuestionsResponse,
  HomeworkRecordResponse,
  HomeworkRecordsResponse,
  SaveAnswerAction,
  SaveAnswerResponse,
  SaveAnswerSuccessAction,
  UpdateTimerAction,
  UpdateTimerResponse,
  UpdateTimerSuccessAction,
} from './models';
import { API_URL } from 'src/constants/api';
import { ErrorResponse } from 'src/models/ErrorResponse';
import { LOGOUT } from '../auth/constants';

function getHomeworkListEpic(action$: ActionsObservable<GetHomeworkListAction>) {
  return action$.pipe(
    ofType(HomeworkConstants.GET_HOMEWORK_LIST),
    switchMap(() => {
      return ajax({
        url: API_URL + '/homework/me',
        method: 'get',
        withCredentials: true,
      }).pipe(
        map((payload: AjaxResponse): GetHomeworkListSuccessAction => {
          return {
            type: HomeworkConstants.GET_HOMEWORK_LIST_SUCCESS,
            payload: payload.response as HomeworkListResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: HomeworkConstants.GET_HOMEWORK_LIST_NETWORK_ERROR,
            });
          }
          return of({
            type: HomeworkConstants.GET_HOMEWORK_LIST_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function getHomeworkRecordsEpic(action$: ActionsObservable<GetHomeworkRecordsAction>) {
  return action$.pipe(
    ofType(HomeworkConstants.GET_HOMEWORK_RECORDS),
    switchMap((action) => {
      return ajax({
        url: API_URL + '/homework/review/' + action.homeworkId.toString(),
        method: 'get',
        withCredentials: true,
      }).pipe(
        map((payload: AjaxResponse): GetHomeworkRecordsSuccessAction => {
          return {
            type: HomeworkConstants.GET_HOMEWORK_RECORDS_SUCCESS,
            payload: payload.response as HomeworkRecordsResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: HomeworkConstants.GET_HOMEWORK_RECORDS_NETWORK_ERROR,
            });
          }
          return of({
            type: HomeworkConstants.GET_HOMEWORK_RECORDS_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function getHomeworkRecordEpic(action$: ActionsObservable<GetHomeworkRecordAction>) {
  return action$.pipe(
    ofType(HomeworkConstants.GET_HOMEWORK_RECORD),
    switchMap((action) => {
      return ajax({
        url: API_URL + '/homework/attempt/' + action.attemptId.toString(),
        method: 'get',
        withCredentials: true,
      }).pipe(
        map((payload: AjaxResponse): GetHomeworkRecordSuccessAction => {
          return {
            type: HomeworkConstants.GET_HOMEWORK_RECORD_SUCCESS,
            payload: payload.response as HomeworkRecordResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: HomeworkConstants.GET_HOMEWORK_RECORD_NETWORK_ERROR,
            });
          }
          return of({
            type: HomeworkConstants.GET_HOMEWORK_RECORD_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function getHomeworkQuestionsEpic(action$: ActionsObservable<GetHomeworkQuestionsAction>) {
  return action$.pipe(
    ofType(HomeworkConstants.GET_HOMEWORK_QUESTIONS),
    switchMap((action) => {
      return ajax({
        url: API_URL + '/homework/me/' + action.homeworkId.toString(),
        method: 'get',
        withCredentials: true,
      }).pipe(
        map((payload: AjaxResponse): GetHomeworkQuestionsSuccessAction => {
          return {
            type: HomeworkConstants.GET_HOMEWORK_QUESTIONS_SUCCESS,
            payload: payload.response as HomeworkQuestionsResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: HomeworkConstants.GET_HOMEWORK_QUESTIONS_NETWORK_ERROR,
            });
          }
          return of({
            type: HomeworkConstants.GET_HOMEWORK_QUESTIONS_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function bookmarkQuestionsEpic(action$: ActionsObservable<BookmarkQuestionAction>) {
  return action$.pipe(
    ofType(HomeworkConstants.BOOKMARK_QUESTION),
    switchMap((action) => {
      return ajax({
        url: API_URL + '/homeworkquestion/bookmark',
        method: 'post',
        withCredentials: true,
        body: {
          questionId: action.payload.questionId,
          bookmark: action.payload.bookmark,
        },
        headers: {
          'content-type': 'application/json;charset=UTF-8',
        },
      }).pipe(
        map((payload: AjaxResponse): BookmarkQuestionSuccessAction => {
          return {
            type: HomeworkConstants.BOOKMARK_QUESTION_SUCCESS,
            payload: payload.response as BookmarkResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: HomeworkConstants.BOOKMARK_QUESTION_NETWORK_ERROR,
            });
          }
          return of({
            type: HomeworkConstants.BOOKMARK_QUESTION_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function saveAnswerEpic(action$: ActionsObservable<SaveAnswerAction>) {
  return action$.pipe(
    ofType(HomeworkConstants.SAVE_ANSWER),
    switchMap((action) => {
      return ajax({
        url: API_URL + '/homeworkquestion/answer',
        method: 'post',
        withCredentials: true,
        body: {
          questionId: action.payload.questionId,
          answer: action.payload.answer.join(','),
          totalTime: action.payload.totalTime,
          mark: action.payload.mark,
          attemptId: action.payload.attemptId,
        },
        headers: {
          'content-type': 'application/json;charset=UTF-8',
        },
      }).pipe(
        map((payload: AjaxResponse): SaveAnswerSuccessAction => {
          return {
            type: HomeworkConstants.SAVE_ANSWER_SUCCESS,
            payload: payload.response as SaveAnswerResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: HomeworkConstants.SAVE_ANSWER_NETWORK_ERROR,
            });
          }
          return of({
            type: HomeworkConstants.SAVE_ANSWER_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function endHomeworkEpic(action$: ActionsObservable<EndHomeworkAction>) {
  return action$.pipe(
    ofType(HomeworkConstants.END_HOMEWORK),
    switchMap((action) => {
      return ajax({
        url: API_URL + '/homework/end',
        method: 'post',
        withCredentials: true,
        body: {
          totalTime: action.payload.totalTime,
          attemptId: action.payload.attemptId,
          homeworkId: action.payload.homeworkId,
        },
        headers: {
          'content-type': 'application/json;charset=UTF-8',
        },
      }).pipe(
        map((payload: AjaxResponse): EndHomeworkSuccessAction => {
          return {
            type: HomeworkConstants.END_HOMEWORK_SUCCESS,
            payload: payload.response as EndHomeworkResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: HomeworkConstants.END_HOMEWORK_NETWORK_ERROR,
            });
          }
          return of({
            type: HomeworkConstants.END_HOMEWORK_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function updateTimerEpic(action$: ActionsObservable<UpdateTimerAction>) {
  return action$.pipe(
    ofType(HomeworkConstants.UPDATE_TIMER),
    switchMap((action) => {
      return ajax({
        url: API_URL + '/homework/update-timer',
        method: 'post',
        withCredentials: true,
        body: {
          totalTime: action.payload.totalTime,
          attemptId: action.payload.attemptId,
          homeworkId: action.payload.homeworkId,
        },
        headers: {
          'content-type': 'application/json;charset=UTF-8',
        },
      }).pipe(
        map((payload: AjaxResponse): UpdateTimerSuccessAction => {
          return {
            type: HomeworkConstants.UPDATE_TIMER_SUCCESS,
            payload: payload.response as UpdateTimerResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: HomeworkConstants.UPDATE_TIMER_NETWORK_ERROR,
            });
          }
          return of({
            type: HomeworkConstants.UPDATE_TIMER_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

export const homeworkEpics: Epic = combineEpics(
  ...[
    getHomeworkListEpic,
    getHomeworkRecordsEpic,
    getHomeworkRecordEpic,
    getHomeworkQuestionsEpic,
    bookmarkQuestionsEpic,
    saveAnswerEpic,
    endHomeworkEpic,
    updateTimerEpic,
  ],
);
