export const GET_HOMEWORK_LIST = 'homework/getHomeworkList';
export const GET_HOMEWORK_LIST_SUCCESS = 'homework/getHomeworkListSuccess';
export const GET_HOMEWORK_LIST_ERROR = 'homework/getHomeworkListError';
export const GET_HOMEWORK_LIST_NETWORK_ERROR = 'homework/getHomeworkListNetworkError';

export const GET_HOMEWORK_RECORDS = 'homework/getHomeworkRecords';
export const GET_HOMEWORK_RECORDS_SUCCESS = 'homework/getHomeworkRecordsSuccess';
export const GET_HOMEWORK_RECORDS_ERROR = 'homework/getHomeworkRecordsError';
export const GET_HOMEWORK_RECORDS_NETWORK_ERROR = 'homework/getHomeworkRecordsNetworkError';
export const RESET_HOMEWORK_RECORDS = 'homework/resetHomeworkRecords';

export const GET_HOMEWORK_RECORD = 'homework/getHomeworkRecord';
export const GET_HOMEWORK_RECORD_SUCCESS = 'homework/getHomeworkRecordSuccess';
export const GET_HOMEWORK_RECORD_ERROR = 'homework/getHomeworkRecordError';
export const GET_HOMEWORK_RECORD_NETWORK_ERROR = 'homework/getHomeworkRecordNetworkError';

export const GET_HOMEWORK_QUESTIONS = 'homework/getQuestions';
export const GET_HOMEWORK_QUESTIONS_SUCCESS = 'homework/getQuestionsSuccess';
export const GET_HOMEWORK_QUESTIONS_ERROR = 'homework/getQuestionsError';
export const GET_HOMEWORK_QUESTIONS_NETWORK_ERROR = 'homework/getQuestionsNetworkError';

export const BOOKMARK_QUESTION = 'homework/bookmarkQuestion';
export const BOOKMARK_QUESTION_SUCCESS = 'homework/bookmarkQuestionSuccess';
export const BOOKMARK_QUESTION_ERROR = 'homework/bookmarkQuestionError';
export const BOOKMARK_QUESTION_NETWORK_ERROR = 'homework/bookmarkQuestionNetworkError';
export const RESET_BOOKMARK = 'homework/resetBookmarkQuestion';

export const SAVE_ANSWER = 'homework/saveAnswer';
export const SAVE_ANSWER_SUCCESS = 'homework/saveAnswerSuccess';
export const SAVE_ANSWER_ERROR = 'homework/saveAnswerError';
export const SAVE_ANSWER_NETWORK_ERROR = 'homework/saveAnswerNetworkError';
export const RESET_SAVE_ANSWER = 'homework/resetSaveAnswer';

export const END_HOMEWORK = 'homework/endHomework';
export const END_HOMEWORK_SUCCESS = 'homework/endHomeworkSuccess';
export const END_HOMEWORK_ERROR = 'homework/endHomeworkError';
export const END_HOMEWORK_NETWORK_ERROR = 'homework/endHomeworkNetworkError';
export const RESET_END_HOMEWORK = 'homework/resetEndHomework';

export const UPDATE_TIMER = 'homework/updateTimer';
export const UPDATE_TIMER_SUCCESS = 'homework/updateTimerSuccess';
export const UPDATE_TIMER_ERROR = 'homework/updateTimerError';
export const UPDATE_TIMER_NETWORK_ERROR = 'homework/updateTimerNetworkError';
