import { Event } from 'src/models/Event';

import { EventState } from './models';
import AppState from '../rootState';

const selectEventState = (state: AppState): EventState => state.event;

export const selectEvents = (state: AppState): Event[] | null => selectEventState(state).events;
export const selectEventsStatusCode = (state: AppState): number | null =>
  selectEventState(state).eventsStatusCode;
export const selectEventsNetworkError = (state: AppState): boolean =>
  selectEventState(state).eventsNetworkError;
export const selectEventsLoading = (state: AppState): boolean | undefined =>
  selectEventState(state).eventsLoading;
export const selectClassroomLink = (state: AppState): string | null => selectEventState(state).link;
export const selectClassroomKs = (state: AppState): string | null => selectEventState(state).ks;
export const selectClassroomLinkStatusCode = (state: AppState): number | null =>
  selectEventState(state).classroomLinkStatusCode;
export const selectClassroomLinkNetworkError = (state: AppState): boolean =>
  selectEventState(state).classroomLinkNetworkError;
