import { HomeworkActionType, HomeworkState } from './models';
import * as HomeworkConstants from './constants';

export const homeworkInitialState: HomeworkState = {
  // Homework list
  homeworkList: null,
  homeworkListLoading: false,
  homeworkListStatusCode: null,
  homeworkListNetworkError: false,

  // Homework Records
  homeworkRecords: null,
  homeworkRecordsLoading: false,
  homeworkRecordsStatusCode: null,
  homeworkRecordsNetworkError: false,

  // Homework Record
  homeworkRecordQuestions: null,
  homeworkRecordAttemptData: null,
  homeworkRecordLoading: false,
  homeworkRecordStatusCode: null,
  homeworkRecordNetworkError: false,

  // Questions
  questions: null,
  questionsLoading: false,
  questionsStatusCode: null,
  questionsNetworkError: false,

  // Bookmark
  bookmarkLoading: false,
  bookmarkStatusCode: null,
  bookmarkNetworkError: false,

  // Save Answer
  saveAnswerLoading: false,
  saveAnswerStatusCode: null,
  saveAnswerNetworkError: false,

  // Homework Summary
  homeworkSummary: null,
  homeworkSummaryLoading: false,
  homeworkSummaryStatusCode: null,
  homeworkSummaryNetworkError: false,

  // Update timer
  updateTimerLoading: false,
  updateTimerStatusCode: null,
  updateTimerNetworkError: false,
};

export const homeworkReducer = (
  state = homeworkInitialState,
  action: HomeworkActionType,
): HomeworkState => {
  switch (action.type) {
    case HomeworkConstants.GET_HOMEWORK_LIST:
      return {
        ...state,
        homeworkListLoading: true,
        homeworkListStatusCode: null,
        homeworkListNetworkError: false,
      };
    case HomeworkConstants.GET_HOMEWORK_LIST_SUCCESS:
      return {
        ...state,
        homeworkListLoading: false,
        homeworkListStatusCode: action.payload.code,
        homeworkList: action.payload.data.objects,
      };
    case HomeworkConstants.GET_HOMEWORK_LIST_ERROR:
      return {
        ...state,
        homeworkListLoading: false,
        homeworkListStatusCode: action.payload.code,
      };
    case HomeworkConstants.GET_HOMEWORK_LIST_NETWORK_ERROR:
      return {
        ...state,
        homeworkListLoading: false,
        homeworkListNetworkError: true,
      };
    case HomeworkConstants.GET_HOMEWORK_RECORDS:
      return {
        ...state,
        homeworkRecordsLoading: true,
      };
    case HomeworkConstants.GET_HOMEWORK_RECORDS_SUCCESS:
      return {
        ...state,
        homeworkRecordsLoading: false,
        homeworkRecordsStatusCode: action.payload.code,
        homeworkRecords: action.payload.data.objects,
      };
    case HomeworkConstants.GET_HOMEWORK_RECORDS_ERROR:
      return {
        ...state,
        homeworkRecordsLoading: false,
        homeworkRecordsStatusCode: action.payload.code,
      };
    case HomeworkConstants.GET_HOMEWORK_RECORDS_NETWORK_ERROR:
      return {
        ...state,
        homeworkRecordsLoading: false,
        homeworkRecordsNetworkError: true,
      };
    case HomeworkConstants.RESET_HOMEWORK_RECORDS:
      return {
        ...state,
        homeworkRecords: null,
        homeworkRecordsLoading: false,
        homeworkRecordsStatusCode: null,
        homeworkRecordsNetworkError: false,
      };
    case HomeworkConstants.GET_HOMEWORK_RECORD:
      return {
        ...state,
        homeworkRecordQuestions: null,
        homeworkRecordAttemptData: null,
        homeworkRecordLoading: true,
        homeworkRecordStatusCode: null,
        homeworkRecordNetworkError: false,
      };
    case HomeworkConstants.GET_HOMEWORK_RECORD_SUCCESS:
      return {
        ...state,
        homeworkRecordQuestions: action.payload.data.objects.questions,
        homeworkRecordAttemptData: action.payload.data.objects.attemptData,
        homeworkRecordLoading: false,
        homeworkRecordStatusCode: action.payload.code,
      };
    case HomeworkConstants.GET_HOMEWORK_RECORD_ERROR:
      return {
        ...state,
        homeworkRecordLoading: false,
        homeworkRecordStatusCode: action.payload.code,
      };
    case HomeworkConstants.GET_HOMEWORK_RECORD_NETWORK_ERROR:
      return {
        ...state,
        homeworkRecordLoading: false,
        homeworkRecordNetworkError: true,
      };
    case HomeworkConstants.GET_HOMEWORK_QUESTIONS:
      return {
        ...state,
        questionsLoading: true,
        questionsStatusCode: null,
        questionsNetworkError: false,
      };
    case HomeworkConstants.GET_HOMEWORK_QUESTIONS_SUCCESS:
      return {
        ...state,
        questionsLoading: false,
        questionsStatusCode: action.payload.code,
        questions: action.payload.data.objects,
      };
    case HomeworkConstants.GET_HOMEWORK_QUESTIONS_ERROR:
      return {
        ...state,
        questionsLoading: false,
        questionsStatusCode: action.payload.code,
      };
    case HomeworkConstants.GET_HOMEWORK_QUESTIONS_NETWORK_ERROR:
      return {
        ...state,
        questionsLoading: false,
        questionsNetworkError: true,
      };
    case HomeworkConstants.BOOKMARK_QUESTION:
      return {
        ...state,
        bookmarkLoading: true,
        questions:
          state.questions?.map((item) =>
            item.id !== action.payload.questionId
              ? item
              : { ...item, bookmark: action.payload.bookmark },
          ) || null,
      };
    case HomeworkConstants.BOOKMARK_QUESTION_SUCCESS:
      return {
        ...state,
        bookmarkLoading: false,
        bookmarkStatusCode: action.payload.code,
      };
    case HomeworkConstants.BOOKMARK_QUESTION_ERROR:
      return {
        ...state,
        bookmarkLoading: false,
        bookmarkStatusCode: action.payload.code,
      };
    case HomeworkConstants.BOOKMARK_QUESTION_NETWORK_ERROR:
      return {
        ...state,
        bookmarkLoading: false,
        bookmarkNetworkError: true,
      };
    case HomeworkConstants.RESET_BOOKMARK:
      return {
        ...state,
        bookmarkLoading: false,
        bookmarkStatusCode: null,
        bookmarkNetworkError: false,
      };
    case HomeworkConstants.SAVE_ANSWER:
      return {
        ...state,
        saveAnswerLoading: true,
        questions:
          state.questions?.map((item) =>
            item.id !== action.payload.questionId
              ? item
              : { ...item, answer: action.payload.answer, mark: action.payload.mark },
          ) || null,
      };
    case HomeworkConstants.SAVE_ANSWER_SUCCESS:
      return {
        ...state,
        saveAnswerLoading: false,
        saveAnswerStatusCode: action.payload.code,
      };
    case HomeworkConstants.SAVE_ANSWER_ERROR:
      return {
        ...state,
        saveAnswerLoading: false,
        saveAnswerStatusCode: action.payload.code,
      };
    case HomeworkConstants.SAVE_ANSWER_NETWORK_ERROR:
      return {
        ...state,
        saveAnswerLoading: false,
        saveAnswerNetworkError: true,
      };
    case HomeworkConstants.RESET_SAVE_ANSWER:
      return {
        ...state,
        saveAnswerLoading: false,
        saveAnswerStatusCode: null,
        saveAnswerNetworkError: false,
      };
    case HomeworkConstants.END_HOMEWORK:
      return {
        ...state,
        homeworkSummaryLoading: true,
      };
    case HomeworkConstants.END_HOMEWORK_SUCCESS:
      return {
        ...state,
        homeworkSummary: action.payload.data.objects,
        homeworkSummaryLoading: false,
        homeworkSummaryStatusCode: action.payload.code,
      };
    case HomeworkConstants.END_HOMEWORK_ERROR:
      return {
        ...state,
        homeworkSummaryLoading: false,
        homeworkSummaryStatusCode: action.payload.code,
      };
    case HomeworkConstants.END_HOMEWORK_NETWORK_ERROR:
      return {
        ...state,
        homeworkSummaryLoading: false,
        homeworkSummaryNetworkError: true,
      };
    case HomeworkConstants.RESET_END_HOMEWORK:
      return {
        ...state,
        homeworkSummary: null,
        homeworkSummaryLoading: false,
        homeworkSummaryStatusCode: null,
        homeworkSummaryNetworkError: false,
      };
    case HomeworkConstants.UPDATE_TIMER:
      return {
        ...state,
        updateTimerLoading: true,
        updateTimerStatusCode: null,
        updateTimerNetworkError: false,
      };
    case HomeworkConstants.UPDATE_TIMER_SUCCESS:
      return {
        ...state,
        updateTimerLoading: false,
        updateTimerStatusCode: action.payload.code,
      };
    case HomeworkConstants.UPDATE_TIMER_ERROR:
      return {
        ...state,
        updateTimerLoading: false,
        updateTimerStatusCode: action.payload.code,
      };
    case HomeworkConstants.UPDATE_TIMER_NETWORK_ERROR:
      return {
        ...state,
        updateTimerLoading: false,
        updateTimerNetworkError: true,
      };
    default:
      return state;
  }
};
