import React from 'react';
import { Heading, Divider, HStack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import RightTopbar from './RightTopbar';

interface NavigateTopbarProps {
  currentTab: string;
  firstTitle: string;
  secondTitle: string;
  firstTo: string;
  secondTo: string;
  keywordSearch?: {
    keyword: string | null;
    setKeyword: React.Dispatch<React.SetStateAction<string | null>>;
  };
  firstTitleWidth?: string;
  secondTitleWidth?: string;
  isWithSearchbar?: boolean;
}

function NavigateTopbar({
  currentTab,
  firstTitle,
  firstTo,
  secondTitle,
  secondTo,
  keywordSearch,
  firstTitleWidth,
  secondTitleWidth,
  isWithSearchbar = true,
}: NavigateTopbarProps): JSX.Element {
  return (
    <RightTopbar keywordSearch={keywordSearch} isSearchable={isWithSearchbar}>
      <HStack
        spacing={5}
        overflowX={{
          base: 'scroll',
          sm: 'unset',
        }}
        overflowY="hidden"
        mb={{
          base: 2,
          md: 0,
        }}
        width={{
          base: '100%',
          md: 'auto',
        }}>
        <Link to={firstTo}>
          {currentTab === firstTitle ? (
            <Heading
              fontSize={{
                base: 28,
                md: 30,
              }}
              fontWeight="semibold"
              minWidth={firstTitleWidth ? firstTitleWidth : 'auto'}>
              {firstTitle}
            </Heading>
          ) : (
            <Heading
              fontSize={{
                base: 28,
                md: 30,
              }}
              fontWeight="semibold"
              color="#AFAFAF"
              minWidth={firstTitleWidth ? firstTitleWidth : 'auto'}>
              {firstTitle}
            </Heading>
          )}
        </Link>
        <Divider orientation="vertical" borderColor="#707070" />
        <Link to={secondTo}>
          {currentTab === secondTitle ? (
            <Heading
              fontSize={{
                base: 28,
                md: 30,
              }}
              fontWeight="semibold"
              minWidth={secondTitleWidth ? secondTitleWidth : 'auto'}>
              {secondTitle}
            </Heading>
          ) : (
            <Heading
              fontSize={{
                base: 28,
                md: 30,
              }}
              fontWeight="semibold"
              color="#AFAFAF"
              minWidth={secondTitleWidth ? secondTitleWidth : 'auto'}>
              {secondTitle}
            </Heading>
          )}
        </Link>
      </HStack>
    </RightTopbar>
  );
}

export default NavigateTopbar;
