import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Flex,
  Spacer,
  InputGroup,
  Input,
  InputRightElement,
  HStack,
  Divider,
} from '@chakra-ui/react';
import { SearchIcon } from 'src/icons';
import VerifyEmailWarning from 'src/containers/Timetable/VerifyEmailWarning';

interface RightTopbarProps {
  children: React.ReactNode;
  showVerifyEmailWarning?: boolean;
  keywordSearch?: {
    keyword: string | null;
    setKeyword: React.Dispatch<React.SetStateAction<string | null>>;
  };
  isSearchable?: boolean;
}

function RightTopbar({
  children,
  keywordSearch,
  isSearchable = true,
  showVerifyEmailWarning,
}: RightTopbarProps): JSX.Element {
  const { t } = useTranslation();
  // TODO: Remove this future. POC
  // const profile = useSelector(selectProfile);
  // if (!profile) return <Loader />;

  const submitKeyword = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    const element = document.getElementById('search') as HTMLInputElement;
    if (keywordSearch && element.value.length > 0) {
      keywordSearch.setKeyword(element.value);
    }
  };

  const onChangeSearch = () => {
    const element = document.getElementById('search') as HTMLInputElement;
    if (element.value.length === 0 && keywordSearch && keywordSearch.keyword) {
      keywordSearch.setKeyword(element.value);
    }
  };

  // TODO: Remove this future. POC
  // const onLogout = () => {
  //   const result = window.confirm('Are you sure you want to log out?');
  //   if (result === true) {
  //     dispatch(logout());
  //     history.push('/');
  //   }
  // };

  return (
    <Flex
      as="header"
      align="center"
      justify="space-between"
      w="full"
      pt="4vh"
      pb="1vh"
      px="8"
      flexDirection="column">
      <Flex
        w="100%"
        flexDirection={{
          base: 'column',
          md: 'row',
        }}>
        <Flex>{children}</Flex>
        <Spacer />
        <HStack spacing={5}>
          {showVerifyEmailWarning && <VerifyEmailWarning />}
          {isSearchable && (
            <InputGroup
              w={{
                base: '100%',
                md: 60,
              }}
              as="form"
              onSubmit={submitKeyword}>
              <Input id="search" type="search" placeholder={t('search')} onChange={onChangeSearch} />
              <InputRightElement as="button" type="submit" cursor="pointer">
                <SearchIcon color="black" />
              </InputRightElement>
            </InputGroup>
          )}
        </HStack>
      </Flex>
      <Divider mt={4} borderColor="#707070" />
    </Flex>
  );
}

export default RightTopbar;
