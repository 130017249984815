export const GET_QUESTION_BANK = 'questionBank/getQuestionBank';
export const GET_QUESTION_BANK_SUCCESS = 'questionBank/getQuestionBankSuccess';
export const GET_QUESTION_BANK_ERROR = 'questionBank/getQuestionBankError';
export const GET_QUESTION_BANK_NETWORK_ERROR = 'questionBank/getQuestionBankNetworkError';

export const GET_MORE_QUESTION_BANK = 'questionBank/getMoreQuestionBank';
export const GET_MORE_QUESTION_BANK_SUCCESS = 'questionBank/getMoreQuestionBankSuccess';

export const GET_PURCHASED_QUESTION_BANK = 'questionBank/getPurchasedQuestionBank';
export const GET_PURCHASED_QUESTION_BANK_SUCCESS = 'questionBank/getPurchasedQuestionBankSuccess';
export const GET_PURCHASED_QUESTION_BANK_ERROR = 'questionBank/getPurchasedQuestionBankError';
export const GET_PURCHASED_QUESTION_BANK_NETWORK_ERROR =
  'questionBank/getPurchasedQuestionBankNetworkError';

export const GET_MORE_PURCHASED_QUESTION_BANK = 'questionBank/getMorePurchasedQuestionBank';
export const GET_MORE_PURCHASED_QUESTION_BANK_SUCCESS =
  'questionBank/getMorePurchasedQuestionBankSuccess';

export const GET_QUESTION = 'questionBank/getQuestion';
export const GET_QUESTION_SUCCESS = 'questionBank/getQuestionSuccess';
export const GET_QUESTION_ERROR = 'questionBank/getQuestionError';
export const GET_QUESTION_NETWORK_ERROR = 'questionBank/getQuestionNetworkError';

export const SAVE_ANSWER = 'questionBank/saveAnswer';
export const SAVE_ANSWER_SUCCESS = 'questionBank/saveAnswerSuccess';
export const SAVE_ANSWER_ERROR = 'questionBank/saveAnswerError';
export const SAVE_ANSWER_NETWORK_ERROR = 'questionBank/saveAnswerNetworkError';

export const RESET_QUESTION_BANK = 'questionBank/resetQuestionBank';
export const RESET_PURCHASED_QUESTION_BANK = 'questionBank/resetPurchasedQuestionBank';
export const RESET_SAVE_ANSWER = 'questionBank/resetSaveAnswer';
