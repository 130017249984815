import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function BackIcon(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <path
        d="M13.7940591,20.5258106 L12.6051657,21.644553 C12.091007,22.1184823 11.3032624,22.1184823 10.7891037,21.644553 L0.378105972,11.8541823 C0.137480254,11.6365528 0,11.32597 0,11 C0,10.67403 0.137480254,10.3634472 0.378105972,10.1458177 L10.7891037,0.35544704 C11.3032624,-0.118482347 12.091007,-0.118482347 12.6051657,0.35544704 L13.7940591,1.47418939 C14.0366767,1.69634726 14.1732012,2.01275825 14.1690297,2.34322656 C14.1648582,2.67369488 14.0203912,2.98649809 13.7722445,3.20235481 L7.31840757,8.98307365 L22.7107596,8.98307365 C23.040937,8.97000158 23.3626547,9.0902438 23.604647,9.31716466 C23.8466393,9.54408553 23.9889302,9.85895326 24,10.1920194 L24,11.8046805 C23.9891964,12.1371487 23.8476333,12.4516095 23.6065636,12.6786392 C23.3654939,12.905669 23.0447427,13.0265971 22.7151225,13.0147263 L7.31840757,13.0147263 L13.7722445,18.7987452 C14.0222789,19.0132266 14.1681586,19.3262583 14.1723397,19.6572778 C14.1765208,19.9882973 14.038595,20.3049752 13.7940591,20.5258106 L13.7940591,20.5258106 Z"
        id="Back_icon"
        fill="currentColor"
        fillRule="nonzero"
      ></path>
    </Icon>
  );
}
