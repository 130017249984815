export const GET_EVENTS = 'calendar/getEvents';
export const GET_EVENTS_SUCCESS = 'calendar/getEventsSuccess';
export const GET_EVENTS_ERROR = 'calendar/getEventsError';
export const GET_EVENTS_NETWORK_ERROR = 'calendar/getEventsNetworkError';
export const RESET_EVENTS = 'calendar/resetEvents';

export const GET_CLASSROOM_LINK = 'calendar/getClassroomLink';
export const GET_CLASSROOM_LINK_SUCCESS = 'calendar/getClassroomLinkSuccess';
export const GET_CLASSROOM_LINK_ERROR = 'calendar/getClassroomLinkError';
export const GET_CLASSROOM_LINK_NETWORK_ERROR = 'calendar/getClassroomLinkNetworkError';
