import { Order } from 'src/models/Order';
import AppState from '../rootState';
import { OrderState } from './models/states';

const selectOrderState = (state: AppState): OrderState => state.order;

export const selectNewOrder = (state: AppState): Order | null => selectOrderState(state).newOrder;

export const selectNewOrderLoading = (state: AppState): boolean => selectOrderState(state).createOrderLoading;

export const selectFreeClassOrderStatusCode = (state: AppState): number | null =>
  selectOrderState(state).createFreeClassOrderStatusCode;

export const selectFreeClassOrderNetworkError = (state: AppState): boolean =>
  selectOrderState(state).createFreeClassOrderNetworkError;

export const selectFreeClassOrderLoading = (state: AppState): boolean =>
  selectOrderState(state).createFreeClassOrderLoading;

