import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function LeftArrowIcon(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <path
        d="M14.6561722,24.019096 C14.0986138,24.019679 13.5570057,23.833039 13.1181722,23.489096 L0.960172169,13.989096 C0.354148954,13.5152847 0,12.7888562 0,12.019596 C0,11.2503357 0.354148954,10.5239073 0.960172169,10.050096 L13.1181722,0.550095985 C13.820722,-0.0135963125 14.7721884,-0.155779532 15.608838,0.177901128 C16.4454876,0.511581789 17.0379544,1.2695313 17.1597459,2.1619954 C17.2815375,3.05445949 16.9138219,3.94344183 16.1971722,4.48909599 L6.55917217,12.022096 L16.1971722,19.556096 C17.0382314,20.2132265 17.3693435,21.3318393 17.0214792,22.3408941 C16.6736149,23.3499489 15.7235056,24.0268699 14.6561722,24.0260966 L14.6561722,24.019096 Z"
        id="Left"
        fill="currentColor"
        fillRule="nonzero"
      ></path>
    </Icon>
  );
}
