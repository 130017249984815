import {
  GET_LEVELS,
  GET_LEVELS_ERROR,
  GET_LEVELS_NETWORK_ERROR,
  GET_LEVELS_SUCCESS,
  GET_MORE_READING_MATERIALS,
  GET_MORE_READING_MATERIALS_SUCCESS,
  GET_MORE_RECORDED_LECTURES,
  GET_MORE_RECORDED_LECTURES_SUCCESS,
  GET_READING_MATERIALS,
  GET_READING_MATERIALS_ERROR,
  GET_READING_MATERIALS_NETWORK_ERROR,
  GET_READING_MATERIALS_SUCCESS,
  GET_RECORDED_LECTURES,
  GET_RECORDED_LECTURES_ERROR,
  GET_RECORDED_LECTURES_NETWORK_ERROR,
  GET_RECORDED_LECTURES_SUCCESS,
  RESET_RESOURCES,
} from './constants';
import { ResourcesActionType, ResourcesState } from './models';

export const resourcesInitialState: ResourcesState = {
  // Get levels
  levels: null,
  levelsLoading: false,
  levelsStatusCode: null,
  levelsNetworkError: false,

  // Reading materials
  readingMaterials: null,
  readingMaterialsLoading: false,
  readingMaterialsRefetching: false,
  readingMaterialsStatusCode: null,
  readingMaterialsNetworkError: false,

  // Recorded lectures
  recordedLectures: null,
  recordedLecturesLoading: false,
  recordedLecturesRefetching: false,
  recordedLecturesStatusCode: null,
  recordedLecturesNetworkError: false,
};

export const resourcesReducer = (
  state = resourcesInitialState,
  action: ResourcesActionType,
): ResourcesState => {
  switch (action.type) {
    case GET_LEVELS:
      return {
        ...state,
        levels: null,
        levelsLoading: true,
        levelsStatusCode: null,
        levelsNetworkError: false,
      };
    case GET_LEVELS_SUCCESS:
      return {
        ...state,
        levelsLoading: false,
        levelsStatusCode: action.payload.code,
        levels: action.payload.data.objects,
      };
    case GET_LEVELS_ERROR:
      return {
        ...state,
        levelsLoading: false,
        levelsStatusCode: action.payload.code,
      };
    case GET_LEVELS_NETWORK_ERROR:
      return {
        ...state,
        levelsLoading: false,
        levelsNetworkError: true,
      };
    case GET_READING_MATERIALS:
      return {
        ...state,
        readingMaterialsLoading: true,
        readingMaterialsStatusCode: null,
        readingMaterialsNetworkError: false,
      };
    case GET_READING_MATERIALS_SUCCESS:
      return {
        ...state,
        readingMaterials: action.payload.data.objects,
        readingMaterialsPagination: action.payload.pagination,
        readingMaterialsLoading: false,
        readingMaterialsStatusCode: action.payload.code,
      };
    case GET_READING_MATERIALS_ERROR:
      return {
        ...state,
        readingMaterialsLoading: false,
        readingMaterialsStatusCode: action.payload.code,
      };
    case GET_READING_MATERIALS_NETWORK_ERROR:
      return {
        ...state,
        readingMaterialsLoading: false,
        readingMaterialsNetworkError: true,
      };
    case GET_MORE_READING_MATERIALS:
      return {
        ...state,
        readingMaterialsRefetching: true,
        readingMaterialsStatusCode: null,
        readingMaterialsNetworkError: false,
      };
    case GET_MORE_READING_MATERIALS_SUCCESS: {
      if (!state.readingMaterials) {
        return state;
      }

      return {
        ...state,
        readingMaterialsRefetching: false,
        readingMaterials: [...state.readingMaterials, ...action.payload.data.objects],
        readingMaterialsPagination: action.payload.pagination,
        readingMaterialsStatusCode: action.payload.code,
      };
    }
    case GET_RECORDED_LECTURES:
      return {
        ...state,
        recordedLecturesLoading: true,
        recordedLecturesStatusCode: null,
        recordedLecturesNetworkError: false,
      };
    case GET_RECORDED_LECTURES_SUCCESS:
      return {
        ...state,
        recordedLectures: action.payload.data.objects,
        recordedLecturesPagination: action.payload.pagination,
        recordedLecturesLoading: false,
        recordedLecturesStatusCode: action.payload.code,
      };
    case GET_RECORDED_LECTURES_ERROR:
      return {
        ...state,
        recordedLecturesLoading: false,
        recordedLecturesStatusCode: action.payload.code,
      };
    case GET_RECORDED_LECTURES_NETWORK_ERROR:
      return {
        ...state,
        recordedLecturesLoading: false,
        recordedLecturesNetworkError: true,
      };
    case GET_MORE_RECORDED_LECTURES:
      return {
        ...state,
        recordedLecturesRefetching: true,
        recordedLecturesStatusCode: null,
        recordedLecturesNetworkError: false,
      };
    case GET_MORE_RECORDED_LECTURES_SUCCESS: {
      if (!state.recordedLectures) {
        return state;
      }

      return {
        ...state,
        recordedLecturesRefetching: false,
        recordedLectures: [...state.recordedLectures, ...action.payload.data.objects],
        recordedLecturesPagination: action.payload.pagination,
        recordedLecturesStatusCode: action.payload.code,
      };
    }
    case RESET_RESOURCES:
      return resourcesInitialState;
    default:
      return state;
  }
};
