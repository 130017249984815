import { of } from 'rxjs';
import { ajax, AjaxResponse, AjaxError } from 'rxjs/ajax';
import { map, switchMap, catchError, takeUntil } from 'rxjs/operators';
import { combineEpics, ofType, ActionsObservable, Epic } from 'redux-observable';

import { API_URL } from 'src/constants/api';
import {
  GET_QUESTION_BANK,
  GET_QUESTION_BANK_SUCCESS,
  GET_MORE_QUESTION_BANK,
  GET_MORE_QUESTION_BANK_SUCCESS,
  GET_PURCHASED_QUESTION_BANK,
  GET_PURCHASED_QUESTION_BANK_SUCCESS,
  GET_MORE_PURCHASED_QUESTION_BANK,
  GET_MORE_PURCHASED_QUESTION_BANK_SUCCESS,
  GET_QUESTION,
  GET_QUESTION_SUCCESS,
  SAVE_ANSWER,
  SAVE_ANSWER_SUCCESS,
  GET_QUESTION_BANK_ERROR,
  GET_QUESTION_BANK_NETWORK_ERROR,
  GET_PURCHASED_QUESTION_BANK_ERROR,
  GET_PURCHASED_QUESTION_BANK_NETWORK_ERROR,
  GET_QUESTION_ERROR,
  GET_QUESTION_NETWORK_ERROR,
  SAVE_ANSWER_ERROR,
  SAVE_ANSWER_NETWORK_ERROR,
} from './constants';
import {
  QuestionResponse,
  QuestionBankResponse,
  GetQuestionBankAction,
  GetQuestionBankSuccessAction,
  GetMoreQuestionBankAction,
  GetMoreQuestionBankSuccessAction,
  GetPurchasedQuestionBankAction,
  GetPurchasedQuestionBankSuccessAction,
  GetMorePurchasedQuestionBankAction,
  GetMorePurchasedQuestionBankSuccessAction,
  GetQuestionAction,
  GetQuestionSuccessAction,
  SaveAnswerResponse,
  SaveAnswerAction,
  SaveAnswerSuccessAction,
} from './models';
import { toUrlString } from 'src/utils/queryString';
import { LOGOUT } from '../auth/constants';
import { ErrorResponse } from 'src/models/ErrorResponse';

function getQuestionBankEpic(action$: ActionsObservable<GetQuestionBankAction>) {
  return action$.pipe(
    ofType(GET_QUESTION_BANK),
    switchMap((action) => {
      const apiQuery = toUrlString(action.payload);
      return ajax({
        url: API_URL + '/questionbank?' + apiQuery,
        method: 'get',
        withCredentials: true,
      }).pipe(
        map((payload: AjaxResponse): GetQuestionBankSuccessAction => {
          return {
            type: GET_QUESTION_BANK_SUCCESS,
            payload: {
              ...(payload.response as QuestionBankResponse),
              pagination: {
                page: Number(payload.xhr.getResponseHeader('x-page')),
                limit: Number(payload.xhr.getResponseHeader('x-limit')),
                totalPages: Number(payload.xhr.getResponseHeader('x-total-pages')),
                totalRecords: Number(payload.xhr.getResponseHeader('x-total-records')),
              },
            },
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: GET_QUESTION_BANK_NETWORK_ERROR,
            });
          }
          return of({
            type: GET_QUESTION_BANK_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function getMoreQuestionBankEpic(action$: ActionsObservable<GetMoreQuestionBankAction>) {
  return action$.pipe(
    ofType(GET_MORE_QUESTION_BANK),
    switchMap((action) => {
      const apiQuery = toUrlString(action.payload);
      return ajax({
        url: API_URL + '/questionbank?' + apiQuery,
        method: 'get',
        withCredentials: true,
      }).pipe(
        map((payload: AjaxResponse): GetMoreQuestionBankSuccessAction => {
          return {
            type: GET_MORE_QUESTION_BANK_SUCCESS,
            payload: {
              ...(payload.response as QuestionBankResponse),
              pagination: {
                page: Number(payload.xhr.getResponseHeader('x-page')),
                limit: Number(payload.xhr.getResponseHeader('x-limit')),
                totalPages: Number(payload.xhr.getResponseHeader('x-total-pages')),
                totalRecords: Number(payload.xhr.getResponseHeader('x-total-records')),
              },
            },
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: GET_QUESTION_BANK_NETWORK_ERROR,
            });
          }
          return of({
            type: GET_QUESTION_BANK_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function getPurchasedQuestionBankEpic(action$: ActionsObservable<GetPurchasedQuestionBankAction>) {
  return action$.pipe(
    ofType(GET_PURCHASED_QUESTION_BANK),
    switchMap((action) => {
      const apiQuery = toUrlString(action.payload);
      return ajax({
        url: API_URL + '/questionbank/purchased?' + apiQuery,
        method: 'get',
        withCredentials: true,
      }).pipe(
        map((payload: AjaxResponse): GetPurchasedQuestionBankSuccessAction => {
          return {
            type: GET_PURCHASED_QUESTION_BANK_SUCCESS,
            payload: {
              ...(payload.response as QuestionBankResponse),
              pagination: {
                page: Number(payload.xhr.getResponseHeader('x-page')),
                limit: Number(payload.xhr.getResponseHeader('x-limit')),
                totalPages: Number(payload.xhr.getResponseHeader('x-total-pages')),
                totalRecords: Number(payload.xhr.getResponseHeader('x-total-records')),
              },
            },
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: GET_PURCHASED_QUESTION_BANK_NETWORK_ERROR,
            });
          }
          return of({
            type: GET_PURCHASED_QUESTION_BANK_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function getMorePurchasedQuestionBankEpic(
  action$: ActionsObservable<GetMorePurchasedQuestionBankAction>,
) {
  return action$.pipe(
    ofType(GET_MORE_PURCHASED_QUESTION_BANK),
    switchMap((action) => {
      const apiQuery = toUrlString(action.payload);
      return ajax({
        url: API_URL + '/questionbank/purchased?' + apiQuery,
        method: 'get',
        withCredentials: true,
      }).pipe(
        map((payload: AjaxResponse): GetMorePurchasedQuestionBankSuccessAction => {
          return {
            type: GET_MORE_PURCHASED_QUESTION_BANK_SUCCESS,
            payload: {
              ...(payload.response as QuestionBankResponse),
              pagination: {
                page: Number(payload.xhr.getResponseHeader('x-page')),
                limit: Number(payload.xhr.getResponseHeader('x-limit')),
                totalPages: Number(payload.xhr.getResponseHeader('x-total-pages')),
                totalRecords: Number(payload.xhr.getResponseHeader('x-total-records')),
              },
            },
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: GET_PURCHASED_QUESTION_BANK_NETWORK_ERROR,
            });
          }
          return of({
            type: GET_PURCHASED_QUESTION_BANK_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function getQuestionEpic(action$: ActionsObservable<GetQuestionAction>) {
  return action$.pipe(
    ofType(GET_QUESTION),
    switchMap((action) => {
      return ajax({
        url: `${API_URL}/questionbank/${action.id}`,
        method: 'get',
        withCredentials: true,
      }).pipe(
        map((payload: AjaxResponse): GetQuestionSuccessAction => {
          return {
            type: GET_QUESTION_SUCCESS,
            payload: payload.response as QuestionResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: GET_QUESTION_NETWORK_ERROR,
            });
          }
          return of({
            type: GET_QUESTION_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function saveAnswerEpic(action$: ActionsObservable<SaveAnswerAction>) {
  return action$.pipe(
    ofType(SAVE_ANSWER),
    switchMap((action) => {
      return ajax({
        url: `${API_URL}/question/answer`,
        method: 'post',
        withCredentials: true,
        body: {
          questionId: action.payload.id,
          answer: action.payload.answer.join(','),
        },
      }).pipe(
        map((payload: AjaxResponse): SaveAnswerSuccessAction => {
          return {
            type: SAVE_ANSWER_SUCCESS,
            payload: payload.response as SaveAnswerResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: SAVE_ANSWER_NETWORK_ERROR,
            });
          }
          return of({
            type: SAVE_ANSWER_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

export const questionBankEpics: Epic = combineEpics(
  ...[
    getQuestionBankEpic,
    getMoreQuestionBankEpic,
    getQuestionEpic,
    getPurchasedQuestionBankEpic,
    getMorePurchasedQuestionBankEpic,
    saveAnswerEpic,
  ],
);
