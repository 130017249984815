import React, { useEffect } from 'react';
import {
  Button,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { XIcon } from 'src/icons';
import { applyPromoCode } from 'src/redux/cart/actions';
import {
  selectPromoCode,
  selectPromoCodeErrorMessage,
  selectPromoCodeLoading,
} from 'src/redux/cart/selectors';

interface PromoCodeProps {
  canCheckout: boolean;
  promoCode: string;
  setPromoCode: React.Dispatch<React.SetStateAction<string>>;
  resetPromoCode: () => void;
}

function PromoCode(props: PromoCodeProps): JSX.Element {
  const { canCheckout, promoCode, setPromoCode, resetPromoCode } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const promoCodeLoading = useSelector(selectPromoCodeLoading);
  const promoCodeData = useSelector(selectPromoCode);
  const promoCodeErrorMessage = useSelector(selectPromoCodeErrorMessage);

  const hasPromoCode = promoCodeData && Boolean(promoCodeData);
  const hasAppliedPromoCode = hasPromoCode || Boolean(promoCodeErrorMessage);
  const validPromoCode = hasPromoCode && Boolean(!promoCodeErrorMessage);

  const isPercentage = promoCodeData?.type.id === 2;
  const discountValue = hasPromoCode ? promoCodeData.value : '';

  const discountMessage = isPercentage ? `${discountValue}% off` : `RM${discountValue} off`;

  const onApplyPromoCode = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(applyPromoCode(promoCode));
  };

  useEffect(() => {
    if (!promoCode) {
      resetPromoCode();
    }
  }, [promoCode]);

  return (
    <Flex direction="column" w="100%" maxW="250px" minW="250px">
      <Text fontWeight="semibold" mb="2">
        {t('promo_code')}
      </Text>
      <Flex
        as="form"
        onSubmit={onApplyPromoCode}
        w="100%"
        justifyContent="flex-end"
        alignItems="center">
        <InputGroup size="sm" mr="2">
          <Input
            size="sm"
            disabled={!canCheckout}
            value={promoCode}
            onChange={(e) => setPromoCode(e.target.value)}
          />
          {promoCode && (
            <InputRightElement>
              <IconButton
                disabled={!canCheckout}
                variant="ghost"
                size="sm"
                aria-label="clear promo code"
                icon={<XIcon />}
                onClick={() => resetPromoCode()}
              />
            </InputRightElement>
          )}
        </InputGroup>

        <Button
          disabled={!canCheckout}
          variant="ghost"
          size="sm"
          aria-label="apply promo code"
          type="submit"
          isLoading={promoCodeLoading}>
          Apply
        </Button>
      </Flex>
      {hasAppliedPromoCode ? (
        <Text mt="2" fontSize="sm" color={validPromoCode ? 'black' : 'red.500'}>
          {validPromoCode ? 'Discount: ' + discountMessage : promoCodeErrorMessage}
        </Text>
      ) : (
        <Text mt="2" fontSize="sm" visibility="hidden">
          placeholder
        </Text>
      )}
    </Flex>
  );
}

export default PromoCode;
