import { Profile } from 'src/models/Profile';

import { ProfileState } from './models';
import AppState from '../rootState';

const selectProfileState = (state: AppState): ProfileState => state.profile;

// Profile
export const selectProfile = (state: AppState): Profile | null => selectProfileState(state).profile;

export const selectProfileLoading = (state: AppState): boolean | undefined =>
  selectProfileState(state).profileLoading;

export const selectProfileStatusCode = (state: AppState): number | null =>
  selectProfileState(state).profileStatusCode;

export const selectProfileNetworkError = (state: AppState): boolean =>
  selectProfileState(state).profileNetworkError;

// Update profile
export const selectUpdateProfileStatusCode = (state: AppState): number | null =>
  selectProfileState(state).updateProfileStatusCode;

export const selectUpdateProfileNetworkError = (state: AppState): boolean =>
  selectProfileState(state).updateProfileNetworkError;

// Upload profile image
export const selectUploadProfileImageStatusCode = (state: AppState): number | null =>
  selectProfileState(state).uploadProfileImageStatusCode;

export const selectUploadProfileImageNetworkError = (state: AppState): boolean =>
  selectProfileState(state).uploadProfileImageNetworkError;
