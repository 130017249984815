import React from 'react';

import { Flex, Spinner } from '@chakra-ui/react';

function RefetchingLoader(): JSX.Element {
  return (
    <Flex
      flexDirection="column"
      mx="auto"
      rounded="lg"
      w="full"
      justifyContent="center"
      alignItems="center"
    >
      <Spinner />
    </Flex>
  );
}

export default RefetchingLoader;
