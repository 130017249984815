import { extendTheme } from '@chakra-ui/react';

import { progress } from './colors/progress';
import { main } from './colors/main';
import Checkbox from './components/Checkbox';
import Radio from './components/Radio';

export const customTheme = extendTheme({
  fonts: {
    heading: 'Poppins',
    body: 'Poppins',
  },
  colors: {
    ...progress,
    ...main,
  },
  components: {
    Checkbox,
    Radio,
  },
});
