import React from 'react';
import { format, parseISO } from 'date-fns';
import { useDispatch } from 'react-redux';
import { Flex, Image, IconButton, Text, HStack, Spacer } from '@chakra-ui/react';

import { RemoveIcon } from 'src/icons';
import { formatPrice } from 'src/utils/formatPrice';
import { Course } from 'src/models/cart/Course';
import { deleteCartItem, getCart } from 'src/redux/cart/actions';
import { getCoursePriceStructure } from 'src/utils/getCoursePriceStructure';
import TavisPlaceholder from 'src/images/TavisPlaceholder.svg';
import { useTranslation } from 'react-i18next';

interface CourseItemProps {
  course: Course;
  canPurchase: boolean;
  resetPromoCode: () => void;
}

function ItemCard({ course, resetPromoCode, canPurchase }: CourseItemProps): JSX.Element {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { hasDiscount, discountPrice, originalPrice } = getCoursePriceStructure(course);

  const onDelete = (id: string) => {
    resetPromoCode();
    dispatch(deleteCartItem(id));
    setTimeout(() => {
      dispatch(getCart());
    }, 500);
  };

  return (
    <Flex
      w="100%"
      py="4"
      px="6"
      borderBottomColor="gray.600"
      borderBottomWidth="1px"
      bg={canPurchase ? course.color : 'gray.100'}
      color={canPurchase ? 'black' : 'gray.400'}
      cursor={canPurchase ? '' : 'not-allowed'}
      flexDirection={{
        base: 'column',
        md: 'row',
      }}>
      <Flex
        mb={{
          base: '20px',
          md: 0,
        }}
        flexDirection={{
          base: 'column',
          md: 'row',
        }}>
        <Flex direction="column" justifyContent="center">
          <IconButton
            mx="4"
            aria-label="remove-cart-item"
            icon={<RemoveIcon boxSize="20px" />}
            variant="unstyled"
            _hover={{ color: 'red' }}
            _focus={{ outline: 'none' }}
            onClick={() => onDelete(String(course.cartId))}
          />
        </Flex>
        <Flex
          justifyContent="center"
          w={{
            base: '100%',
            md: 'auto',
          }}>
          <Image
            src={course.courseImageUrl || TavisPlaceholder}
            minW="100px"
            minH="100px"
            w={{
              base: '150px',
              md: '100px',
            }}
            h={{
              base: '150px',
              md: '100px',
            }}
            borderRadius="lg"
            fit="cover"
          />
        </Flex>
      </Flex>
      <Flex
        w="full"
        justifyContent="space-between"
        flexDirection={{
          base: 'column',
          md: 'row',
        }}
        ml={{
          base: 0,
          md: 4,
        }}>
        <Flex
          direction="column"
          pr={{
            base: 0,
            md: '30px',
          }}
          mb={{
            base: '20px',
            md: 0,
          }}>
          <Text fontSize="lg" fontWeight="medium" textTransform="capitalize">
            {`${course.courseName} - ${course.form.name} - ${format(
              parseISO(course.startDate),
              'yyyy',
            )} - Cikgu ${course.teacher.firstname} ${course.teacher.lastname}`}
          </Text>
          <Text fontSize="lg" fontWeight="medium" textTransform="capitalize">
            (#{course.sku})
          </Text>
          <Spacer />
          <Text>{t('billed_instruction')}</Text>
        </Flex>
        <Flex
          direction="column"
          fontSize={{
            base: 'md',
            md: 'lg',
          }}
          fontWeight="medium"
          alignItems={{
            base: 'flex-start',
            md: 'flex-end',
          }}>
          {hasDiscount ? (
            <HStack
              spacing="2"
              flexWrap={{
                base: 'wrap',
                md: 'unset',
              }}>
              <Text>
                {t('fee')}/{t('month_word')}:
              </Text>
              <Text textDecoration="line-through">{formatPrice(String(originalPrice))}</Text>
              <Text color={canPurchase ? 'red.500' : 'inherit'}>
                {formatPrice(String(discountPrice))}
              </Text>
            </HStack>
          ) : (
            <Text>
              {t('fee')}/{t('month_word')}: {formatPrice(String(originalPrice))}
            </Text>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}

export default ItemCard;
