import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function CourseIcon(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <path
        d="M21,16.8743762 L21,1.12438689 C21.0006524,0.825981152 20.8824302,0.539607857 20.6714746,0.328602625 C20.4605189,0.117597394 20.1742129,-0.000652568 19.8758772,2.70893005e-06 L4.49894001,2.70893005e-06 C2.01452655,0.000687476184 0.00068187556,2.01500526 0,4.50000271 L0,19.4999922 C0.00068187556,21.9849894 2.01452655,23.9993072 4.49894001,23.9999919 L19.8697007,23.9999919 C20.1680364,24.0006472 20.4543424,23.8823972 20.6652981,23.671392 C20.8762538,23.4603868 20.9944759,23.1740135 20.9938181,22.8756077 L20.9938181,22.1256082 C20.9928679,21.7857814 20.8396372,21.4642841 20.5762888,21.2495792 C20.3794427,20.0906674 20.3794427,18.9068459 20.5762888,17.7479341 C20.8438677,17.5370556 21,17.2151135 21,16.8743762 Z M5.99858668,6.28047895 C5.99858668,6.12489307 6.12468433,5.99876579 6.28023367,5.99876579 L16.2144664,5.99876579 C16.3700157,5.99876579 16.4961134,6.12489307 16.4961134,6.28047895 L16.4961134,7.21828721 C16.4961134,7.37387309 16.3700157,7.50000036 16.2144664,7.50000036 L6.27899837,7.50000036 C6.12344904,7.50000036 5.99735139,7.37387309 5.99735139,7.21828721 L5.99858668,6.28047895 Z M5.99858668,9.28047691 C5.99858668,9.12489104 6.12468433,8.99876376 6.28023367,8.99876376 L16.2144664,8.99876376 C16.3700157,8.99876376 16.4961134,9.12489104 16.4961134,9.28047691 L16.4961134,10.2182852 C16.4961134,10.3738711 16.3700157,10.4999983 16.2144664,10.4999983 L6.27899837,10.4999983 C6.12344904,10.4999983 5.99735139,10.3738711 5.99735139,10.2182852 L5.99858668,9.28047691 Z M17.873466,21.0049356 L4.49894001,21.0049356 C3.67070803,21.0049356 2.99929334,20.3333611 2.99929334,19.5049356 C2.99929334,18.676508 3.67070803,18.0049356 4.49894001,18.0049356 L17.873466,18.0049356 C17.7844185,19.0029516 17.7844185,20.0069175 17.873466,21.0049356 L17.873466,21.0049356 Z"
        id="Course"
        fill="currentColor"
        fillRule="nonzero"
      ></path>
    </Icon>
  );
}
