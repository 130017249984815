import { AuthActionType, AuthState } from './models';
import * as AuthConstants from './constants';

export const authInitialState: AuthState = {
  // Login
  rememberMe: true,
  loginStatus: null,
  loginRole: null,

  loginLoading: false,
  loginMethod: null,
  moodleSession: null,
  username: null,
  loginErrorMessage: null,
  loginStatusCode: null,
  freeTrial: null,
  freeTrialExpiry: null,
  registerDate: null,
  freeTrialAccessStartDate: null,
  accountVerified: null,
  verificationSkipsLeft: null,

  // Register
  registerErrorMessage: null,
  registerStatus: null,
  registerLoading: false,
  registerErrors: false,

  // Forget password
  forgetPasswordMessage: null,
  forgetPasswordLoading: false,
  forgetPasswordErrors: false,

  // Confirm verification code
  confirmVerificationCodeMessage: null,
  confirmVerificationCodeLoading: false,
  confirmVerificationCodeStatusCode: null,
  confirmVerificationCodeNetworkError: false,

  // Resend verification code
  resendVerificationCodeMessage: null,
  resendVerificationCodeLoading: false,
  resendVerificationCodeStatusCode: null,
  resendVerificationCodeNetworkError: false,

  // Get user details
  userDetailsLoading: false,
  userDetailsStatusCode: null,
  userDetailsNetworkError: false,

  // Update onboarding details
  updateOnboardingDetailsLoading: false,
  updateOnboardingDetailsStatusCode: null,
  updateOnboardingDetailsNetworkError: false,
  updateOnboardingDetailsMessage: null,

  // Get level of study
  studyLevelLoading: false,
  studyLevel: null,
  studyLevelStatusCode: null,
  studyLevelNetworkError: false,
  studyLevelPagination: null,

  // Skip verification
  skipVerificationMessage: null,
  skipVerificationLoading: false,
  skipVerificationStatusCode: null,
  skipVerificationNetworkError: false,
};

export const authReducer = (state = authInitialState, action: AuthActionType): AuthState => {
  switch (action.type) {
    case AuthConstants.LOGIN:
    case AuthConstants.LOGIN_GOOGLE:
    case AuthConstants.LOGIN_FACEBOOK:
    case AuthConstants.LOGIN_APPLE:
      return {
        ...state,
        loginErrorMessage: null,
        loginLoading: true,
      };
    case AuthConstants.LOGIN_SUCCESS:
      return {
        ...state,
        loginRole: action.payload.data.role,
        loginMethod: 'manual',
        loginLoading: false,
        loginStatus: action.payload.status,
        rememberMe: action.payload.rememberMe === undefined ? true : action.payload.rememberMe,
        moodleSession: action.payload.data?.moddle_session || null,
        username: action.payload.data?.userName || null,
        freeTrial: action.payload.data.freeTrial,
        freeTrialExpiry: action.payload.data.freeTrialEntitlementExpiry,
        registerDate: action.payload.data.registerDate,
        freeTrialAccessStartDate: action.payload.data.freeTrialAccessStartDate,
        accountVerified: action.payload.data.confirmed,
        verificationSkipsLeft: action.payload.data.verificationSkipsLeft,
      };
    case AuthConstants.REFRESH_TOKEN_NETWORK_ERROR:
    case AuthConstants.LOGIN_NETWORK_ERROR:
      return {
        ...state,
        loginLoading: false,
        loginErrorMessage: 'Login error. Please try again.',
      };
    case AuthConstants.LOGIN_ERROR:
    case AuthConstants.LOGIN_APPLE_ERROR:
    case AuthConstants.LOGIN_GOOGLE_ERROR:
    case AuthConstants.LOGIN_FACEBOOK_ERROR:
    case AuthConstants.REFRESH_TOKEN_ERROR:
      return {
        ...state,
        loginLoading: false,
        loginErrorMessage: action.payload.error,
        loginStatusCode: action.payload.code,
        loginStatus: action.payload.status,
      };
    case AuthConstants.LOGIN_GOOGLE_SUCCESS:
      return {
        ...state,
        loginMethod: 'google',
        loginLoading: false,
        loginRole: action.payload.data.role,
        loginStatus: action.payload.status,
        moodleSession: action.payload.data?.moddle_session || null,
        username: action.payload.data?.userName || null,
        freeTrial: action.payload.data.freeTrial,
        freeTrialExpiry: action.payload.data.freeTrialEntitlementExpiry,
        registerDate: action.payload.data.registerDate,
        freeTrialAccessStartDate: action.payload.data.freeTrialAccessStartDate,
        accountVerified: action.payload.data.confirmed,
        verificationSkipsLeft: action.payload.data.verificationSkipsLeft,
      };
    case AuthConstants.LOGIN_FACEBOOK_SUCCESS:
      return {
        ...state,
        loginMethod: 'facebook',
        loginLoading: false,
        loginRole: action.payload.data.role,
        loginStatus: action.payload.status,
        moodleSession: action.payload.data?.moddle_session || null,
        username: action.payload.data?.userName || null,
        freeTrial: action.payload.data.freeTrial,
        freeTrialExpiry: action.payload.data.freeTrialEntitlementExpiry,
        registerDate: action.payload.data.registerDate,
        freeTrialAccessStartDate: action.payload.data.freeTrialAccessStartDate,
        accountVerified: action.payload.data.confirmed,
        verificationSkipsLeft: action.payload.data.verificationSkipsLeft,
      };
    case AuthConstants.LOGIN_APPLE_SUCCESS:
      return {
        ...state,
        loginMethod: 'apple',
        loginLoading: false,
        loginRole: action.payload.data.role,
        loginStatus: action.payload.status,
        moodleSession: action.payload.data?.moddle_session || null,
        username: action.payload.data?.userName || null,
        freeTrial: action.payload.data.freeTrial,
        freeTrialExpiry: action.payload.data.freeTrialEntitlementExpiry,
        registerDate: action.payload.data.registerDate,
        freeTrialAccessStartDate: action.payload.data.freeTrialAccessStartDate,
        accountVerified: action.payload.data.confirmed,
        verificationSkipsLeft: action.payload.data.verificationSkipsLeft,
      };
    case AuthConstants.REGISTER:
      return {
        ...state,
        registerErrorMessage: null,
        registerLoading: true,
        registerStatus: null,
        registerErrors: false,
      };
    case AuthConstants.REGISTER_SUCCESS:
      return {
        ...state,
        registerErrorMessage: action.payload.data,
        registerStatus: action.payload.status,
        registerLoading: false,
      };
    case AuthConstants.REGISTER_ERROR:
      return {
        ...state,
        registerErrorMessage: action.payload.error || null,
        registerLoading: false,
      };
    case AuthConstants.REGISTER_NETWORK_ERROR:
      return {
        ...state,
        registerLoading: false,
        registerErrors: true,
        registerErrorMessage: 'Register error. Please try again.',
      };
    case AuthConstants.FORGET_PASSWORD:
      return {
        ...state,
        forgetPasswordMessage: null,
        forgetPasswordLoading: true,
      };
    case AuthConstants.FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        forgetPasswordMessage: action.payload.data || null,
        forgetPasswordLoading: false,
      };
    case AuthConstants.FORGET_PASSWORD_ERROR:
      return {
        ...state,
        forgetPasswordMessage: action.payload.error || null,
        forgetPasswordLoading: false,
      };
    case AuthConstants.FORGET_PASSWORD_NETWORK_ERROR:
      return {
        ...state,
        forgetPasswordLoading: false,
        forgetPasswordErrors: true,
        forgetPasswordMessage: 'Forgot password error. Please try again.',
      };
    case AuthConstants.REFRESH_TOKEN:
      return {
        ...state,
        loginErrorMessage: null,
        loginLoading: true,
        loginStatus: null,
        loginStatusCode: null,
      };
    case AuthConstants.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        loginLoading: false,
        loginStatus: action.payload.status,
        moodleSession: action.payload.data?.moddle_session || null,
        username: action.payload.data?.userName || null,
        loginRole: action.payload.data.role,
        freeTrial: action.payload.data.freeTrial,
        freeTrialExpiry: action.payload.data.freeTrialEntitlementExpiry,
        registerDate: action.payload.data.registerDate,
        freeTrialAccessStartDate: action.payload.data.freeTrialAccessStartDate,
        accountVerified: action.payload.data.confirmed,
        verificationSkipsLeft: action.payload.data.verificationSkipsLeft,
      };
    case AuthConstants.CLEAR_STATUS:
      return authInitialState;
    case AuthConstants.CONFIRM_VERIFICATION_CODE:
      return {
        ...state,
        confirmVerificationCodeMessage: null,
        confirmVerificationCodeLoading: true,
        confirmVerificationCodeStatusCode: null,
        confirmVerificationCodeNetworkError: false,
      };
    case AuthConstants.CONFIRM_VERIFICATION_CODE_SUCCESS:
      return {
        ...state,
        loginRole: action.payload.data.role,
        loginMethod: 'manual',
        loginStatus: action.payload.status,
        rememberMe: true,
        moodleSession: action.payload.data?.moddle_session || null,
        username: action.payload.data?.userName || null,
        freeTrial: action.payload.data.freeTrial,
        freeTrialExpiry: action.payload.data.freeTrialEntitlementExpiry,
        registerDate: action.payload.data.registerDate,
        freeTrialAccessStartDate: action.payload.data.freeTrialAccessStartDate,
        confirmVerificationCodeLoading: false,
        confirmVerificationCodeStatusCode: action.payload.code,
        accountVerified: action.payload.data.confirmed,
        verificationSkipsLeft: action.payload.data.verificationSkipsLeft,
      };
    case AuthConstants.CONFIRM_VERIFICATION_CODE_ERROR:
      return {
        ...state,
        confirmVerificationCodeMessage: action.payload.error,
        confirmVerificationCodeLoading: false,
        confirmVerificationCodeStatusCode: action.payload.code,
      };
    case AuthConstants.CONFIRM_VERIFICATION_CODE_NETWORK_ERROR:
      return {
        ...state,
        confirmVerificationCodeLoading: false,
        confirmVerificationCodeNetworkError: true,
      };
    case AuthConstants.RESEND_VERIFICATION_CODE:
      return {
        ...state,
        resendVerificationCodeMessage: null,
        resendVerificationCodeLoading: true,
        resendVerificationCodeStatusCode: null,
        resendVerificationCodeNetworkError: false,
      };
    case AuthConstants.RESEND_VERIFICATION_CODE_SUCCESS:
      return {
        ...state,
        resendVerificationCodeMessage: action.payload.data,
        resendVerificationCodeLoading: false,
        resendVerificationCodeStatusCode: action.payload.code,
      };
    case AuthConstants.RESEND_VERIFICATION_CODE_ERROR:
      return {
        ...state,
        resendVerificationCodeMessage: action.payload.error,
        resendVerificationCodeLoading: false,
        resendVerificationCodeStatusCode: action.payload.code,
      };
    case AuthConstants.RESEND_VERIFICATION_CODE_NETWORK_ERROR:
      return {
        ...state,
        resendVerificationCodeLoading: false,
        resendVerificationCodeNetworkError: true,
      };
    case AuthConstants.GET_USER_DETAILS:
      return {
        ...state,
        userDetailsLoading: true,
        userDetailsStatusCode: null,
        userDetailsNetworkError: false,
      };
    case AuthConstants.GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        loginRole: action.payload.data.objects.role.name,
        userDetailsLoading: false,
        userDetailsStatusCode: action.payload.code,
        registerDate: action.payload.data.objects.registerDate,
        freeTrialAccessStartDate: action.payload.data.objects.freeTrialAccessStartDate,
        accountVerified: parseInt(action.payload.data.objects.confirmed),
        verificationSkipsLeft: parseInt(action.payload.data.objects.verificationSkipsLeft),
      };
    case AuthConstants.GET_USER_DETAILS_ERROR:
      return {
        ...state,
        userDetailsLoading: false,
        userDetailsStatusCode: action.payload.code,
      };
    case AuthConstants.GET_USER_DETAILS_NETWORK_ERROR:
      return {
        ...state,
        userDetailsLoading: false,
        userDetailsNetworkError: true,
      };
    case AuthConstants.RESET_USER_DETAILS:
      return {
        ...state,
        userDetailsLoading: false,
        userDetailsStatusCode: null,
        userDetailsNetworkError: false,
      };
    case AuthConstants.UPDATE_ONBOARDING_DETAILS:
      return {
        ...state,
        updateOnboardingDetailsLoading: true,
        updateOnboardingDetailsStatusCode: null,
        updateOnboardingDetailsNetworkError: false,
        updateOnboardingDetailsMessage: null,
      };
    case AuthConstants.UPDATE_ONBOARDING_DETAILS_SUCCESS:
      return {
        ...state,
        updateOnboardingDetailsLoading: false,
        updateOnboardingDetailsStatusCode: action.payload.code,
      };
    case AuthConstants.UPDATE_ONBOARDING_DETAILS_ERROR:
      return {
        ...state,
        updateOnboardingDetailsLoading: false,
        updateOnboardingDetailsStatusCode: action.payload.code,
        updateOnboardingDetailsMessage: action.payload.translatedErrors.en,
      };
    case AuthConstants.UPDATE_ONBOARDING_DETAILS_NETWORK_ERROR:
      return {
        ...state,
        updateOnboardingDetailsLoading: false,
        updateOnboardingDetailsNetworkError: true,
      };
    case AuthConstants.GET_STUDY_LEVEL:
      return {
        ...state,
        studyLevel: null,
        studyLevelStatusCode: null,
        studyLevelNetworkError: false,
        studyLevelLoading: true,
        studyLevelPagination: null,
      };
    case AuthConstants.GET_STUDY_LEVEL_SUCCESS:
      return {
        ...state,
        studyLevel: action.payload.data.objects,
        studyLevelStatusCode: action.payload.code,
        studyLevelLoading: false,
        studyLevelPagination: action.payload.pagination,
      };
    case AuthConstants.GET_STUDY_LEVEL_ERROR:
      return {
        ...state,
        studyLevelStatusCode: action.payload.code,
        studyLevelLoading: false,
      };
    case AuthConstants.GET_STUDY_LEVEL_NETWORK_ERROR:
      return {
        ...state,
        studyLevelNetworkError: true,
        studyLevelLoading: false,
      };
    case AuthConstants.GET_MORE_STUDY_LEVEL:
      return {
        ...state,
        studyLevelStatusCode: null,
        studyLevelNetworkError: false,
        studyLevelLoading: true,
      };

    case AuthConstants.GET_MORE_STUDY_LEVEL_SUCCESS: {
      if (!state.studyLevel) {
        return state;
      }

      return {
        ...state,
        studyLevel: [...state.studyLevel, ...action.payload.data.objects],
        studyLevelStatusCode: action.payload.code,
        studyLevelLoading: false,
        studyLevelPagination: action.payload.pagination,
      };
    }
    case AuthConstants.SKIP_VERIFICATION:
      return {
        ...state,
        skipVerificationLoading: true,
        skipVerificationStatusCode: null,
        skipVerificationNetworkError: false,
      };
    case AuthConstants.SKIP_VERIFICATION_SUCCESS:
      return {
        ...state,
        skipVerificationLoading: false,
        skipVerificationStatusCode: action.payload.code,
        skipVerificationMessage: action.payload.data,
      };
    case AuthConstants.SKIP_VERIFICATION_ERROR:
      return {
        ...state,
        skipVerificationLoading: false,
        skipVerificationStatusCode: action.payload.code,
        skipVerificationMessage: action.payload.error,
      };
    case AuthConstants.SKIP_VERIFICATION_NETWORK_ERROR:
      return {
        ...state,
        skipVerificationLoading: false,
        skipVerificationNetworkError: true,
      };
    case AuthConstants.CLEAR_VERIFICATION_STATUS:
      return {
        ...state,
        // Confirm verification code
        confirmVerificationCodeMessage: null,
        confirmVerificationCodeLoading: false,
        confirmVerificationCodeStatusCode: null,
        confirmVerificationCodeNetworkError: false,
        // Resend verification code
        resendVerificationCodeMessage: null,
        resendVerificationCodeLoading: false,
        resendVerificationCodeStatusCode: null,
        resendVerificationCodeNetworkError: false,
        // Skip verification
        skipVerificationMessage: null,
        skipVerificationLoading: false,
        skipVerificationStatusCode: null,
        skipVerificationNetworkError: false,
      };
    default:
      return state;
  }
};
