import { Course } from 'src/models/cart/Course';

interface ReturnValue {
  hasDiscount: boolean;
  originalPrice: number;
  discountPrice: number;
}

export const getCoursePriceStructure = (course: Course): ReturnValue => {
  const hasBundleDiscount = Boolean(course.bundleDiscountPrice);
  const hasRegularDiscount = Boolean(course.discountPrice);

  const hasDiscount = hasBundleDiscount;

  const originalPrice = hasRegularDiscount ? course.discountPrice : course.price;

  const discountPrice = course.bundleDiscountPrice;

  return { hasDiscount, originalPrice, discountPrice };
};
