import { of } from 'rxjs';
import { ajax, AjaxResponse, AjaxError } from 'rxjs/ajax';
import { map, switchMap, catchError, takeUntil } from 'rxjs/operators';
import { combineEpics, ofType, ActionsObservable, Epic } from 'redux-observable';

import * as ParentsNotificationConstants from './contants';
import {
  CancelNotificationAction,
  CancelNotificationResponse,
  CancelNotificationSuccessAction,
  ConfirmOTPAction,
  ConfirmOTPResponse,
  ConfirmOTPSuccessAction,
  ForgotPasswordAction,
  ForgotPasswordResponse,
  ForgotPasswordSuccessAction,
  GetNotificationDetailsAction,
  GetNotificationDetailsSuccessAction,
  NotificationDetailsResponse,
  ResendOTPAction,
  ResendOTPResponse,
  ResendOTPSuccessAction,
  SaveDetailsAction,
  SaveDetailsResponse,
  SaveDetailsSuccessAction,
  UpdateDetailsAction,
  UpdateDetailsResponse,
  UpdateDetailsSuccessAction,
  UpdatePasswordAction,
  UpdatePasswordResponse,
  UpdatePasswordSuccessAction,
  VerifyPasswordAction,
  VerifyPasswordResponse,
  VerifyPasswordSuccessAction,
} from './models';
import { API_URL } from 'src/constants/api';
import { ErrorResponse } from 'src/models/ErrorResponse';
import { LOGOUT } from '../auth/constants';

function getNotificationDetailsEpic(action$: ActionsObservable<GetNotificationDetailsAction>) {
  return action$.pipe(
    ofType(ParentsNotificationConstants.GET_NOTIFICATION_DETAILS),
    switchMap(() => {
      return ajax({
        url: API_URL + '/notification/me',
        method: 'get',
        withCredentials: true,
      }).pipe(
        map((payload: AjaxResponse): GetNotificationDetailsSuccessAction => {
          return {
            type: ParentsNotificationConstants.GET_NOTIFICATION_DETAILS_SUCCESS,
            payload: payload.response as NotificationDetailsResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: ParentsNotificationConstants.GET_NOTIFICATION_DETAILS_NETWORK_ERROR,
            });
          }
          return of({
            type: ParentsNotificationConstants.GET_NOTIFICATION_DETAILS_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function verifyPasswordEpic(action$: ActionsObservable<VerifyPasswordAction>) {
  return action$.pipe(
    ofType(ParentsNotificationConstants.VERIFY_PASSWORD),
    switchMap((action) => {
      return ajax({
        url: API_URL + '/notification/verify-password',
        method: 'post',
        withCredentials: true,
        body: {
          password: action.password,
        },
        headers: {
          'content-type': 'application/json;charset=UTF-8',
        },
      }).pipe(
        map((payload: AjaxResponse): VerifyPasswordSuccessAction => {
          return {
            type: ParentsNotificationConstants.VERIFY_PASSWORD_SUCCESS,
            payload: payload.response as VerifyPasswordResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: ParentsNotificationConstants.VERIFY_PASSWORD_NETWORK_ERROR,
            });
          }
          return of({
            type: ParentsNotificationConstants.VERIFY_PASSWORD_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function saveNotificationDetailsEpic(action$: ActionsObservable<SaveDetailsAction>) {
  return action$.pipe(
    ofType(ParentsNotificationConstants.SAVE_NOTIFICATION_DETAILS),
    switchMap((action) => {
      return ajax({
        url: API_URL + '/notification',
        method: 'post',
        withCredentials: true,
        body: {
          name: action.payload.name,
          email: action.payload.email,
          password: action.payload.password,
          mobile: action.payload.mobile,
          residence: action.payload.residence,
          isConditionApproved:
            action.payload.isConditionApproved === undefined
              ? undefined
              : action.payload.isConditionApproved,
        },
        headers: {
          'content-type': 'application/json;charset=UTF-8',
        },
      }).pipe(
        map((payload: AjaxResponse): SaveDetailsSuccessAction => {
          return {
            type: ParentsNotificationConstants.SAVE_NOTIFICATION_DETAILS_SUCCESS,
            payload: payload.response as SaveDetailsResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: ParentsNotificationConstants.SAVE_NOTIFICATION_DETAILS_NETWORK_ERROR,
            });
          }
          return of({
            type: ParentsNotificationConstants.SAVE_NOTIFICATION_DETAILS_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function updateNotificationDetailsEpic(action$: ActionsObservable<UpdateDetailsAction>) {
  return action$.pipe(
    ofType(ParentsNotificationConstants.UPDATE_NOTIFICATION_DETAILS),
    switchMap((action) => {
      return ajax({
        url: API_URL + '/notification/' + action.payload.notificationId.toString(),
        method: 'put',
        withCredentials: true,
        body: {
          name: action.payload.name,
          email: action.payload.email,
          password: action.payload.password,
          isPasswordRemoved: action.payload.password === null ? true : undefined,
          mobile: action.payload.mobile,
          residence: action.payload.residence,
          isConditionApproved:
            action.payload.isConditionApproved === undefined
              ? undefined
              : action.payload.isConditionApproved,
        },
        headers: {
          'content-type': 'application/json;charset=UTF-8',
        },
      }).pipe(
        map((payload: AjaxResponse): UpdateDetailsSuccessAction => {
          return {
            type: ParentsNotificationConstants.UPDATE_NOTIFICATION_DETAILS_SUCCESS,
            payload: payload.response as UpdateDetailsResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: ParentsNotificationConstants.UPDATE_NOTIFICATION_DETAILS_NETWORK_ERROR,
            });
          }
          return of({
            type: ParentsNotificationConstants.UPDATE_NOTIFICATION_DETAILS_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function cancelNotificationEpic(action$: ActionsObservable<CancelNotificationAction>) {
  return action$.pipe(
    ofType(ParentsNotificationConstants.CANCEL_NOTIFICATION),
    switchMap((action) => {
      return ajax({
        url: API_URL + '/notification/' + action.notificationId.toString(),
        method: 'delete',
        withCredentials: true,
      }).pipe(
        map((payload: AjaxResponse): CancelNotificationSuccessAction => {
          return {
            type: ParentsNotificationConstants.CANCEL_NOTIFICATION_SUCCESS,
            payload: payload.response as CancelNotificationResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: ParentsNotificationConstants.CANCEL_NOTIFICATION_NETWORK_ERROR,
            });
          }
          return of({
            type: ParentsNotificationConstants.CANCEL_NOTIFICATION_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function confirmOTPEpic(action$: ActionsObservable<ConfirmOTPAction>) {
  return action$.pipe(
    ofType(ParentsNotificationConstants.CONFIRM_OTP),
    switchMap((action) => {
      return ajax({
        url: API_URL + '/notification/confirm-otp',
        method: 'post',
        withCredentials: true,
        body: {
          email: action.payload.email,
          code: action.payload.OTP,
        },
        headers: {
          'content-type': 'application/json;charset=UTF-8',
        },
      }).pipe(
        map((payload: AjaxResponse): ConfirmOTPSuccessAction => {
          return {
            type: ParentsNotificationConstants.CONFIRM_OTP_SUCCESS,
            payload: payload.response as ConfirmOTPResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: ParentsNotificationConstants.CONFIRM_OTP_NETWORK_ERROR,
            });
          }
          return of({
            type: ParentsNotificationConstants.CONFIRM_OTP_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function resendOTPEpic(action$: ActionsObservable<ResendOTPAction>) {
  return action$.pipe(
    ofType(ParentsNotificationConstants.RESEND_OTP),
    switchMap((action) => {
      return ajax({
        url: API_URL + '/notification/resend-otp',
        method: 'post',
        withCredentials: true,
        body: {
          email: action.email,
        },
        headers: {
          'content-type': 'application/json;charset=UTF-8',
        },
      }).pipe(
        map((payload: AjaxResponse): ResendOTPSuccessAction => {
          return {
            type: ParentsNotificationConstants.RESEND_OTP_SUCCESS,
            payload: payload.response as ResendOTPResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: ParentsNotificationConstants.RESEND_OTP_NETWORK_ERROR,
            });
          }
          return of({
            type: ParentsNotificationConstants.RESEND_OTP_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function updatePasswordEpic(action$: ActionsObservable<UpdatePasswordAction>) {
  return action$.pipe(
    ofType(ParentsNotificationConstants.UPDATE_PASSWORD),
    switchMap((action) => {
      return ajax({
        url: API_URL + '/notification/update-password',
        method: 'post',
        withCredentials: true,
        body: {
          email: action.payload.email,
          password: action.payload.password,
        },
        headers: {
          'content-type': 'application/json;charset=UTF-8',
        },
      }).pipe(
        map((payload: AjaxResponse): UpdatePasswordSuccessAction => {
          return {
            type: ParentsNotificationConstants.UPDATE_PASSWORD_SUCCESS,
            payload: payload.response as UpdatePasswordResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: ParentsNotificationConstants.UPDATE_PASSWORD_NETWORK_ERROR,
            });
          }
          return of({
            type: ParentsNotificationConstants.UPDATE_PASSWORD_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function forgotPasswordEpic(action$: ActionsObservable<ForgotPasswordAction>) {
  return action$.pipe(
    ofType(ParentsNotificationConstants.FORGOT_PASSWORD),
    switchMap((action) => {
      return ajax({
        url: API_URL + '/notification/forgot-password',
        method: 'post',
        withCredentials: true,
        body: {
          email: action.email,
        },
        headers: {
          'content-type': 'application/json;charset=UTF-8',
        },
      }).pipe(
        map((payload: AjaxResponse): ForgotPasswordSuccessAction => {
          return {
            type: ParentsNotificationConstants.FORGOT_PASSWORD_SUCCESS,
            payload: payload.response as ForgotPasswordResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: ParentsNotificationConstants.FORGOT_PASSWORD_NETWORK_ERROR,
            });
          }
          return of({
            type: ParentsNotificationConstants.FORGOT_PASSWORD_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

export const parentsNotificationEpics: Epic = combineEpics(
  ...[
    getNotificationDetailsEpic,
    verifyPasswordEpic,
    saveNotificationDetailsEpic,
    updateNotificationDetailsEpic,
    cancelNotificationEpic,
    confirmOTPEpic,
    resendOTPEpic,
    updatePasswordEpic,
    forgotPasswordEpic,
  ],
);
